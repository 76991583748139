import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { PageContainer, BodyDiv, MainContainer } from '../../../UI/Library/PageStructure';
import { Card, CardContent, CardHeader, CardTitle } from "../../../UI/Library/SellerComponents";
import { Line, Bar, Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, BarElement, ArcElement, Title, Tooltip, Legend } from 'chart.js';
import { FaCheckCircle, FaHourglassHalf, FaExclamationTriangle, FaRocket, FaChartLine, FaBullseye, FaTrash, FaCalendarAlt, FaExclamationCircle, FaEdit, FaSave, FaTimes} from 'react-icons/fa';
import { motion, AnimatePresence } from 'framer-motion';
import moment from 'moment-timezone';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, BarElement, ArcElement, Title, Tooltip, Legend);

const SubBodyDiv = styled.div`
  display: grid;
  grid-template-columns: 30% 70%;
  gap: 20px;
  margin: 20px;
  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
  }
`;

const GoalCard = styled(motion.div)`
  background: linear-gradient(135deg, #f6f9fc 0%, #ffffff 100%);
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15);
  }
`;

const GoalHeader = styled(CardHeader)`
  background: linear-gradient(135deg, #325d79 0%, #5f7d95 100%);
  padding: 20px;
`;

const GoalTitle = styled(CardTitle)`
  font-size: 24px;
  font-weight: bold;
  margin: 0;
  color: white;
`;

const GoalContent = styled(CardContent)`
  padding: 20px;
`;

const GoalDetail = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  font-size: 16px;
`;

const GoalLabel = styled.span`
  font-weight: bold;
  color: #325d79;
`;

const GoalValue = styled.span`
  color: #5f7d95;
`;

const ProgressBar = styled.div`
  width: 100%;
  height: 20px;
  background-color: #e0e0e0;
  border-radius: 10px;
  overflow: hidden;
  margin-top: 20px;
`;

const ProgressFill = styled.div`
  width: ${props => props.progress}%;
  height: 100%;
  background: linear-gradient(90deg, #325d79 0%, #5f7d95 100%);
  transition: width 0.5s ease-out;
`;

const ProgressLabel = styled.div`
  text-align: center;
  margin-top: 10px;
  font-weight: bold;
  color: #325d79;
`;

const StatusIndicator = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  font-size: 18px;
  font-weight: bold;
`;

const ChartContainer = styled(motion.div)`
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  padding: 20px;
`;

const EditButton = styled.button`
  background-color: #52c41a;  // Green color
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 20px;

  &:hover {
    background-color: #73d13d;  // Lighter green on hover
    transform: translateY(-2px);
  }
`;

const SaveButton = styled.button`
  background-color: #1890ff;  // Blue color
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 20px;

  &:hover {
    background-color: #40a9ff;  // Lighter blue on hover
    transform: translateY(-2px);
  }
`;

const DeleteButton = styled.button`
  background-color: #ff4d4f;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 20px;

  &:hover {
    background-color: #ff7875;
    transform: translateY(-2px);
  }
`;

const Modal = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled(motion.div)`
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  text-align: center;
  max-width: 400px;
  width: 90%;
`;

const ModalButton = styled.button`
  margin: 0 10px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-2px);
  }
`;

const ConfirmButton = styled(ModalButton)`
  background-color: #ff4d4f;
  color: white;

  &:hover {
    background-color: #ff7875;
  }
`;

const CancelButton = styled(ModalButton)`
  background-color: #d9d9d9;
  color: #000;

  &:hover {
    background-color: #bfbfbf;
  }
`;

const InsightsSection = styled.div`
  margin-top: 20px;
`;

const InsightCard = styled(motion.div)`
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
`;

const InsightTitle = styled.h3`
  color: #325d79;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
`;

const InsightContent = styled.p`
  color: #5f7d95;
`;

const ExpiredGoalWarning = styled.div`
  background-color: #fff3cd;
  color: #856404;
  padding: 10px;
  border-radius: 5px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
`;

function TargetPageIndiv() {
  const { targetName } = useParams();
  const navigate = useNavigate();
  const [target, setTarget] = useState(null);
  const [contracts, setContracts] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [company, setCompany] = useState("");
  const [insights, setInsights] = useState([]);

  // Set up editing mode for endDate and targetType's value.
  const [isEditing, setIsEditing] = useState(false);
  const [editedData, setEditedData] = useState({});

  useEffect(() => {
    const fetchCompanyAndData = async () => {
      const username = localStorage.getItem('user');
      const accessToken = localStorage.getItem('access_token');
      const config = {
        headers: { 'Authorization': `Bearer ${accessToken}` }
      };

      try {
        const companyRes = await axios.post('https://carbonsmith-handling-69e454e97e3b.herokuapp.com/get_user_company', { username }, config);
        const fetchedCompany = companyRes.data.Company;
        setCompany(fetchedCompany);

        const [targetRes, contractsRes] = await Promise.all([
          axios.post('https://carbonsmith-handling-69e454e97e3b.herokuapp.com/seller_targets', { username }, config),
          axios.post('https://carbonsmith-handling-69e454e97e3b.herokuapp.com/get_company_contracts', { company: fetchedCompany }, config)
        ]);


        const fetchedTarget = targetRes.data.targets[targetName];
        if (fetchedTarget) {
          setTarget(fetchedTarget);
          setEditedData({
            // Initialize editedData's endDate.
            endDate: fetchedTarget.endDate,
            // Initialize editedData's targetType.
            targetType: fetchedTarget.targetType
          });
        } else {
          alert('No target found');
          navigate('/Overview');
        }

        const contractsData = contractsRes.data.data;
        if (Array.isArray(contractsData)) {
          setContracts(contractsData);
        } else if (typeof contractsData === 'object' && contractsData !== null) {
          setContracts(Object.values(contractsData));
        } else {
          console.error('Unexpected contracts data format:', contractsData);
          setContracts([]);
        }

      } catch (error) {
        console.error('Error fetching data:', error);
        setContracts([]);
      }
    };

    fetchCompanyAndData();
  }, [targetName, navigate]);

  useEffect(() => {
    if (target && contracts.length > 0) {
      generateInsights();
    }
  }, [target, contracts]);

  const calculateProgress = () => {
    if (!target || !target.targetType) return 0;

    const [targetType, targetValue] = Object.entries(target.targetType)[0];
    let currentValue = 0;

    if (!Array.isArray(contracts) || contracts.length === 0) {
      console.error('Contracts is not an array or is empty:', contracts);
      return 0;
    }

    switch (targetType) {
      case 'CO2 Removed':
        currentValue = contracts.reduce((sum, contract) => {
          const removed = Number(contract.carbonRemoved) || 0;
          return sum + removed;
        }, 0);
        break;
      case 'Contracts Completed':
        currentValue = contracts.filter(contract => contract.Status === 'Completed').length;
        break;
      case 'Partnerships Created':
        currentValue = new Set(contracts.map(contract => contract.purchaser)).size;
        break;
      default:
        return 0;
    }

    return Math.min((currentValue / targetValue) * 100, 100);
  };

  const progress = calculateProgress();

  const getStatusIndicator = () => {
    if (progress === 0) return { icon: <FaExclamationTriangle color="#ffa940" />, text: "Not Started", color: "#ffa940" };
    if (progress === 100) return { icon: <FaCheckCircle color="#52c41a" />, text: "Completed", color: "#52c41a" };
    return { icon: <FaHourglassHalf color="#1890ff" />, text: "In Progress", color: "#1890ff" };
  };

  const status = getStatusIndicator();

  const chartData = {
    labels: contracts.map((_, index) => `Contract ${index + 1}`),
    datasets: [
      {
        label: 'Contract Value ($)',
        data: contracts.map(contract => {
          if (contract.Revenue && typeof contract.Revenue === 'string') {
            return parseFloat(contract.Revenue.replace(/[^0-9.-]+/g,"")) || 0;
          }
          return contract.Revenue || 0;
        }),
        borderColor: 'rgb(75, 192, 192)',
        backgroundColor: 'rgba(75, 192, 192, 0.5)',
        yAxisID: 'y',
      },
      {
        label: 'Carbon Removed (tons)',
        data: contracts.map(contract => contract.carbonRemoved || 0),
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
        yAxisID: 'y1',
      }
    ]
  };

  const chartOptions = {
    responsive: true,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    stacked: false,
    plugins: {
      title: {
        display: true,
        text: 'Contract Values vs Carbon Removed'
      }
    },
    scales: {
      y: {
        type: 'linear',
        display: true,
        position: 'left',
        title: {
          display: true,
          text: 'Contract Value ($)'
        }
      },
      y1: {
        type: 'linear',
        display: true,
        position: 'right',
        title: {
          display: true,
          text: 'Carbon Removed (tons)'
        },
        grid: {
          drawOnChartArea: false,
        },
      },
    },
  };

  const handleDelete = async () => {
    const username = localStorage.getItem('user');
    const accessToken = localStorage.getItem('access_token');
    const config = {
      headers: { 'Authorization': `Bearer ${accessToken}` }
    };

    try {
      await axios.post('https://carbonsmith-handling-69e454e97e3b.herokuapp.com/delete_seller_goal',
          { user: username, targetName },
          config
      );
      navigate('/Targets');
    } catch (error) {
      console.error('Error deleting goal:', error);
      alert('Failed to delete goal. Please try again.');
    }
  };

  // When click save button.
  const handleSave = async () => {
    const username = localStorage.getItem('user');
    const accessToken = localStorage.getItem('access_token');
    const config = {
      headers: { 'Authorization': `Bearer ${accessToken}` }
    };

    try {
      // The timestamps to be stored into firebase's data should always be UTC format.
      // Edited endDate.
      const newEndDate = moment.tz(editedData.endDate, moment.tz.guess()).utc().format();
      // Edited targetType's value.
      const newTargetType = editedData.targetType;

      const submitObj = {
        user: username,
        // ID for backend to track.
        targetName,
        endDate: newEndDate,
        targetType: newTargetType,
        // Updated modifiedAt automatically.
        modifiedAt: new Date().toISOString()
      };

      await axios.post('https://carbonsmith-handling-69e454e97e3b.herokuapp.com/edit_target_info', submitObj, config);

      //await axios.post('http://127.0.0.1:5000/edit_target_info', submitObj, config);

      setTarget(prevTarget => ({
        ...prevTarget,
        endDate: newEndDate,
        targetType: newTargetType,
        modifiedAt: new Date().toISOString() // 自动更新modifiedAt时间
      }));
      setIsEditing(false);
      alert('Changes saved successfully!');
    } catch (error) {
      console.error('Error saving changes:', error);
      alert('Failed to save changes. Please try again.');
    }
  };


  const generateInsights = () => {
    const newInsights = [];
    const [targetType, targetValue] = Object.entries(target.targetType)[0];
    const currentValue = calculateProgress() * targetValue / 100;

    // Change UTC to local time.
    const localStartDate = moment(target.startDate).tz(moment.tz.guess()).toDate();
    const localEndDate = moment(target.endDate).tz(moment.tz.guess()).toDate();

    //const daysSinceStart = Math.ceil((new Date() - new Date(target.startDate)) / (1000 * 60 * 60 * 24));
    const daysSinceStart = Math.ceil((new Date() - localStartDate) / (1000 * 60 * 60 * 24));
    const progressRate = currentValue / daysSinceStart;
    newInsights.push({
      title: "Daily Progress Rate",
      content: `You're making progress at a rate of ${progressRate.toFixed(2)} ${targetType === 'CO2 Removed' ? 'tons' : 'units'} per day.`,
      icon: <FaChartLine />
    });

    //const daysUntilEnd = Math.ceil((new Date(target.endDate) - new Date()) / (1000 * 60 * 60 * 24));
    const daysUntilEnd = Math.ceil((localEndDate - new Date()) / (1000 * 60 * 60 * 24));
    const projectedValue = currentValue + (progressRate * daysUntilEnd);
    const projectedPercentage = (projectedValue / targetValue) * 100;
    newInsights.push({
      title: "Goal Projection",
      content: `At your current rate, you're projected to reach ${projectedPercentage.toFixed(2)}% of your goal by the end date.`,
      icon: <FaRocket />
    });

    if (projectedPercentage < 100) {
      const accelerationNeeded = ((targetValue - currentValue) / daysUntilEnd) - progressRate;
      newInsights.push({
        title: "Acceleration Needed",
        content: `To reach your goal, you need to increase your daily progress by ${accelerationNeeded.toFixed(2)} ${targetType === 'CO2 Removed' ? 'tons' : 'units'}.`,
        icon: <FaBullseye />
      });
    }

    setInsights(newInsights);
  };

  //const isGoalExpired = new Date() > new Date(target?.endDate);
  // Convert target.endDate to local time and check whether expire.
  const isGoalExpired = new Date() > moment(target?.endDate).tz(moment.tz.guess()).toDate();


  if (!target) return <div>Loading...</div>;

  return (
      <PageContainer>
        <MainContainer>
          <BodyDiv>
            <SubBodyDiv>
              <GoalCard
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5 }}
              >
                <GoalHeader>
                  <GoalTitle>{targetName}</GoalTitle>
                </GoalHeader>
                <GoalContent>
                  {Object.entries(target).map(([key, value]) => {
                    if (key !== 'targetName' && key !== 'timeAdded') {
                      if (key === 'targetType') {
                        const [type, amount] = Object.entries(value)[0];
                        return (
                            <GoalDetail key={type}>
                              <GoalLabel>{type === 'CO2 Removed' ? 'CO₂ Removed' : type}:</GoalLabel>

                              {isEditing ? ( // If isEditing is true, make targetType's value into editing mode.
                                  <GoalValue>
                                    <input
                                        type="number"
                                        value={editedData.targetType[type]}
                                        onChange={(e) => setEditedData({
                                          ...editedData,
                                          targetType: { [type]: e.target.value }
                                        })}
                                    />
                                  </GoalValue>
                              ) : (
                                  <GoalValue>{amount}</GoalValue>
                              )}

                            </GoalDetail>
                        );
                      }
                      if (key === 'endDate' || key === 'startDate') {
                        return (
                            <GoalDetail key={key}>
                              <GoalLabel>{key === 'startDate' ? 'Start Date' : 'End Date'}:</GoalLabel>
                              {isEditing && key === 'endDate' ? (
                                  <GoalValue>
                                    <input
                                        type="date"
                                        value={moment(editedData.endDate).format('YYYY-MM-DD')} // Use local time for users to choose a date.
                                        min={moment(target.startDate).tz(moment.tz.guess()).format('YYYY-MM-DD')} // endDate should at least as large as startDate.
                                        onChange={(e) => setEditedData({
                                          ...editedData,
                                          endDate: e.target.value
                                        })}
                                    />
                                  </GoalValue>
                              ) : (
                                  <GoalValue>{moment(value).tz(moment.tz.guess()).format('MM/DD/YYYY')}</GoalValue> // Render in local date.
                              )}
                            </GoalDetail>
                        );
                      }
                      if (key === 'addedAt' || key === 'modifiedAt') {
                        return (
                            <GoalDetail key={key}>
                              <GoalLabel>{key}:</GoalLabel>
                              {/* Show addedAt and modifiedAt as local time. */}
                              <GoalValue>{moment(value).tz(moment.tz.guess()).format('MM/DD/YYYY, HH:mm:ss')}</GoalValue>
                            </GoalDetail>
                        );
                      }
                      return (
                          <GoalDetail key={key}>
                            <GoalLabel>{key}:</GoalLabel>
                            <GoalValue>{value}</GoalValue>
                          </GoalDetail>
                      );
                    }
                    return null;
                  })}
                  <ProgressBar>
                    <ProgressFill progress={progress} />
                  </ProgressBar>
                  <ProgressLabel>{progress.toFixed(2)}% Complete</ProgressLabel>
                  <StatusIndicator style={{ color: status.color }}>
                    {status.icon} {status.text}
                  </StatusIndicator>
                  {isGoalExpired && (
                      <ExpiredGoalWarning>
                        <FaExclamationCircle />
                        This goal has expired. Consider updating or archiving it.
                      </ExpiredGoalWarning>
                  )}
                  {/* If isEditing is true, show Edit and Cancel buttons. Once Edit button is clicked, isEditing will be set to true. */}
                  {!isEditing ? (
                      <EditButton onClick={() => setIsEditing(true)}><FaEdit /> Edit</EditButton>
                  ) : (
                      <>
                        <SaveButton onClick={handleSave}><FaSave /> Save Changes</SaveButton>
                        <CancelButton onClick={() => setIsEditing(false)}><FaTimes /> Cancel</CancelButton>
                      </>
                  )}
                  <DeleteButton onClick={() => setShowModal(true)}>
                    <FaTrash /> Delete Goal
                  </DeleteButton>
                </GoalContent>
              </GoalCard>

              <ChartContainer
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: 0.2 }}
              >
                <Line options={chartOptions} data={chartData} />
                <InsightsSection>
                  <AnimatePresence>
                    {insights.map((insight, index) => (
                        <InsightCard
                            key={index}
                            initial={{ opacity: 0, x: -50 }}
                            animate={{ opacity: 1, x: 0 }}
                            exit={{ opacity: 0, x: 50 }}
                            transition={{ duration: 0.5, delay: index * 0.1 }}
                        >
                          <InsightTitle>{insight.icon} {insight.title}</InsightTitle>
                          <InsightContent>{insight.content}</InsightContent>
                        </InsightCard>
                    ))}
                  </AnimatePresence>
                </InsightsSection>
              </ChartContainer>
            </SubBodyDiv>
          </BodyDiv>
        </MainContainer>

        <AnimatePresence>
          {showModal && (
              <Modal
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
              >
                <ModalContent
                    initial={{ scale: 0.8, y: -50 }}
                    animate={{ scale: 1, y: 0 }}
                    exit={{ scale: 0.8, y: 50 }}
                >
                  <h2>Are you sure you want to delete this goal?</h2>
                  <ConfirmButton onClick={handleDelete}>Yes, Delete</ConfirmButton>
                  <CancelButton onClick={() => setShowModal(false)}>Cancel</CancelButton>
                </ModalContent>
              </Modal>
          )}
        </AnimatePresence>
      </PageContainer>
  );
}

export default TargetPageIndiv;