import React, { useState, useEffect } from "react";
import styled from "styled-components";
import LandingBodyTabsBuyer from "./LandingBodyTabsBuyer";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import AvailableCreditsFooter from "./UniversalFcnsBuyerFE/AvailableCreditsScroll";
import Spinner from "../../../UniversalFunctions/LoadingGraphic";
import {
  format2Decimals,
  formatNumberDollars,
} from "../../../UniversalFunctions/Numeric/Format2Decimal";

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 0 4%;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const BodyDiv = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const UpperBodyDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // padding: 2%;
`;

const LowerBodyDiv = styled.div`
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
`;

const TitleDiv = styled.div`
  width: 80%;
  border: 1px solid #325d7980;
  border-radius: 5px;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
`;

const RightSideTitle = styled.div`
  display: flex;
`;

const ContractsStatus = styled.h3`
  color: #325d79ff;
  font-weight: bold;
`;

const Title = styled.h1`
  color: #325d79ff;
  font-size: 1.5em;
  font-weight: bold;
  text-align: left;
`;

const ContractList = styled.ul`
  list-style: none;
  padding-left: 3%;
  padding-bottom: 2%;
  display: flex;
  flex-direction: row;
`;

const ContractItem = styled.li`
  background: white;
  padding: 2%;
  margin-right: 3%;
  margin-bottom: 2%;
  border-radius: 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  width: 30%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  border: 2px solid #325d7980;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    // background-color: #add8e6;
    // background-color: #c5c6D0;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
    transform: translateY(-5px);
    transition: all 0.3s ease-in-out;
    cursor: pointer;
  }
`;

const ContractTitle = styled.p`
  font-weight: bold;
  font-size: 1.5em;
  color: #325d79ff;
  // font-family: dm sans;
`;

const ContractDesc = styled.p`
  text-align: left;
  font-size: 1em;
  // font-family: dm sans;
`;

const WelcomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10% 0;
  background: linear-gradient(to right, #00c9ff, #92fe9d);
  border-radius: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  color: white;
  text-align: center;
  margin: 2% 1%;
  transition: all 0.3s ease-in-out;
  //   height: 50%;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.25);
  }
`;

const WelcomeTitle = styled.h1`
  font-size: 2.5em;
  margin-bottom: 20px;
`;

const WelcomeMessage = styled.p`
  font-size: 1.2em;
  margin-bottom: 30px;
  max-width: 600px; // Keeps text from becoming too wide on larger screens
`;

const GetStartedButton = styled.button`
  background-color: white;
  color: #325d79ff;
  font-size: 1em;
  padding: 2% 4%;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #5f7d95ff;
  }
`;

const ContractDescTitle = styled.p`
  font-weight: bold;
  color: #286fb4;
  display: inline;
`;

//background: linear-gradient(to right, #325d79ff, #5f7d95ff);

function YourRemovals() {
  const [contracts, setContracts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const requestParams = {};

  useEffect(() => {
    // Fetch contracts data and set it using setContracts
    const fetchContracts = async () => {
      const username = localStorage.getItem("user");
      const accessToken = localStorage.getItem("access_token");
      requestParams["username"] = username;
      const config = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };
      try {
        const response = await axios.post(
          "https://carbonsmith-handling-69e454e97e3b.herokuapp.com/buyer_contracts_all",
          requestParams,
          config
        );
        const fetchedContracts = response.data.data;
        console.log("data ", fetchedContracts);

        setContracts(fetchedContracts || []);
      } catch (error) {
        console.log("error :", error);
      }
      setIsLoading(false);
    };
    fetchContracts();
  }, []);

  return (
    <Container>
      <BodyDiv>
        {isLoading ? (
          <div style={{ marginTop: "10%" }}>
            <Spinner />
          </div>
        ) : (
          <>
            <UpperBodyDiv>
              {contracts.length === 0 ? (
                <WelcomeContainer>
                  <WelcomeTitle>No Contracts To Show</WelcomeTitle>
                  <WelcomeMessage>
                    Get started below to begin tracking your carbon removal
                    purchases
                  </WelcomeMessage>
                  <GetStartedButton
                    onClick={() => {
                      navigate("/");
                    }}
                  >
                    Get Started
                  </GetStartedButton>
                </WelcomeContainer>
              ) : (
                <>
                  <TitleDiv>
                    <Title>Your Contracts</Title>
                    {Object.values(contracts).some(
                      (contract) => contract.status === "In Progress"
                    ) && (
                      <RightSideTitle>
                        <span
                          style={{
                            height: "12px",
                            width: "12px",
                            backgroundColor: "green",
                            borderRadius: "50%",
                            display: "inline-block",
                            marginRight: "7px",
                            marginTop: "33%",
                          }}
                        ></span>
                        <ContractsStatus>Active</ContractsStatus>
                      </RightSideTitle>
                    )}
                  </TitleDiv>

                  <ContractList>
                    {/*need to make this display only contracts that are currently in progress or uninitialized - completed contracts should not show*/}
                    {Object.values(contracts).map((contract) => (
                      <ContractItem
                        key={contract.contractName}
                        onClick={() =>
                          navigate(
                            `/purchased-contract/${contract.contractName}`
                          )
                        }
                      >
                        {/* Display contract details here */}
                        <ContractTitle>{contract.contractName}</ContractTitle>
                        <ContractDesc>
                          <ContractDescTitle>Company: </ContractDescTitle>
                          {contract.company}
                        </ContractDesc>
                        <ContractDesc>
                          <ContractDescTitle>ID: </ContractDescTitle>
                          {contract.contract_hedera}
                        </ContractDesc>
                        <ContractDesc>
                          <ContractDescTitle>Tons Removed: </ContractDescTitle>
                          {contract.tonsToCapture}
                        </ContractDesc>
                        <ContractDesc>
                          <ContractDescTitle>Price: </ContractDescTitle>$
                          {formatNumberDollars(contract.priceTonUSD)}
                        </ContractDesc>
                        <ContractDesc>
                          <ContractDescTitle>Total Cost: </ContractDescTitle>$
                          {format2Decimals(contract.cost)}
                        </ContractDesc>
                        <ContractDesc>
                          <ContractDescTitle>Status: </ContractDescTitle>
                          {contract.status}
                        </ContractDesc>
                      </ContractItem>
                    ))}
                  </ContractList>
                </>
              )}
            </UpperBodyDiv>
            <LowerBodyDiv>
              <AvailableCreditsFooter />
            </LowerBodyDiv>
          </>
        )}
      </BodyDiv>
    </Container>
  );
}

export default YourRemovals;
