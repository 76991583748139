import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import LandingBodyTabs from "../Pages/PageComponents/CoreProductFrontEndSeller/LandingBodyTabs";
import LandingBodyTabsBuyer from "../Pages/PageComponents/CoreProductFrontEndBuyer/LandingBodyTabsBuyer";
import axios from "axios";
import styled from "styled-components";

const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

const PageContainer = styled.div`
  display: flex;
  flex-direction: row;
  // background-color: #E4E8E8;
  @media (max-width: 768px) {
    max-width: 100vw;
    margin-top: 10vh;
    flex-direction: column;
  }
`;

const AuthCheck = () => {
  const navigate = useNavigate();
  const [accountType, setAccountType] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkToken = async () => {
      const firebaseIdToken = localStorage.getItem("firebase_id_token");
      if (!firebaseIdToken) {
        try {
          localStorage.removeItem("user");
          localStorage.removeItem("access_token");
        } catch (error) {
          console.log("an error occurred removing tokens");
        }
        await sleep(1000); //wait for one second
        navigate("/");
        return;
      }

      try {
        const decodedToken = jwtDecode(firebaseIdToken);
        const accessToken = localStorage.getItem("access_token");
        const currentTime = Date.now() / 1000;
        if (decodedToken.exp < currentTime) {
          // Token is expired
          localStorage.removeItem("firebase_id_token");
          try {
            localStorage.removeItem("user");
            localStorage.removeItem("access_token");
          } catch (error) {
            console.log("an error occurred removing tokens");
          }
          await sleep(1000);
          navigate("/");
        }
        const config = {
          headers: {
              'Authorization': `Bearer ${accessToken}`,
              'Firebase-Token': firebaseIdToken
          }
      };
        const res = await axios.post('https://carbonsmith-handling-69e454e97e3b.herokuapp.com/account_type', { username:  decodedToken.sub }, config);
        const userData = res.data;
        setAccountType(userData["account_type"]);
      } catch (error) {
        console.error("Error decoding token:", error);
        await sleep(1000);
        navigate("/");
      } finally {
        setIsLoading(false);
      }
    };

    checkToken();
  }, [navigate]);

  //Should changed to a more dynamic throbber in the future
  if (isLoading) {
    return <div></div>;
  }

  console.log("account type: ", accountType);

  return (
    <PageContainer>
      {accountType === "Seller" ? <LandingBodyTabs /> : <LandingBodyTabsBuyer />}
      <Outlet />
    </PageContainer>
  );
};

export default AuthCheck;
