import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import {
  MdOutlineDashboard,
  MdAdjust,
  MdRemoveCircleOutline,
  MdHistory,
  MdContactSupport,
  MdRemoveCircle,
} from "react-icons/md";
import { FiLogOut } from "react-icons/fi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import defaultHeadshot from "../../../../images/default_headshot.png";
import { useAuth } from "../../../UniversalFunctions/AuthProvider";
import LogoLight from "../../../../images/CS_White.png";

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const hoverEffect = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.04); }
  100% { transform: scale(1); }
`;

const MainContainer = styled.div`
  width: 15vw;
  min-width: 15vw;
  position: relative;
  // height: 100vw;

  @media (max-width: 768px) {
    width: 100%;
    height: 6vh;
    max-width: 100vw;
  }
`;

const TabContainer = styled.div`
  position: fixed; /* Keep the sidebar fixed */
  top: 0;
  left: 0;
  min-width: 15vw;
  height: 100%;
  // background: linear-gradient(to bottom, #325d79, #5f7d95);
  background: linear-gradient(to bottom, #1c778d, #0d4d4f);
  color: #fff;
  //padding: 20px 10px; /* Adjusted padding */
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  z-index: 1000; /* Ensure it's above other content */
  @media (max-width: 768px) {
    width: 100%;
    height: 6vh;
    max-width: 100vw;
  }
`;
const TabLinkContainer = styled.div`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  padding: 20px 0;
  display: flex;
  flex-direction: column; /* Align items vertically */
  justify-content: space-between; /* Separate top nav and profile link */
  text-align: left;
  height: 100%;

  @media (max-width: 768px) {
    display: none;
    flex-direction: row;
    width: 100%;
    height: 6vh;
    gap: 10px;
    max-width: 100vw;
    margin: auto;
  }
`;
const LogoContainer = styled.div`
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // padding: 20px;

  @media (min-width: 800px) {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
  }

  @media (max-width: 799px) {
    width: 0;
    height: 0;
    padding: 0;
  }

  &:hover {
    cursor: pointer;
  }
`;

const LogoImage = styled.img`
  width: 0;

  @media (min-width: 800px) {
    width: 80px;
    height: auto;
  }
`;

const TabLink = styled.div`
  display: flex;
  align-items: center;
  gap: 2%;
  text-decoration: none;
  color: #fff;
  cursor: pointer;
  padding: 5%;
  // border-radius: 20px;
  font-weight: bold;
  transition: background-color 0.3s, transform 0.3s;

  &:hover {
    // animation: ${hoverEffect} 1.5s infinite;
    background-color: #0a3c57;
  }
  a {
    color: inherit;
    text-decoration: none;
    width: 100%;
  }
  @media (max-width: 800px) {
    font-size: 1rem;
    padding: 0;
  }
`;

const NonProfileLinks = styled.div`
  @media (min-width: 800px) {
    display: flex;
    flex-direction: column;
    height: 45%;
    justify-content: space-between;
  }
`;

const ProfileLogoutDiv = styled.div`
  @media (max-width: 800px) {
    justify-content: start;
  }
  @media (min-width: 800px) {
    height: 30%;
  }
`;

const LogoutLink = styled(TabLink)`
  gap: 0;

  @media (max-width: 800px) {
    // padding: 10px 20px;
    font-size: 1rem;
    gap: 0;
    font-size: 1rem; /* Ensure font size is readable on mobile */
    padding: 10px; /* Add padding for better touch targets */
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  // padding: 0;
`;

const TabText = styled.p`
  margin-left: 4%;
`;

const RedText = styled.span`
  color: #ff1d18;
  @media (max-width: 800px) {
    position: absolute;
    left: 100px;
    width: 20%;
    font-size: 0.5rem;
    font-weight: bolder;
  }
`;

const ProfileLinkContainer = styled(TabLink)`
  display: flex;
  flex-direction: column;
  // align-items: center;
  // justify-content: center;
  @media (max-width: 800px) {
    margin-right: 50px;
  }
`;

const ProfileContentDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const ProfileImage = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid #fff;
  @media (max-width: 800px) {
    width: 30px;
    height: 30px;
  }
`;

const ProfileText = styled.span`
  display: none; // Hide text on small screens or adapt as needed
  @media (min-width: 700px) {
    display: inline;
  }
`;

const MobileMenuIcon = styled.div`
  display: none;
  @media (max-width: 768px) {
    margin: auto;
    margin-left: 20px;
    position: absolute;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    width: 100%;
    font-size: 1.5rem; //for hamburger icon
    color: #fff;
    cursor: pointer;
  }
`;

const MobileMenu = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: absolute;
  top: 6vh;
  left: 0;
  background: linear-gradient(to bottom, #1c778d, #0d4d4f);
  color: #fff;
  padding: 10px 10px; /* Adjusted padding */
  padding-left: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  z-index: 1000; /* Ensure it's above other content */
  align-items: center;
  justify-content: center;
`;

// Assuming you have an Icon component or library
// Placeholder for icons next to tab names
const Icon = styled.i`
  display: inline-block;
  margin-right: 8px;
`;

// The updated React component
export default function LandingBodyTabs({ footerRef }) {
  const { authState, signOut } = useAuth();
  const [profileImage, setProfileImage] = useState("");
  const [noImage, setNoImage] = useState(true);
  const user = localStorage.getItem("user");
  const navigate = useNavigate();
  const [showMenu, setShowMenu] = useState(false);
  const [navbarHeight, setNavbarHeight] = useState(0);

  const handleLogout = () => {
    signOut();
    navigate("/");
  };

  useEffect(() => {
    const navbarElement = document.querySelector(".navbar");
    if (navbarElement) {
      setNavbarHeight(navbarElement.offsetHeight);
    }

    // Image fetching logic remains unchanged
    const getProfImage = async () => {
      const user = localStorage.getItem("user");
      const accessToken = localStorage.getItem("access_token");
      //     // requestParams['user'] = user;
      const config = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        responseType: "blob", //handling binary data
      };
      //     console.log('config', config);

      try {
        const res = await axios.get(
          `https://carbonsmith-handling-69e454e97e3b.herokuapp.com/profile/get_profile_image?user=${user}`,
          config
        );
        const imageBlob = res.data;
        const imageUrl = URL.createObjectURL(imageBlob);
        setNoImage(false);
        setProfileImage(imageUrl);
      } catch (error) {
        console.log("Error fetching profile image: ", error);
      }
    };
    getProfImage();

    if (noImage) {
      setProfileImage(defaultHeadshot);
    }
  }, []);

  const profileLinks = () => {
    return (
      <>
        {user === "drewhahn23@gmail.com" ? (
          <TabLink>
            <Link to="/Admin"> Admin</Link>
          </TabLink>
        ) : (
          <></>
        )}
        <TabLink>
          <Link to="/">
            <TabText> Home</TabText>
          </Link>
        </TabLink>
        {/* <TabLink>
          <Link to="/YourRemovals">
            <TabText> Purchases</TabText>
          </Link>
        </TabLink> */}
        <TabLink>
          <Link to="/Contract-History-Buyer">
            <TabText> Contracts</TabText>
          </Link>
        </TabLink>
        <TabLink>
          <Link to="/Removal-Goals">
            <TabText> Goals</TabText>
          </Link>
        </TabLink>
        <TabLink>
          <Link to="/">
            <TabText>
              Insights
            </TabText>
          </Link>
        </TabLink>
        {/* <TabLink>
          <Link to="/support"><TabText> Help</TabText></Link>
        </TabLink> */}
        {/* <TabLink onClick={handleLogout}>
          <FiLogOut /><TabText> Logout</TabText>
        </TabLink> */}
      </>
    );
  };

  return (
    <MainContainer>
      <TabContainer className="navbar">
        {/* Utilize TabLink for each navigation item, with optional icons for visual enhancement */}
        <LogoContainer onClick={() => navigate("/")}>
          <LogoImage src={LogoLight} alt="Company Logo" />
        </LogoContainer>
        <MobileMenuIcon onClick={() => setShowMenu(!showMenu)}>
          <FontAwesomeIcon icon={faBars} />
          <ProfileLinkContainer>
            <Link to={`/Profile/${localStorage.getItem("user")}`}>
              <ProfileContentDiv>
                <ProfileImage
                  src={profileImage || defaultHeadshot}
                  alt="User"
                />
                <ProfileText>Profile</ProfileText>
              </ProfileContentDiv>
            </Link>
          </ProfileLinkContainer>
        </MobileMenuIcon>
        {showMenu && (
          <MobileMenu style={{ top: `${navbarHeight}px` }}>
            {profileLinks()}
            <LogoutLink onClick={handleLogout}>
              <FiLogOut />
              <TabText>Logout</TabText>
            </LogoutLink>
          </MobileMenu>
        )}
        <TabLinkContainer>
          <NonProfileLinks>{profileLinks()}</NonProfileLinks>

          <ProfileLogoutDiv>
            <ProfileLinkContainer>
              <Link to={`/Profile/${localStorage.getItem("user")}`}>
                <ProfileContentDiv>
                  <ProfileImage
                    src={profileImage || defaultHeadshot}
                    alt="User"
                  />
                  <ProfileText>Profile</ProfileText>
                </ProfileContentDiv>
              </Link>
            </ProfileLinkContainer>
            <LogoutLink onClick={handleLogout} style={{ fontSize: ".7rem" }}>
              <FiLogOut />
              <TabText> Logout</TabText>
            </LogoutLink>
          </ProfileLogoutDiv>
        </TabLinkContainer>
      </TabContainer>
    </MainContainer>
  );
}