import React, { useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { useNavigate } from "react-router";
import { useAuth } from "../../../UniversalFunctions/AuthProvider";
import { NewHeaderLight } from "../ExternalSite/NewHeader";
import NewFooter from "../ExternalSite/NewFooter";
import loginBackground from "../../../../images/Login_Page_Background.png";
import theme from "../../../UI/Library/Theme";
import FlexContainer from "../../../UI/Library/Container/FlexContainer";
import { clamp } from "framer-motion";
import { signInWithCustomToken } from "firebase/auth";
import { auth } from "../../../UniversalFunctions/firebase";

const PageContainer = styled.div`
  background-color: #fff; /* Background color to match your site's design */
  text-align: center; /* Center the content */
  overflow-x: clip; /* Hide horizontal scrollbar */
`;

const MainContainer = styled.div`
  width: 100%;
  height: 100vh;
  background-image: url(${loginBackground});
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (min-width: 1024px) {
    flex-direction: row;
    justify-content: right;
  }
`;

const FormLabel = styled.label`
  align-self: flex-start;
  display: block;
  font-weight: bold;
  color: #325d79ff;
  transition: all 0.5s ease-in-out;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 400;
  line-height: 17.58px;
  text-align: left;

  &:focus-within {
    color: #325d79ff;
  }
`;
const Form = styled.form`
  min-height: 60%;
  width: 80%;
  background-color: white;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  padding: 5vh 0;
  @media (min-width: 1024px) {
    width: auto;
    min-width: 30%;
    margin-right: 10%;
  }
`;
const Text = styled.p`
  color: #0a3c57;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 300;
  line-height: 17.58px;
  text-align: left;
`;
const FormInput = styled.input`
  width: 100%;
  margin-bottom: 10px;
  background: #edeeee;
  border: none;
  padding: 0 10px;
  border-radius: 10px;
  height: 2.5rem;
  min-height: 2rem;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 300;
  line-height: 16.41px;
  text-align: left;
  color: #8d9b9c;

  ::placeholder {
    color: #8d9b9c;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 300;
    line-height: 16.41px;
  }

  &:focus {
    outline: none;
    border-color: #325d79ff;
  }

  &:focus + ${FormLabel} {
    color: #325d79ff;
  }
`;

const FormButton = styled.button`
  // background-color: #325d79ff; /* Button background color */
  background-color: ${theme.colors.primary[900]};
  color: #fff; /* Button text color */
  padding: 12px 24px;
  border-radius: 10px;
  font-size: 16px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s, transform 0.2s;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 300;
  line-height: 16.41px;
  text-align: center;

  &:hover {
    background-color: #325d7980;
    transform: scale(1.05);
  }
`;

const NavLink = styled.a`
  color: #1eb1b5;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.41px;
  text-align: right;
  text-decoration: underline;

  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const SuccessAlert = styled.div`
  background-color: #f3f9e8;
  color: #739e9f;
  padding: 10px;
  margin: 10px;
  border-radius: 10px;
`;
const ErrorAlert = styled.div`
  background-color: #ffebd4;
  color: #f76a1d;
  padding: 10px;
  margin: 10px;
  border-radius: 10px;
`;

function LoginPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [alert, setAlert] = useState({
    type: "",
    message: "",
  });
  const { setAuthState } = useAuth(); //get the function to update the auth state
  const navigate = useNavigate(); //Get the history object from React Router
  const [renderResetPassword, setRenderResetPassword] = useState(false);
  const handleForgotPassword = () => {
    setRenderResetPassword(true);
  };

  const handleReset = async (e) => {
    e.preventDefault();
    setSubmitDisabled(true);
    //send request to backend
    axios
      .post(
        "https://carbonsmith-handling-69e454e97e3b.herokuapp.com/password-recovery",
        // "http://localhost:5000/password-recovery",
        {
          email: email,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        // console.log("response: ", res);
        setRenderResetPassword(false);
        setAlert({
          type: "success",
          message: "Password reset email sent",
        });
      })
      .catch((error) => {
        // console.error("Error " + error);
        setRenderResetPassword(false);
        setAlert({
          type: "error",
          message: "Error sending password reset email",
        });
      });
    setTimeout(() => {
      setAlert({
        type: "",
        message: "",
      });
      setSubmitDisabled(false);
    }, 10000);
  };

  // Firebase id token refreshing process is initiated in handleSubmit, when the user press the login button.
  const handleSubmit = async (e) => {
    e.preventDefault();

    // In case the previous firebase auth.currentUser is not successfully logged out.
    if (auth.currentUser) {
      //console.log("LogIn log out current firebase user", auth.currentUser);
      await auth.signOut();
    }

    const userData = {
      username: email,
      password: password,
    };
    //console.log("frontend enter user data", userData);

    // Send userData of user email and password to the backend, and the backend login endpoint will use userData do the auth in database.
    try {
      // Use local endpoint for now.
      //const res = await axios.post('http://127.0.0.1:5000/login', userData);
      const res = await axios.post(
        "https://carbonsmith-handling-69e454e97e3b.herokuapp.com/login",
        userData
      );

      // If successfully auth in the backend database.
      if (res.status === 200) {
        //console.log("frontend response data:", res);

        // Get firebase_custom_token returned from the backend login endpoint.
        const firebaseCustomToken = res.data.firebase_custom_token;
        //console.log("firebase_custom_token get from backend:", firebaseCustomToken);

        // Key step: use signInWithCustomToken method with firebase_custom_token to connect with firebase in frontend, so that we can use firebase's built-in method to do the refreshing.
        // After calling this function, we will get a new token, which will be used to refreshed, not the same one with the firebase_custom_token.
        const firebaseCredential = await signInWithCustomToken(
          auth,
          firebaseCustomToken
        );
        const newToken = await firebaseCredential.user.getIdToken(true);
        //console.log("firebase_id_token after signInWithCustomToken to be kept refreshed in frontend:", newToken);

        try {
          // Set it here. Because the first token generated by signInWithCustomToken will only exist here for once and will not successfully trigger the refreshFirebaseIdToken method and be stored.
          localStorage.setItem("firebase_id_token", newToken);
          //console.log("login get firebase_id_token:", localStorage.getItem("firebase_id_token"));

          // Store user in localStorage.
          localStorage.setItem("user", res.data.user);
          //console.log("login get user:", localStorage.getItem("user"));

          // Store jwt_token as access_token in localStorage.
          localStorage.setItem("access_token", res.data.jwt_token);
          //console.log("login get access_token:", localStorage.getItem("access_token"));
        } catch (error) {
          console.error("Error setting items in localStorage:", e);
        }

        // Update auth status and redirect.
        setAuthState(email, true);
        navigate("/");
      }
    } catch (error) {
      console.error("Error during the login process:", error);
      // Error handling.
      handleLoginError(error);
    } finally {
      // Automatically clean error message after 10s of showing.
      setTimeout(() => {
        setAlert({ type: "", message: "" });
      }, 10000);
    }
  };

  // An independent error handling function.
  function handleLoginError(error) {
    if (error.response) {
      const errorMessage =
        error.response.data?.error || error.response.statusText;
      setAlert({
        type: "error",
        message: errorMessage.includes("Username and password")
          ? "Invalid username or password"
          : errorMessage.includes("Invalid")
          ? "Invalid request"
          : errorMessage,
      });
    } else if (error.request) {
      setAlert({
        type: "error",
        message: "No response from the server. Check your network connection.",
      });
    } else {
      setAlert({
        type: "error",
        message: `Error during the login process: ${error.message}`,
      });
    }
  }

  return (
    <PageContainer>
      <NewHeaderLight />
      <MainContainer>
        {renderResetPassword || (
          <Form onSubmit={handleSubmit}>
            <FlexContainer
              direction="column"
              customStyles={`
              padding: 1rem 2rem;
            width: 100%;
            height: 100%;
            `}
              justify="center"
              gap={"2vh"}
            >
              <div style={{ textAlign: "left", paddingBottom: "10px" }}>
                <h2
                  style={{
                    color: "#0A3C57",
                    fontFamily: "Roboto",
                    fontSize: clamp("1.8rem", "4vw", "2.5rem"),
                    fontWeight: "700",
                    lineHeight: "46.88px",
                    textAlign: "left",
                  }}
                >
                  Welcome back
                </h2>
                <p>Pick up where you left off and keep making a difference.</p>
              </div>
              {alert.type === "error" && (
                <ErrorAlert>{alert.message}</ErrorAlert>
              )}
              {alert.type === "success" && (
                <SuccessAlert>{alert.message}</SuccessAlert>
              )}
              <FormLabel htmlFor="email">Email</FormLabel>
              <FormInput
                type="email"
                id="email"
                name="email"
                value={email}
                placeholder="Enter your email"
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <FormLabel htmlFor="password">Password</FormLabel>
              <FormInput
                type="password"
                id="password"
                name="password"
                value={password}
                placeholder="Enter your password"
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <NavLink
                onClick={handleForgotPassword}
                style={{ alignSelf: "flex-end", right: 0, bottom: 5 }}
              >
                Forgot Password?
              </NavLink>
              <FormButton type="submit" disabled={submitDisabled}>
                Sign In
              </FormButton>

              <div style={{ paddingTop: "20px" }}>
                <Text>
                  Don't have an account yet?{" "}
                  <NavLink onClick={() => (navigate('/'))}>
                  <a href="#contact-form" style={{ textDecoration: 'none', color: '#0A3C57'}}>
                    Start here
                  </a>
                </NavLink>
                </Text>
              </div>
            </FlexContainer>
          </Form>
        )}
        {renderResetPassword && (
          <Form onSubmit={handleReset}>
            <FlexContainer
              direction="column"
              customStyles={`
             padding: 1rem 2rem;
              width: 100%;
              height: 100%;
              `}
              justify="center"
              gap={"2vh"}
            >
              <div style={{ textAlign: "left", paddingBottom: "10px" }}>
                <h2
                  style={{
                    color: "#0A3C57",
                    fontFamily: "Roboto",
                    fontSize: "40px",
                    fontWeight: "700",
                    lineHeight: "46.88px",
                    textAlign: "left",
                  }}
                >
                  Reset Password
                </h2>
                <p>
                  Enter your email and we will send you a link to reset your
                  password
                </p>
              </div>
              {alert.type === "error" && (
                <ErrorAlert>{alert.message}</ErrorAlert>
              )}
              {alert.type === "success" && (
                <SuccessAlert>{alert.message}</SuccessAlert>
              )}
              <FormLabel htmlFor="email">Email</FormLabel>
              <FormInput
                type="email"
                id="email"
                name="email"
                value={email}
                placeholder="Enter your email"
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <FormButton type="submit" disabled={submitDisabled}>
                Reset Password
              </FormButton>
              <NavLink onClick={() => setRenderResetPassword(false)}>
                Go back
              </NavLink>
            </FlexContainer>
          </Form>
        )}
      </MainContainer>
      <NewFooter />
    </PageContainer>
  );
}

export default LoginPage;
