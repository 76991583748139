import React from 'react';
import styled from 'styled-components';
import { FaLinkedin, FaXing } from 'react-icons/fa';
import { RiTwitterXFill } from "react-icons/ri";
import logo from '../../../../images/carbonsmith_logo_final.jpg'; // Replace with actual path to your logo
import logowhite from '../../../../images/carbonsmith_logo_white.png';
import { useNavigate } from 'react-router-dom';

const FooterContainer = styled.footer`
  background-color: #0A3C57;
  padding: 7% 20px;
  padding-bottom: 2%;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 800px) {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 50vh;
      background: linear-gradient(to bottom, white, #0A3C57);
      z-index: -1; // Ensure the pseudo-element is behind the content
    }
    padding-top: 10vh;
    
  }
  @media (min-width: 800px) {
    padding-top: 0;
  }
`;

const LogoSection = styled.div`
  display: flex;
  align-items: center;
  margin-left: 5%;
  transform: translatey(-25px);

  &:hover {
    cursor: pointer;
  }
`;

const Logo = styled.img`
  height: 60px;
  margin-right: 10px;
`;

const CompanyName = styled.span`
  font-size: 24px;
  font-weight: bold;
`;

const LinksSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 7%;
  align-items: left;
  text-align: left;
  margin-right: 10%;
//   margin-left: 5%;
  @media (max-width: 750px) {
    margin-top: 2%;
  }
`;

const Link = styled.a`
  color: white;
  text-decoration: none;
  margin: 10px 0;
  font-size: 18px;
  
  &:hover {
    text-decoration: underline;
  }
`;

const SocialSection = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0;
`;

const Icon = styled.a`
  color: white;
  // margin: 0 10px;
  font-size: 24px;
  
  &:hover {
    color: #1EB1B5;
  }
`;

const CopyrightSection = styled.div`
  text-align: left;
  font-size: 14px;
  margin-top: 20px;
  margin-left: 5%;
  width: 100%;

  @media (max-width: 750px) {
    margin-left: 0%;
  }
`;

const NewFooter = () => {
    const navigate = useNavigate();
  return (
    <FooterContainer>
      <LogoSection>
        <a href='#landing' style={{textDecoration: 'none'}}>
        <Logo src={logowhite} alt="Carbonsmith Logo" />
        </a>
        {/* <CompanyName>Carbonsmith</CompanyName> */}
      </LogoSection>
      <LinksSection>
        <Link href="/our-team">About</Link>
        <Link href="/blog">Blog</Link>
        {/* <Link href="/blog">Blog</Link> */}
        <Link href="#get-started">Get Started</Link>
        <SocialSection>
        <Icon href="https://www.linkedin.com/company/carbonsmith" target="_blank" rel="noopener noreferrer" style={{ marginRight: '20%'}}>
          <FaLinkedin />
        </Icon>
        <Icon href="https://www.x.com/carbonsmith_io" target="_blank" rel="noopener noreferrer">
          <RiTwitterXFill />
        </Icon>
      </SocialSection>
      </LinksSection>

      <CopyrightSection>
        © 2024 Carbonsmith, All Rights Reserved
      </CopyrightSection>
    </FooterContainer>
  );
};

export default NewFooter;
