import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import LandingBodyTabsBuyer from './LandingBodyTabsBuyer';
import axios from 'axios';
import styled from 'styled-components';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background: linear-gradient(145deg, #fff, #DBE2E9);
`;

const MainContainer = styled.div`
  // flex: 1;
  // background-color: #f5f5f5;
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: #f5f5f5;
  justify-content: space-between;
//   padding: 3%;
`;

const BodyDiv = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;
    padding: 4%;
`;

const BodyHeader = styled.h2`
    font-size: 2em;
    // margin: auto;
    padding: 4% 4%;
    color: #325d79ff;
    justify-content: flex-start; 
    text-align: left;
`;

const ContentContainer = styled.div`
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 5%;
  margin: 3%;
  display: flex;
  flex-direction: column;
//   align-items: center;
  max-width: 80%; // Adjust as needed
  margin-left: auto;
  margin-right: auto;
`;

const MissionTitle = styled.h3`
  font-size: 1.5em;
  color: #325d79ff;
  margin-bottom: 3%;
  text-align: left;
`;

const DescriptionText = styled.p`
  font-size: 1em;
  color: #5f7d95ff;
  line-height: 1.6;
  text-align: left;
  margin-bottom: 3%;

`;


function CompanyDisplay() {
    const navigate = useNavigate();
    const { company } = useParams();
    const requestParams = {};
    const [companyData, setCompanyData] = useState([]);
    // console.log(company, 'oijioj')

    useEffect(() => {


        const fetchCompanyData = async() => {
            const accessToken = localStorage.getItem('access_token');
            const username = localStorage.getItem('user');
            requestParams['company'] = company;
            const config = {
                headers: {
                  'Authorization': `Bearer ${accessToken}`
                }
            }

            if (company) {
                try {
                    const res = await axios.post('https://carbonsmith-handling-69e454e97e3b.herokuapp.com/get_indiv_company', requestParams, config);
                    console.log('company: ', res.data.data);
                    setCompanyData(res.data.data);

                } catch (error) {
                    console.log('Error: ', error);
                }
            }

        }
        fetchCompanyData();
        
    }, [])

    return (
                <MainContainer>
                    <BodyDiv>
                        <ContentContainer>
                            <BodyHeader>{company}</BodyHeader>
                            <MissionTitle>Mission</MissionTitle>
                            <DescriptionText>{companyData.mission}</DescriptionText>
                            <MissionTitle>Additional</MissionTitle>
                            <DescriptionText>{companyData.additionalDesc}</DescriptionText>
                        </ContentContainer>
                        {/* Need to add a contact section eventually */}

                    </BodyDiv>

                </MainContainer>
    );
}

export default CompanyDisplay;