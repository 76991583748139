import React, { useState } from 'react';
import styled from 'styled-components';
import { Titleh3 } from './ExternalUILibrary/ExternalComponents';
import TranslucentLogo from '../../../../images/CS logo - translucent-new.png';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { addedAt, modifiedAt } from '../../../UniversalFunctions/timestamps';

// Styled components
const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(to bottom, white, #0A3C57);
  padding: 20px;

  @media (max-width: 750px) {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 50vh;
      background: linear-gradient(to bottom, white, #0A3C57);
      z-index: -1; // Ensure the pseudo-element is behind the content
    }
    padding-top: 80vh;
    padding-bottom: 80vh;
  }
  @media (min-width: 750px) {
    padding-bottom: 40%;
    padding-top: 40%;
  }
  @media (min-width: 1000px) {
    padding-bottom: 10%;
    padding-top: 40px;
  }
`;

const FormWrapper = styled.div`
  display: flex;
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 1000px;
  width: 100%;
  overflow: hidden;
  margin: 5% 0;

  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

const InfoSection = styled.div`
  flex: 1;
  background: #DFEEF0;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: left;
  text-align: left;
  position: relative;
`;

const InfoTitle = styled.h1`
  font-size: 70px;
  color: #315D79;
  line-height: 1.2;
  font-weight: 400;
  margin-bottom: 20px;

  @media (max-width: 750px) {
    text-align: left;
    font-size: 45px;
    width: 80%;
  }

  @media (min-width: 1100px) {
    line-height: 1.25;
  }
`;

const InfoText = styled.p`
  font-size: 18px;
  color: #315D79;
  font-style: italic;
`;

const FormSection = styled.div`
  flex: 1;
  padding: 40px;
  padding-top: 70px;
  display: flex;
  flex-direction: column;
`;

const RoleSelection = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
`;

const RoleButton = styled.button`
  flex: 1;
  padding: 10px;
  margin: 0 5px;
  font-size: 18px;
  background-color: ${({ active }) => (active ? '#1EB1B5' : '#f0f0f0')};
  color: ${({ active }) => (active ? 'white' : '#315D79')};

  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #1EB1B5;
    color: white;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

const Label = styled.label`
  font-size: 14px;
  color: #0A3C57;
  margin-bottom: 0.5%;
  text-align: left;
  margin-left: 2%;
`;

const Input = styled.input`
  padding: 10px;
  font-size: 16px;
  border: 1px solid #315D79;
  border-radius: 5px;
`;

const TextAreaWrapper = styled(InputWrapper)`
  flex-direction: column;
`;

const TextArea = styled.textarea`
  padding: 10px;
  font-size: 16px;
  border: 1px solid #315D79;
  border-radius: 5px;
  resize: vertical;
`;

const SelectWrapper = styled(InputWrapper)`
  flex-direction: column;
`;

const Select = styled.select`
  padding: 10px;
  font-size: 16px;
  border: 1px solid #315D79;
  border-radius: 5px;
`;

const SubmitButton = styled.button`
  padding: 15px;
  margin-top: 20px;
  font-size: 18px;
  background-color: #1EB1B5;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #17a2b8;
  }
`;

const FirstNameLastName = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

const Logo = styled.img`
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 65%;
  height: auto;
  opacity: 0.5;

  // @media (max-width: 750px) {
  //   width: 
  // }
`;

const NewContactForm = () => {
  const navigate = useNavigate();
  const [role, setRole] = useState('Buyer');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [company, setCompany] = useState('');
  const [message, setMessage] = useState('');
  const [firstTime, setFirstTime] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = {
      account_type: role,
      firstName: firstName,
      lastName: lastName,
      email: email,
      company: company,
      firstTime: firstTime === '1' ? 'No' : 'Yes',
      message: message,
      addedAt: addedAt(),
      modifiedAt: modifiedAt()
    };

    try {
      const res = await axios.post('https://carbonsmith-handling-69e454e97e3b.herokuapp.com/apply_for_carbonsmith', formData);

      if (res.status === 200) {
        alert('Form submitted successfully!');
        // Clear form
        setRole('Buyer');
        setFirstName('');
        setLastName('');
        setEmail('');
        setCompany('');
        setMessage('');
        setFirstTime('');
        navigate('#landing')
      } else {
        alert('Failed to submit the form.');
      }
    } catch (error) {
      alert('An error occurred while submitting the form.');
    }
  };

  return (
    <Container>
      <FormWrapper>
        <InfoSection>
          <InfoTitle>Want to make a difference?</InfoTitle>
          <InfoText>
            Whether selling, buying, or just looking for more information, you can make an impact on our planet through getting involved.
          </InfoText>
          <Logo src={TranslucentLogo} alt="Translucent Logo" />
        </InfoSection>
        <FormSection>
          <Titleh3 style={{ textAlign: 'left', marginLeft: '2%', marginBottom: '1%' }}>I am a...</Titleh3>
          <RoleSelection>
            <RoleButton active={role === 'Buyer'} onClick={() => setRole('Buyer')}>Buyer</RoleButton>
            <RoleButton active={role === 'Seller'} onClick={() => setRole('Seller')}>Seller</RoleButton>
            <RoleButton active={role === 'Other'} onClick={() => setRole('Other')}>Other</RoleButton>
          </RoleSelection>
          <Form onSubmit={handleSubmit}>
            <FirstNameLastName>
              <InputWrapper>
                <Label htmlFor="first-name">First Name*</Label>
                <Input id="first-name" type="text" placeholder="First Name" value={firstName} onChange={(e) => setFirstName(e.target.value)} required />
              </InputWrapper>
              <InputWrapper>
                <Label htmlFor="last-name">Last Name*</Label>
                <Input id="last-name" type="text" placeholder="Last Name" value={lastName} onChange={(e) => setLastName(e.target.value)} required />
              </InputWrapper>
            </FirstNameLastName>
            <InputWrapper>
              <Label htmlFor="company-name">Company Name*</Label>
              <Input id="company-name" type="text" placeholder="My Company" value={company} onChange={(e) => setCompany(e.target.value)} required />
            </InputWrapper>
            <InputWrapper>
              <Label htmlFor="email">Email*</Label>
              <Input id="email" type="email" placeholder="MyEmail@company.com" value={email} onChange={(e) => setEmail(e.target.value)} required />
            </InputWrapper>
            {role === 'Buyer' && (
              <SelectWrapper>
                <Label htmlFor="purchased-before">Purchased Carbon Credits Before?*</Label>
                <Select id="purchased-before" value={firstTime} onChange={(e) => setFirstTime(e.target.value)} required>
                  <option value="0">No</option>
                  <option value="1">Yes</option>
                </Select>
              </SelectWrapper>
            )}
            <TextAreaWrapper>
              <Label htmlFor="message">Message</Label>
              <TextArea id="message" placeholder="Any other details to share? (Optional)" rows="4" value={message} onChange={(e) => setMessage(e.target.value)}></TextArea>
            </TextAreaWrapper>
            <SubmitButton type="submit">Join Priority List</SubmitButton>
          </Form>
        </FormSection>
      </FormWrapper>
    </Container>
  );
};

export default NewContactForm;
