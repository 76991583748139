import { client } from "../../../../utils/sanity/client";
import React, { useState, useEffect } from "react";
import imageUrlBuilder from '@sanity/image-url';
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";


const MainContainer = styled.div`
  max-width: 80%;
  margin: 0 auto;
  padding: 2.5% 5%;
  font-family: Arial, sans-serif;
`;

const ResultsHeading = styled.h2`
  text-align: left;
  font-size: 1.5em;
  color: #00457c;
  margin: 20px 0;
`;

const PostContainer = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #ddd;
  padding-bottom: 3%;
  margin-bottom: 2%;
  padding-top: 2%;
  @media (max-width: 750px) {
    flex-direction: column;
    // margin: auto;
  }
`;

const PostContent = styled.div`
  flex: 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 3%;
  padding-left: 3%;
  text-align: left;
`;

const CategoryLink = styled(Link)`
  margin-right: 10px;
  font-size: 0.9em;
  color: #007db8;
  text-decoration: none;
  margin-bottom: 5px;
  
  &:hover {
    text-decoration: underline;
  }
`;

const Title = styled.h2`
  font-size: 1.75em;
  color: #00457c;
  margin: 0 0 10px 0;
`;

const Image = styled.img`
  flex: 2;
  width: 100%;
  height: auto;
  max-width: 250px;
  object-fit: cover;
  border-radius: 5px;
  margin-right: 20px;
  @media (max-width: 750px) {
    margin: auto;
  }
`;

const AuthorInfo = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

const AuthorImage = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
`;

const AuthorName = styled.h4`
  font-size: 1em;
  color: #555;
`;

const Body = styled.div`
  font-size: 1em;
  line-height: 1.8;
  color: #444;
  margin-bottom: 10px;
`;

const MetaInfo = styled.div`
  font-size: 0.9em;
  color: #777;
  margin-top: 10px;
`;


// Create a builder for image URLs
const builder = imageUrlBuilder(client);

function urlFor(source) {
    return builder.image(source);
}

function truncateText(text, wordLimit, postId) {
    const words = text.split(' ');
    if (words.length > wordLimit) {
        return (
            <>
                {words.slice(0, wordLimit).join(' ')}.. <Link to={`/blog/${postId}`}>Read more</Link>
            </>
        );
    }
    return text;
}

// Fetch content with GROQ, note to explicitly point out what attributes we want from all posts.
async function getContent() {
    const CONTENT_QUERY = `*[_type == "post" && !(_id in path('drafts.**'))] {
  ...,
  author->{
    name, 
    _id
  },
  mainImage {
    asset->{
      _id,
      url 
    }
  },
  categories[]->{
    title, 
    _id
  },
  body,
  publishedAt,
  readTime
}`
    const content = await client.fetch(CONTENT_QUERY);
    console.log('content: ', content);
    return content;
}

// This is the query used to separately feed the category bar.
async function getAllCategories() {
    const query = `*[_type == "category"] {
        _id,
        title
    }`;
    return await client.fetch(query);
}

function DisplayContent() {
    const [content, setContent] = useState([]);
    const [allCategories, setAllCategories] = useState([]);
    // The selected category is default to "All".
    const [selectedCategory, setSelectedCategory] = useState("All");
    // For navigation from BlogPageIndiv use.
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const initialCategory = query.get('category');

    // 1. Get posts' content. 2. Get to feed category bar. 3. Track the status of category chosen from BlogPageIndiv's navigation.
    useEffect(() => {
        async function fetchData() {
            const posts = await getContent();
            const categories = await getAllCategories();
            setContent(posts);
            setAllCategories(categories);
        }
        if (initialCategory) {
            setSelectedCategory(initialCategory);
        }
        fetchData();
    }, [initialCategory]);

    // The click event to for the category bar.
    const handleCategoryClick = (category) => {
        setSelectedCategory(category);
    };
    // The list of thumbnails of posts will be filtered to the category chosen by users.
    const filteredContent = selectedCategory === "All"
        ? content
        : content.filter(post => (post.categories || []).some(cat => cat._id === selectedCategory));

    return (
        <MainContainer>
            {/* Category bar. Set "All" first. Every category can be clicked and filter the content. */}
            <div>
                {[{ _id: 'all', title: 'All' }, ...allCategories].map((category, index, array) => (
                    <React.Fragment key={category._id}>
                        <CategoryLink onClick={() => handleCategoryClick(category._id === 'all' ? 'All' : category._id)}>
                            {category.title}
                        </CategoryLink>
                        {index !== array.length - 1 && <span style={{ margin: "0 10px", color: "#007db8" }}>|</span>}
                    </React.Fragment>
                ))}
            </div>
            {/* Show the filteredContent. */}
            {filteredContent.map((item) => {
                const combinedText = item.body
                    .filter(block => block._type === 'block')
                    .map(block => block.children.map(child => child.text).join(' '))
                    .join(' ');

                {/* Truncate the thumbnails. */}
                const truncatedText = truncateText(combinedText, 50, item._id);

                return (
                    <PostContainer key={item._id}>
                        {item.mainImage && (
                            <Image src={urlFor(item.mainImage.asset).url()} alt={item.title} />
                        )}
                        <PostContent>
                            {/*Show the category of a thumbnail. It can be clicked and filter the content. */}
                            <div>
                                {(item.categories || []).map(category => (
                                    <CategoryLink key={category._id} onClick={() => handleCategoryClick(category._id)}>
                                        {category.title}
                                    </CategoryLink>
                                ))}
                            </div>
                            <Title>{item.title}</Title>
                            <Body>
                                <p>{truncatedText}</p>
                            </Body>
                            <MetaInfo>
                          <span>
                              {new Date(item.publishedAt).toLocaleDateString('en-US', {
                              year: 'numeric',
                              month: 'long',
                              day: 'numeric'
                          })}
                          </span>
                            </MetaInfo>
                        </PostContent>
                    </PostContainer>
                );
            })}
        </MainContainer>
    );
}

export default DisplayContent;