import React, { useEffect, useState } from "react";
import axios from "axios";
import styled, { keyframes } from "styled-components";
import LandingBodyTabs from "./LandingBodyTabs";
// import { Modal } from '../CoreProductFrontEndBuyer/BuyerRemovalGoals';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import ProgressBarChart, {
  generateIntervalDates,
} from "../../../UniversalFunctions/ProgressBarChart";
import CarbonRemovedProgressPie from "./GraphSourcing/CarbonRemovalPieChart";
import CustomContentTreemap from "./GraphSourcing/CustomTreeMap";
import ProgressBarDefault from "./GraphSourcing/ProgressBar";
import { Link, useNavigate } from "react-router-dom";
import Spinner from "../../../UniversalFunctions/LoadingGraphic";
import TestingPage from "../Test";
import { Timeline } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import CheckIcon from "@rsuite/icons/legacy/Check";
import CloseIcon from "@rsuite/icons/Close";
import contract_overview from "../../../../images/contract_overview_2.png";
import contract_progression from "../../../../images/contract_progression.png";
import project_page from "../../../../images/project_dashboard.png";
import project_form from "../../../../images/project_submission.png";
import project_selection from "../../../../images/project_selection.png";
import contract_submission from "../../../../images/contract_submission.png";
import { active, local } from "d3";
import { formatNumberDollars } from "../../../UniversalFunctions/Numeric/Format2Decimal";
import {
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent,
  ContractId,
  ContractStatus,
  ContractItem,
  ContractDetails,
  ContractName,
  StandardButton,
  TabsContainer,
  TabsList,
  TabsContent,
  TabsTrigger,
  TabHeader,
  TabsListContainer,
  HeaderContainer,
  HeaderTitle,
  HeaderDescription,
  HeaderActions,
  HeaderButton,
} from "../../../UI/Library/SellerComponents";
import {
  PageContainer,
  MainContainer,
  BodyDiv,
  UpperDivMain,
} from "../../../UI/Library/PageStructure";
import SellerOverviewLanding from "./SellerOverviewLanding";

const WelcomeSectionExisting = styled.div`
  // display: flex;
  // flex-direction: column;
  // background: linear-gradient(145deg, #f0fff4, #defff8);
  flex: 1;
  background-color: white;
  border-radius: 15px;
  padding: 1%;
  margin: 0 1%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

  &:hover {
    transform: scale(1.01);
    transition: 0.5s ease;
  }
`;

const GraphSection = styled.div`
  flex: 1;
  padding: 1%;
  background-color: white;
  border-radius: 15px;
  margin: 0 1%;
  // border: 1px solid #325d7980;
  max-width: 100%;

  &:hover {
    transform: scale(1.005);
    transition: 1s ease;
  }
`;

const TextDivTop = styled.div`
  display: flex;
  flex-direction: column;
`;

const LeftSideWelcome = styled.div``;

const RightSideWelcome = styled.div``;

const TextOnlyLeftRight = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  gap: 2vh;

  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
  }
`;

const ShadedOverview = styled.div`
  height: fit-content;
  width: 100%;
  color: gray;
  background: rgba(128, 128, 128, 0.4);
  text-align: left;
  margin-bottom: 2%;
  border-radius: 7px;
`;

const ShadedOverviewText = styled.div`
  color: black;
  font-size: 1.35rem;
  margin-left: 5%;
  font-weight: bold;
  padding: 0.5%;
`;

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const floatAnimation = keyframes`
  0% { transform: translateY(0px); }
  50% { transform: translateY(-20px); }
  100% { transform: translateY(0px); }
`;

const TooltipContainer = styled.div`
  background-color: white;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
`;

const ToolTipHeader = styled.p`
  margin: 0 0 2% 0;
  color: #333;
  font-size: 1.1em;
  font-weight: bold;
  text-align: left;
  color: #325d79ff;
`;

const TooltipText = styled.p`
  margin: 0;
  color: #333;
  font-size: 0.8em;
  text-align: left;
  margin: 1.5% 0;
`;

const GraphicContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  margin-top: 1%;
  margin-right: 5%;
`;

const BarNew = styled.div`
  width: ${({ width }) => width}%;
  height: 10px;
  background-color: ${({ color }) => color};
  margin: auto 0;
  justify-content: center;
`;

const ContractGraphic = ({ projectCredits, contractCredits, projectName }) => {
  const projectCredits1 = parseInt(projectCredits, 10); // Scale down for display purposes
  const contractCredits1 = parseInt(contractCredits, 10); // Scale down for display purposes
  const totalUsage = ((contractCredits / projectCredits) * 100).toFixed(2);

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <p>
        Using {totalUsage}% of your available credits from "{projectName}"
      </p>
      <GraphicContainer>
        <BarNew
          width={projectCredits1}
          color="#82ca9d"
          title={`Project Credits: ${projectCredits1}`}
        />
        <BarNew
          width={contractCredits1}
          color="#8884d8"
          title={`Contract Credits: ${contractCredits1}`}
        />
      </GraphicContainer>
    </div>
  );
};

function NewTimeline({ project }) {
  return (
    <GraphicContainer>
      {project.approved === "reviewing" && (
        <Timeline isItemActive={(index) => index === 1}>
          <Timeline.Item>Submitted for Review</Timeline.Item>
          <Timeline.Item>In Review</Timeline.Item>
          <Timeline.Item>Accepted</Timeline.Item>
          <Timeline.Item>Begin issuing credits</Timeline.Item>
        </Timeline> // Fixed the closing tag here
      )}

      {project.approved === "accepted" && (
        <Timeline isItemActive={Timeline.ACTIVE_LAST}>
          <Timeline.Item>Submitted for Review</Timeline.Item>
          <Timeline.Item>In Review</Timeline.Item>
          <Timeline.Item
            dot={<CheckIcon style={{ background: "#15b215", color: "#fff" }} />}
          >
            Accepted
          </Timeline.Item>
          <Timeline.Item>Begin issuing credits</Timeline.Item>
        </Timeline> // Fixed the closing tag here
      )}

      {project.approved === "rejected" && (
        <Timeline isItemActive={Timeline.ACTIVE_LAST}>
          <Timeline.Item>Submitted for Review</Timeline.Item>
          <Timeline.Item>In Review</Timeline.Item>
          <Timeline.Item
            dot={<CloseIcon style={{ background: "red", color: "#fff" }} />}
          >
            Rejected
          </Timeline.Item>
        </Timeline> // Fixed the closing tag here
      )}
    </GraphicContainer>
  );
}

const ProjectInfoTextComp = ({ label, value }) => (
  <div
    style={{
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "4px",
    }}
  >
    <span>{label}</span>
    <span>
      {label === "Revenue" ? `$${formatNumberDollars(value)}` : value}
    </span>
  </div>
);

function TestOverview() {
  const navigate = useNavigate();
  const username = localStorage.getItem("user");
  const [userData, setUserData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchUserData = async () => {
      const username = localStorage.getItem("user");
      const accessToken = localStorage.getItem("access_token");
      const firebaseIdToken = localStorage.getItem("firebase_id_token");

      if (!accessToken || !firebaseIdToken) {
        console.error("Tokens are missing");
        return;
      }

      const requestParams = { username: username };
      const config = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Firebase-Token": firebaseIdToken,
        },
      };
      try {
        const res = await axios.post(
          "https://carbonsmith-handling-69e454e97e3b.herokuapp.com/get_all_user_data",
          requestParams,
          config
        );
        //const res = await axios.post('http://127.0.0.1:5000/get_all_user_data', requestParams, config);
        const fetchedData = res.data.data;

        setUserData(fetchedData);
      } catch (error) {
        console.log("An error occurred: ", error);
      }
      setIsLoading(false);
    };
    fetchUserData();
  }, []);

  const renderExistingUserContent = (userData) => (
    <>
      <UpperDivMain>
        {userData && <SellerOverviewLanding userData={userData} />}
      </UpperDivMain>
    </>
  );

  return isLoading ? <Spinner /> : <>{renderExistingUserContent(userData)}</>;
}

export default TestOverview;
