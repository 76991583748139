import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

// Import your components
import Landing from '../Pages/Landing';
import AboutTeam from '../Pages/PageComponents/AboutTeam'; // Create this component for team details
import Contact from '../Pages/PageComponents/ContactPage';
import Test from '../Pages/PageComponents/Test';
import SignInPage from '../Pages/PageComponents/CoreProductFrontEndSeller/SignIn';
import LoggedInLandingBody from '../Pages/PageComponents/CoreProductFrontEndSeller/LoggedInLandingBody';
import { AuthProvider } from './AuthProvider';
import LoginPage from '../Pages/PageComponents/CoreProductFrontEndSeller/Login';
import CarbonRemovedPage from '../Pages/PageComponents/CoreProductFrontEndSeller/CarbonRemoved';
import ContractList from '../Pages/PageComponents/CoreProductFrontEndSeller/ContractList';
import ContractHistory from '../Pages/PageComponents/CoreProductFrontEndSeller/ContractHistory';
import ContractBuilder from '../Pages/PageComponents/CoreProductFrontEndSeller/ContractBuilder';
import ContractIndividual from '../Pages/PageComponents/CoreProductFrontEndSeller/ContractIndividual';
import YourRemovals from '../Pages/PageComponents/CoreProductFrontEndBuyer/YourRemovals';
import ContractIndividualBuyerPage from '../Pages/PageComponents/CoreProductFrontEndBuyer/ContractIndividualBuyer';
import RemovalGoals from '../Pages/PageComponents/CoreProductFrontEndBuyer/BuyerRemovalGoals';
import GoalIndividualBuyerPage from '../Pages/PageComponents/CoreProductFrontEndBuyer/GoalIndividualBuyer';
import MarketIndivPage from '../Pages/PageComponents/CoreProductFrontEndBuyer/MarketIndivPage';
import ContractHistoryBuyers from '../Pages/PageComponents/CoreProductFrontEndBuyer/ContractHistoryBuyers';
import UserProfileBuyer from '../Pages/PageComponents/CoreProductFrontEndBuyer/UserProfileBuyer';
import RemovalTargets from '../Pages/PageComponents/CoreProductFrontEndSeller/RemovalTargets';
import OurMission from '../Pages/PageComponents/OurMission';
import CompanyDisplay from '../Pages/PageComponents/CoreProductFrontEndBuyer/CompanyDisplay';
import TargetPageIndiv from '../Pages/PageComponents/CoreProductFrontEndSeller/TargetPageIndiv';
import AdminPortal from '../Pages/PageComponents/Application/ReviewPortal/ReviewPortal';
import HelpPage from '../Pages/PageComponents/CoreProductFrontEndSeller/HelpPage';
import UserProfileSeller from '../Pages/PageComponents/CoreProductFrontEndBuyer/UserProfileSeller';
import AddProjectPage from '../Pages/PageComponents/CoreProductFrontEndSeller/AddProject';
import AllProjectsPage from '../Pages/PageComponents/CoreProductFrontEndSeller/CompanyProjects';
import IndivProject from '../Pages/PageComponents/CoreProductFrontEndSeller/ProjectIndividual';
import TestOverview from '../Pages/PageComponents/CoreProductFrontEndSeller/NewSellerOverviewPage';
import ProjectReviewIndiv from '../Pages/PageComponents/Application/ProjectReviewIndiv';
import ResetPassword from '../Pages/PageComponents/CoreProductFrontEndSeller/ResetPassword';
import AuthCheck from './JwtDecoding';
import BlogHome from '../Pages/PageComponents/Blog/BlogHome';
import ArticlePage from '../Pages/PageComponents/Blog/BlogPageIndiv';
// import AuthCheck from './TokenExpire';

function AppRouter() {
  return (
      <AuthProvider>
        <Router>
          <Routes>
            {/*Public Routes */}
            <Route exact path="/" element={<Landing />} />
            <Route path="/our-team" element={<AboutTeam />} />
            <Route path="/our-mission" element={<OurMission />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/test" element={<Test />} />
            <Route path="/SignUp" element={<SignInPage />} />
            <Route path="/Login" element={<LoginPage />} />
            <Route path="/blog" element={<BlogHome />} />
            <Route path="/blog/:postId" element={<ArticlePage />} />
            <Route path="/reset-password" element={<ResetPassword />} />

            {/*Protected Routes */}
            {/* Added in authcheck for handling token expiration - logging out if expired */}
            <Route element={<AuthCheck />}>
              <Route path="/LoginLanding" element={<LoggedInLandingBody />} />
              <Route path="/Overview" element={<TestOverview />} />
              <Route path="/Targets" element={<RemovalTargets />} />
              <Route path="/target/:targetName" element={<TargetPageIndiv />} />
              <Route path="/CarbonRemoved" element={<CarbonRemovedPage />} />
              <Route path="/ContractList" element={<ContractList />} />
              <Route path="/ContractHistory" element={<ContractHistory />} />
              <Route path="/ContractBuilder" element={<ContractBuilder />} />
              <Route path="/contract/:contractName"element={<ContractIndividual />}/>
              <Route path="/supplier/:company" element={<CompanyDisplay />} />
              <Route path="/YourRemovals" element={<YourRemovals />} />
              <Route path="/purchased-contract/:contractName"element={<ContractIndividualBuyerPage />} />
              <Route path="/Removal-Goals" element={<RemovalGoals />} />
              <Route path="/Removal-Goals/:goalName" element={<GoalIndividualBuyerPage />} />
              <Route path="/contract/:company/:contract_name" element={<MarketIndivPage />} />
              <Route path="/Contract-History-Buyer" element={<ContractHistoryBuyers />} />
              <Route path="/profile/:user" element={<UserProfileBuyer />} />
              <Route path="/profile-supplier/:user" element={<UserProfileSeller />} />
              <Route path="/Admin" element={<AdminPortal />} />
              <Route path="/support" element={<HelpPage />} />
              <Route path="/add_project" element={<AddProjectPage />} />
              <Route path="/View_Projects" element={<AllProjectsPage />} />
              <Route path="/View_Projects/:project_name" element={<IndivProject />} />
              <Route path="/Admin/Projects/Review/:company/:project_name" element={<ProjectReviewIndiv />} />
            </Route>
          </Routes>
        </Router>
      </AuthProvider>
  );
}

export default AppRouter;