import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import LandingBodyTabs from "../../CoreProductFrontEndSeller/LandingBodyTabs";
import ProjectReview from "../ProjectReview";
import DraggableAccount from "./DraggableAccount";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";

const PageContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  //   background: linear-gradient(145deg, #fff, #DBE2E9);
`;

const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 1rem;
  // background: #f5f5f5;
  border-radius: 8px;
  @media (max-width: 768px) {
    flex-direction: column;
    width: 100vw;
    max-width: 100vw;
    margin-top: 12%;
  }
`;

const BodyDiv = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: center;
  gap: 1rem;

  @media (max-width: 768px) {
    flex-direction: column;
    width: 80vw;
    max-width: 80vw;
  }
`;

const BoardDisplay = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: center;
  @media (max-width: 768px) {
    flex-direction: column;
    margin-top: 2%;
    width: 100vw;
    max-width: 100vw;
  }
`;
const HeaderDiv = styled.div`
  width: 100%;
`;

const HeaderDivText = styled.p`
  font-size: 1.5rem;
  font-weight: bold;
`;

const BoardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 30vw;
  max-width: 30vw;
  gap: 10px;
  border-radius: 8px;
  background-color: #ecf0f6;
  @media (max-width: 768px) {
    width: 80vw;
    max-width: 80vw;
  }
`;

const InnerBorderContainer = styled.div`
  height: 80vh;
  border: 1px solid #325d7980;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden auto;
  border-radius: 8px;
  @media (max-width: 768px) {
    height: auto;
    min-height: 30vh;
    align-items: flex-start;
  }
`;

const TrashContainer = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  width: 5vw;
  height: 10vh;
  background-color: #ecf0f6;
  border: 2px solid #325d7980;
  @media (max-width: 768px) {
    width: 20vw;
    max-width: 80vw;
  }
`;
const NavButton = styled.button`
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background-color: #325d79;
  color: white;
  &:hover {
    background-color: #5f7d95;
  }
`;

const ModalContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 1000;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
`;
const Modal = styled.div`
  height: 30%;
  width: 35%;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  padding: 2px;

  @media (max-width: 768px) {
    width: 50%;
  }
`;

const Button = styled.button`
  padding: 10px 20px;
  width: clamp(75px, 10vw, 200px);
  height: 40px;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
  background-color: #e6f2f1;

  &:hover {
    background-color: #8caa98;
    color: #eaf5ee;
  }
`;

function AdminPortal() {
  const [view, setView] = useState("AdminPortal");
  const [isAdmin, setIsAdmin] = useState(false);
  const [adminData, setAdminData] = useState([]);
  const [awaitingReview, setAwaitingReview] = useState([]);
  const [reviewInProgress, setReviewInProgress] = useState([]);
  const [completedReview, setCompletedReview] = useState([]);
  const [trash, setIsTrash] = useState([]);

  const [confirmModal, setConfirmModal] = useState({
    isVisible: false,
    source: null,
    destination: null,
    movedItem: null,
    trashArea: false,
  });

  const onDragStart = (start) => {
    setConfirmModal((prevModal) => ({
      ...prevModal,
      movedItem: getList(start.source.droppableId)[start.source.index],
    }));
  };
  const onDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination || source.droppableId === destination.droppableId) {
      return; // Exit if no destination or dropped in the same list
    }

    setConfirmModal((prevModal) => ({
      ...prevModal,
      isVisible: true,
      source,
      destination,
    }));
  };

  const handleMove = (source, destination) => {
    const startList = getList(source.droppableId);
    const finishList = getList(destination.droppableId);
    const [movedItem] = startList.splice(source.index, 1);

    finishList.splice(destination.index, 0, movedItem);

    // Update state based on the droppableId
    updateListsState(source.droppableId, [...startList]);
    updateListsState(destination.droppableId, [...finishList]);

    // Make a POST request to update the backend
    updateItemStatusOnBackend(movedItem, destination.droppableId);
  };

  const handleConfirm = (confirmed) => {
    const { source, destination } = confirmModal;
    setConfirmModal({
      isVisible: false,
      source: null,
      destination: null,
      movedItem: null,
    });

    if (confirmed) {
      handleMove(source, destination);
    }
  };

  // Helper to get the list array by droppableId
  const getList = (droppableId) => {
    switch (droppableId) {
      case "Awaiting Review":
        return [...awaitingReview];
      case "Review In Progress":
        return [...reviewInProgress];
      case "Completed":
        return [...completedReview];
      case "Trash":
        return [...trash];
      default:
        return [];
    }
  };

  // Helper to set the state for a specific list
  const updateListsState = (droppableId, newList) => {
    switch (droppableId) {
      case "Awaiting Review":
        setAwaitingReview(newList);
        break;
      case "Review In Progress":
        setReviewInProgress(newList);
        break;
      case "Completed":
        setCompletedReview(newList);
        break;
      case "Trash":
        setIsTrash(newList);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const checkIsAdmin = async () => {
      const user = localStorage.getItem("user");
      const accessToken = localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };
      if (user === "drewhahn23@gmail.com") {
        setIsAdmin(true);

        try {
          const res = await axios.get(
            "https://carbonsmith-handling-69e454e97e3b.herokuapp.com/admin_info_return",
            config
          );
          const fetchedData = res.data;

          const awaiting = [];
          const inProgress = [];
          const completed = [];
          const trashData = [];

          Object.entries(fetchedData).map(([userKey, userValue]) => {
            Object.entries(userValue).map(([profileKey, profileValue]) => {
              // console.log(profileValue)
              Object.entries(profileValue).map(([detailKey, detailValue]) => {
                const detailObject = { detailKey, ...detailValue };
                // console.log(detailObject);

                if (detailValue.status === "Awaiting Review")
                  awaiting.push(detailObject);
                else if (detailValue.status === "Review In Progress")
                  inProgress.push(detailObject);
                else if (detailValue.status === "Completed")
                  completed.push(detailObject);
                else if (detailValue.status === "Trash")
                  trashData.push(detailObject);

                // if (detailValue.status === 'Awaiting Review') awaiting.push({detailKey, ...detailValue});
                // else if (detailValue.status === 'In Progress') inProgress.push({detailKey, ...detailValue})
                // else if (detailValue.status === 'Completed') completed.push({detailKey, ...detailValue});
                // else if (detailValue.status === 'trash') trashData.push({detailKey, ...detailValue});
              });
            });
          });

          setAwaitingReview(awaiting);
          setReviewInProgress(inProgress);
          setCompletedReview(completed);
          setIsTrash(trashData);
          // const parsedData = Object.keys(fetchedData).map(key => {
          //     const accountInfo = fetchedData[key][Object.keys(fetchedData[key])[0]];
          //     return { username: key.replace(/64/g, '@').replace(/46/g, '.'), ...accountInfo };
          // });
          // setAdminData(fetchedData);
        } catch (error) {
          console.log("An error occurred: ", error);
        }
      }
    };

    checkIsAdmin();
  }, []);

  const updateItemStatusOnBackend = async (movedItem, newLocation) => {
    const accessToken = localStorage.getItem("access_token");
    const firebaseIdToken = localStorage.getItem("firebase_id_token");
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Firebase-Token": firebaseIdToken,
        "Content-Type": "application/json",
      },
    };
    const changeData = {
      ...movedItem,
      newLocation: newLocation,
    };
    try {
      const res = await axios.post(
        "https://carbonsmith-handling-69e454e97e3b.herokuapp.com/change_application_status",
        changeData,
        config
      );
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const moveItemToNewList = (idx, source, destination) => {
    const startList = getList(source);
    const finishList = getList(destination);
    const [movedItem] = startList.splice(idx, 1);
    finishList.push(movedItem);
    // Update state based on the droppableId
    updateListsState(source, [...startList]);
    updateListsState(destination, [...finishList]);

    // Make a POST request to update the backend
    updateItemStatusOnBackend(movedItem, destination.droppableId);
  };

  const renderView = () => {
    switch (view) {
      case "AdminPortal":
        return (
          <BoardDisplay>
            {confirmModal.isVisible && (
              <ModalContainer>
                <Modal>
                  <p style={{ fontWeight: "bold", fontSize: "1rem" }}>
                    Are you sure you want to move this item?
                  </p>
                  <div>
                    <p>{confirmModal.movedItem.email}</p>
                    <p style={{ fontWeight: 600 }}>
                      {confirmModal.movedItem.company}
                    </p>
                    <p>{`From: ${confirmModal.source.droppableId} to ${confirmModal.destination.droppableId}`}</p>
                  </div>
                  <div>
                    <Button
                      onClick={() => handleConfirm(true)}
                      style={{ backgroundColor: "#008074ff", color: "white" }}
                    >
                      Yes
                    </Button>
                    <Button onClick={() => handleConfirm(false)}>No</Button>
                  </div>
                </Modal>
              </ModalContainer>
            )}
            <BoardContainer>
              <InnerBorderContainer>
                <HeaderDiv>
                  <HeaderDivText>Waiting for Review</HeaderDivText>
                </HeaderDiv>
                <Droppable droppableId="Awaiting Review">
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      style={{
                        width: "20vw",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <DraggableAccount
                        data={awaitingReview}
                        containerId={"Awaiting Review"}
                        setConfirmModal={setConfirmModal}
                      />
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </InnerBorderContainer>
            </BoardContainer>

            <BoardContainer>
              <InnerBorderContainer>
                <HeaderDiv>
                  <HeaderDivText>Review in Progress</HeaderDivText>
                </HeaderDiv>
                <Droppable droppableId="Review In Progress">
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      style={{
                        width: "20vw",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <DraggableAccount
                        data={reviewInProgress}
                        containerId={"Review In Progress"}
                        setConfirmModal={setConfirmModal}
                      />
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </InnerBorderContainer>
            </BoardContainer>
            <BoardContainer>
              <InnerBorderContainer>
                <HeaderDiv>
                  <HeaderDivText>Completed Review</HeaderDivText>
                </HeaderDiv>
                <Droppable droppableId="Completed">
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      style={{
                        width: "20vw",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <DraggableAccount
                        data={completedReview}
                        containerId={"Completed"}
                        setConfirmModal={setConfirmModal}
                      />
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </InnerBorderContainer>
            </BoardContainer>

            <FontAwesomeIcon
              icon={faTrashCan}
              style={{
                fontSize: "2rem",
                position: "fixed",
                bottom: "20",
                right: "20",
                zIndex: "1000",
              }}
            />
            <TrashContainer>
              <Droppable droppableId="Trash">
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    style={{ position: "relative" }}
                  >
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </TrashContainer>
          </BoardDisplay>
        );
      case "ProjectReview":
        return (
          <>
            <ProjectReview />
          </>
        );
      default:
        return <p>Invalid view</p>;
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
        <MainContainer>
          <BodyDiv>
            <NavButton onClick={() => setView("AdminPortal")}>
              User Review
            </NavButton>
            <NavButton onClick={() => setView("ProjectReview")}>
              Project Review
            </NavButton>
          </BodyDiv>
          {isAdmin ? (
            renderView()
          ) : (
            <p>You are not authorized to view this page</p>
          )}
        </MainContainer>
    </DragDropContext>
  );
}

export default AdminPortal;
