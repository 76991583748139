import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { formatNumberDollars, format2Decimals } from "./Numeric/Format2Decimal";
import { StatusFormatted } from "./StatusFormat";

const StyledH4 = styled.h4`
  color: #325d79ff; // Darker Teal
  font-size: 1.2em;
  margin-bottom: 8px;
  font-weight: bold;
`;

const StyledParagraph = styled.p`
  color: #5f7d95ff; // Dark Grey
  font-size: 1em;
  line-height: 1.5;
  margin-bottom: 10px;
  text-align: left;
  margin-left: 10%;
`;

const MainContractContainer = styled.div`
  padding: 2%;
  display: flex;
  flex-direction: column;
  padding-bottom: 1%;
`;

const MainContractContainerTitle = styled.p`
  color: #325d79ff;
  font-weight: bold;
  margin-top: 0.7%;
  text-align: left;
  font-size: 1.25em;
  margin-left: 2%;
`;

const ContractContainerMain = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  padding: 1%;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 2vh;
  }
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const ContractIndivContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 5%;
  background-color: #f2f2f2;
  // padding: 2%:
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  min-width: 25%;
  padding-top: 2%;
  padding-bottom: 1%;
  border: 1px solid #325d7980;

  &:hover {
    transform: scale(1.02);
    cursor: pointer;
  }
`;

const ContractTitleDiv = styled.div`
  margin-bottom: 1%;
`;

const ContractBodyDiv = styled.div`
  font-size: 0.9em;
  text-align: left;
  padding: 4%;
`;

const ContractTitleIndiv = styled.p`
  color: #325d79ff;
  font-weight: bold;
  margin: 0 0 1% 0;
`;

const ContractBodyLine = styled.div`
  display: flex;
  flex-direction: row;
`;

const ContractBodyTitle = styled.p`
  font-weight: bold;
  color: #5f7d95ff;
  margin-top: 0;
  margin-bottom: 0;
`;

const ContractBodyIndiv = styled.p`
  color: #5f7d95ff;
  margin-left: 2%;
  margin-top: 0;
  margin-bottom: 0;
`;

const StatusIndicator = styled.div`
  display: inline-block;
  background-color: #f0f0f0;
  color: green;
  padding: 2%;
  padding-top: 0;
  border-radius: 5px;
  font-size: 1em;
  font-weight: bold;
  margin-left: 2%; // Adjust spacing as needed
`;

const StatusIndicatorCompleted = styled.div`
  display: inline-block;
  background-color: #f0f0f0;
  color: blue;
  padding: 2%;
  padding-top: 0;
  border-radius: 5px;
  font-size: 1em;
  font-weight: bold;
  margin-left: 2%; // Adjust spacing as needed
`;

const StatusIndicatorUninitialized = styled.div`
  display: inline-block;
  background-color: #f0f0f0;
  color: gray;
  padding: 2%;
  padding-top: 0;
  border-radius: 5px;
  font-size: 1em;
  font-weight: bold;
  margin-left: 2%; // Adjust spacing as needed
`;

function ContractPreviewsProfile() {
  const [userDataAll, setUserDataAll] = useState({});
  // const [contractName, setContractName] = useState('');
  const requestParams = {};
  const [userContractsAll, setUserContractsAll] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const user = localStorage.getItem("user");
    const accessToken = localStorage.getItem("access_token");

    const getUserInfoAll = async () => {
      const config = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };
      requestParams["username"] = user;
      console.log("user", user)
      try {
        const res = await axios.post(
          "https://carbonsmith-handling-69e454e97e3b.herokuapp.com/get_all_user_data",
          requestParams,
          config
        );
        // console.log('all data: ', res.data);
        console.log("contracts: ", res.data.data.contracts);
        // console.log('object type: ', typeof(res.data.data.contracts))
        setUserDataAll(res.data.data);
        setUserContractsAll(res.data.data.contracts);
      } catch (error) {
        console.log("An error occurred: ", error);
      }
    };

    getUserInfoAll();
  }, []);

  return (
    <MainContractContainer>
      <MainContractContainerTitle>Contracts</MainContractContainerTitle>
      {userContractsAll ? (
        <ContractContainerMain>
          {Object.keys(userContractsAll).length > 0 &&
            Object.values(userContractsAll).map((contract) => (
              <ContractIndivContainer
                onClick={() => {
                  const contractUrl = `/contract/${contract.contractName}`;
                  navigate(contractUrl);
                }}
              >
                <ContractTitleDiv>
                  <ContractTitleIndiv>
                    {contract.contractName}
                  </ContractTitleIndiv>
                </ContractTitleDiv>
                <ContractBodyDiv>
                  <ContractBodyLine>
                    <ContractBodyTitle>Status:</ContractBodyTitle>
                    <ContractBodyIndiv>
                      {" "}
                      {contract.Status === "In Progress" && (
                        <StatusIndicator>Active</StatusIndicator>
                      )}
                      {contract.Status === "Completed" && (
                        <StatusIndicatorCompleted>
                          Completed
                        </StatusIndicatorCompleted>
                      )}
                      {contract.Status === "Uninitialized" && (
                        <StatusIndicatorUninitialized>
                          Uninitialized
                        </StatusIndicatorUninitialized>
                      )}
                    </ContractBodyIndiv>
                  </ContractBodyLine>
                  <ContractBodyLine>
                    <ContractBodyTitle>Revenue:</ContractBodyTitle>
                    <ContractBodyIndiv>
                      {" "}
                      ${formatNumberDollars(contract.Revenue)} USD
                    </ContractBodyIndiv>
                  </ContractBodyLine>
                  <ContractBodyLine>
                    <ContractBodyTitle>Deal Size:</ContractBodyTitle>
                    <ContractBodyIndiv>
                      {" "}
                      {contract.tonsToCapture} tons
                    </ContractBodyIndiv>
                  </ContractBodyLine>
                  <ContractBodyLine>
                    <ContractBodyTitle>Progress:</ContractBodyTitle>
                    <ContractBodyIndiv>
                      {" "}
                      {contract.carbonRemoved} tons removed
                    </ContractBodyIndiv>
                  </ContractBodyLine>
                </ContractBodyDiv>
              </ContractIndivContainer>
            ))}
        </ContractContainerMain>
      ) : (
        <>
          {/* <StyledH4>Contracts</StyledH4> */}
          <StyledParagraph>
            Begin uploading contracts to view them here!
          </StyledParagraph>
        </>
      )}
    </MainContractContainer>
  );
}

export default ContractPreviewsProfile;
