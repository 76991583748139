// import React from 'react';
// import styled from 'styled-components';
// import photo from '../../../../images/main_photo_gary_ellis.jpg'; // Adjust the path if necessary
// import photo2 from  '../../../../images/gary_ellis_photo_2.png';
// import calltoaction from '../../../../images/Homepage_diagram_2.png';
// import { NewHeader } from './NewHeader';

// const LandingContainer = styled.div`
//   position: relative;
//   width: 100%;
//   height: 100vh;
//   overflow: hidden;
//   background-color: white;
// `;

// const BackgroundImage = styled.div`
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background-image: url(${photo2});
//   //background-image: url(${calltoaction});
//   background-repeat: no-repeat;
//   background-size: cover;
//   background-position: center;
//   z-index: 0;
//   // -webkit-mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="white" d="M0,300L48,272C96,200,192,200,288,200C384,215,480,240,576,250C672,257,768,263,864,268C960,272,1056,275,1152,275C1248,128,1344,128,1392,160L1440,192L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,300,0C300,0,300,0,300,0L0,0Z"></path></svg>');
//   // mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="white" d="M0,340L48,310C96,280,192,240,288,240C384,240,480,260,576,270C672,280,768,290,864,295C960,300,1056,305,1152,295C1248,285,1344,270,1392,250L1440,230L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"></path></svg>');
//   mask-size: cover;
//   mask-repeat: no-repeat;
//   mask-position: bottom;
//   background-color: white; /* Ensure background is white */
//   &::before {
//     content: '';
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     // background: rgba(30, 90, 140, 0.33); /* Semi-transparent darker blue overlay */
//     z-index: 1;
//   }
// `;

// // const BackgroundImage = styled.div`
// //   position: absolute;
// //   top: 0;
// //   left: 0;
// //   width: 50%;
// //   height: 50%;
// //   background-image: url(${calltoaction});
// //   background-repeat: no-repeat;
// //   background-size: cover;
// //   background-position: center;
// //   z-index: 0;
// //     &::before {
// //     content: '';
// //     position: absolute;
// //     top: 0;
// //     left: 0;
// //     width: 100%;
// //     height: 100%;
// //     // background: rgba(30, 90, 140, 0.33); /* Semi-transparent darker blue overlay */
// //     z-index: 1;
// //   }
// // `;


// const MainContent = styled.main`
//   position: relative;
//   z-index: 2; /* Ensure MainContent is above the overlay */
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   height: 100vh;
//   text-align: center;
//   color: white;
// `;

// const Title = styled.h1`
//   font-size: 48px;
//   margin: 0;
// `;

// const Subtitle = styled.p`
//   font-size: 24px;
//   margin: 10px 0 0;
//   width: 55%;
// `;

// const NewLandingTop = () => {
//   return (
//     <LandingContainer>
//       <BackgroundImage />
//       <NewHeader absolute={true}/>
//       <MainContent>
//         <Title>Make Net-Zero Attainable</Title>
//         {/* <Subtitle>
//           Sustainability should be open and accessible. 
//         </Subtitle> */}
//         <Subtitle>
//           A marketplace and SaaS tool for Carbon and Renewable Energy Markets.
//         </Subtitle>
//       </MainContent>
//     </LandingContainer>
//   );
// };

// export default NewLandingTop;
// import React from 'react';
// import styled from 'styled-components';
// import calltoaction from '../../../../images/Homepage_diagram_2.png'; // Adjust the path if necessary
// import { NewHeader } from './NewHeader';
// import { NewHeaderLight } from './NewHeader';

// const LandingContainer = styled.div`
//   position: relative;
//   width: 100%;
//   height: 100vh;
//   overflow: hidden;
//   background-color: white;
// `;

// const BackgroundImage = styled.div`
//   position: absolute;
//   top: 10%;
//   right: 10%;
//   width: 40%; /* Adjust this to fit the image size */
//   height: 80%;
//   background-image: url(${calltoaction});
//   background-repeat: no-repeat;
//   background-size: contain; /* Make sure the image doesn't stretch */
//   background-position: center;
//   z-index: 0;
// `;

// const MainContent = styled.main`
//   position: relative;
//   z-index: 2;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: flex-start; /* Align items to the left */
//   padding-left: 5%;
//   height: 100vh;
//   color: black;
// `;

// const Title = styled.h1`
//   font-size: 48px;
//   margin: 0 0 20px;
//   color: #0A3C57;
// `;

// const Subtitle = styled.p`
//   font-size: 24px;
//   margin: 10px 0;
//   width: 55%;
//   color: #333;
// `;

// const BulletList = styled.ul`
//   list-style: none;
//   padding: 0;
//   margin: 20px 0 0;
// `;

// const BulletItem = styled.li`
//   font-size: 20px;
//   margin: 10px 0;
//   display: flex;
//   align-items: center;
//   color: #333;
  
//   &::before {
//     content: '✓';
//     color: #009D6A;
//     font-weight: bold;
//     margin-right: 10px;
//     font-size: 24px;
//   }
// `;

// const CTAButton = styled.button`
//   background-color: #009D6A;
//   color: white;
//   border: none;
//   border-radius: 5px;
//   padding: 15px 30px;
//   font-size: 18px;
//   margin-top: 30px;
//   cursor: pointer;
  
//   &:hover {
//     background-color: #007A54;
//   }
// `;

// const NewLandingTop = () => {
//   return (
//     <LandingContainer>
//       <BackgroundImage />
//       <NewHeaderLight absolute={false}/>
//       <MainContent>
//         <Title>Marketplace for high-quality, durable carbon credit</Title>
//         <Subtitle>We supercharge technology and finance to create a real impact</Subtitle>
//         <BulletList>
//           <BulletItem>Portfolio management</BulletItem>
//           <BulletItem>High quality credits</BulletItem>
//           <BulletItem>Zero cost</BulletItem>
//         </BulletList>
//         <CTAButton>Get Started</CTAButton>
//       </MainContent>
//     </LandingContainer>
//   );
// };

// export default NewLandingTop;

import React from 'react';
import styled from 'styled-components';
import calltoaction from '../../../../images/Homepage_diagram_2.png'; // Adjust the path if necessary
import { NewHeaderLight } from './NewHeader';
import { FaCheckCircle } from 'react-icons/fa';
import { StandardButton } from './ExternalUILibrary/ExternalComponents';

const LandingContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: white;
`;

const BackgroundImage = styled.div`
  position: absolute;
  top: 15%;
  right: 13%;
  width: 33%; /* Adjusted to make the image slightly smaller */
  height: 75%; /* Slight adjustment to the image height */
  background-image: url(${calltoaction});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  z-index: 0;

  @media (max-width: 768px) {
    top: 5%;
    right: 5%;
    width: 60%;
  }
`;

const MainContent = styled.main`
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
  padding-left: 7%;
  padding-top: 5%;
  max-width: 60%; /* Limit text width */
  color: black;
  @media (max-width: 768px) {
    max-width: 80%;

  }
`;

const Title = styled.h1`
  font-size: 48px;
  margin: 0 0 20px;
  color: #0A3C57;
  line-height: 1.2;

  @media (max-width: 768px) {
    font-size: 28px;
  }
`;

const Highlight = styled.span`
  color: #1EB1B5; /* Adjust the color for "high-quality" and "durable" */
`;

const Subtitle = styled.p`
  font-size: 30px;
  margin: 10px 0;
  margin-bottom: 0;
  width: 80%; 
  color: #0A3C57;
  font-family: 'Roboto', sans-serif;
  font-weight: 300; /* Roboto Light */
  line-height: 1;

  @media (max-width: 768px) {
    font-size: 20px;
    width: 50%;
  }
`;

const BulletList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 20px 0 0;
  @media (max-width: 768px) {
    margin-top: 35%;
  }

`;

const BulletItem = styled.li`
  font-size: 24px;
  margin: 10px 0;
  padding: 10px;
  display: flex;
  align-items: center;
  color: #0A3C57;
  font-family: 'Roboto', sans-serif;

  @media (max-width: 768px) {
    font-size: 16px;
    text-align: left;
  }
  
`;

const CTAButton = styled(StandardButton)`
  cursor: pointer;
  background-color: #1EB1B5;
  color: white;
  margin-top: 2%;
  margin-bottom: 4%;
  
  &:hover {
    transform: scale(1.01);
    background-color: #179fa3;
    cursor: pointer;
  }

  @media (max-width: 768px) {
    margin: 0 auto;
  }
`;

const CheckIcon = styled(FaCheckCircle)`
  color: #1EB1B5;
  margin-right: 10px;
  // font-size: 24px;

  @media (max-width: 750px) {
    font-size: 1rem;
  }

`;

const ButtonHolder = styled.div`
  width: 60%;
  margin-top: 10px;
  align-content: center;
  text-align: center;
`;


const NewLandingTop = () => {
  return (
    <LandingContainer>
      <BackgroundImage />
      <NewHeaderLight absolute={false}/>
      <MainContent>
        <Title>
          Marketplace for <Highlight>high-quality,</Highlight> <Highlight>durable</Highlight> carbon credits
        </Title>
        <Subtitle>
          We supercharge technology and finance to create a real impact
        </Subtitle>
        <BulletList>
          <BulletItem><CheckIcon />&nbsp;&nbsp;Portfolio management</BulletItem>
          <BulletItem><CheckIcon />&nbsp;&nbsp;Data-driven credits</BulletItem>
          <BulletItem><CheckIcon />&nbsp;&nbsp;Zero cost</BulletItem>
        </BulletList>
        <ButtonHolder>
          <CTAButton><a href="#contact-form" style={{ textDecoration: 'none', color: 'white'}}>
            Get Started
          </a></CTAButton>
        </ButtonHolder>
      </MainContent>
    </LandingContainer>
  );
};

export default NewLandingTop;
