import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';
import { FaEdit, FaMapMarkerAlt, FaBriefcase, FaUserTie, FaChartLine, FaFileContract } from 'react-icons/fa';
import Spinner from '../../../UniversalFunctions/LoadingGraphic';
import ContractPreviewsProfile from '../../../UniversalFunctions/ContractPreviewsBuyerProfile';
import GoalsPreviewsProfile from '../../../UniversalFunctions/GoalsPreviewsProfile';
import { PageContainer, BodyDiv, MainContainer } from '../../../UI/Library/PageStructure';
import defaultImage from "../../../../images/default_headshot.png";

const ProfileContainer = styled(BodyDiv)`
  display: flex;
  flex-direction: column;
  gap: 30px;
  // padding: 30px;
  background: linear-gradient(145deg, #f6f9fc 0%, #ffffff 100%);
`;

const ProfileHeader = styled.div`
  display: flex;
  align-items: center;
  background: white;
  border-radius: 15px;
  padding: 30px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  
  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
`;

const ProfileImageContainer = styled.div`
  position: relative;
  margin-right: 30px;
  
  @media (max-width: 768px) {
    margin-right: 0;
    margin-bottom: 20px;
  }
`;

const ProfileImage = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  border: 5px solid #325d79;
  box-shadow: 0 5px 15px rgba(50, 93, 121, 0.3);
  background-color: #f0f0f0;
`;

const EditIconContainer = styled.div`
  position: absolute;
  bottom: 5px;
  right: 5px;
  background: #325d79;
  border-radius: 50%;
  padding: 8px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    transform: scale(1.1);
  }
`;

const ProfileInfo = styled.div`
  flex: 1;
`;

const ProfileName = styled.h1`
  font-size: 32px;
  margin: 0 0 10px;
  color: #325d79;
`;

const ProfileRole = styled.h2`
  font-size: 20px;
  margin: 0 0 15px;
  color: #5f7d95;
`;

const ProfileBio = styled.p`
  font-size: 16px;
  color: #333;
  margin: 0;
  line-height: 1.6;
`;

const EditButton = styled.button`
  background-color: #325d79;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  transition: background-color 0.3s;
  margin-top: 20px;
  
  &:hover {
    background-color: #2a4d66;
  }
  
  svg {
    margin-right: 8px;
  }
`;

const ContentSection = styled.div`
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  overflow: hidden;
`;

const SectionTitle = styled.h3`
  font-size: 24px;
  margin: 0;
  padding: 20px 30px;
  background: #325d79;
  color: white;
`;

const InfoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  padding: 30px;
`;

const InfoItem = styled.div`
  display: flex;
  align-items: center;
  padding: 15px;
  background: #f6f9fc;
  border-radius: 10px;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  }
`;

const InfoIcon = styled.div`
  margin-right: 15px;
  color: #325d79;
  font-size: 24px;
`;

const InfoText = styled.span`
  font-size: 16px;
  color: #333;
`;

const ActivitySection = styled(ContentSection)`
  display: flex;
  flex-direction: column;
  height: 500px;
`;

const TabContainer = styled.div`
  display: flex;
  background: #f6f9fc;
`;

const Tab = styled.button`
  flex: 1;
  background-color: ${props => props.active ? '#4a8db7' : 'transparent'};
  color: ${props => props.active ? 'white' : '#325d79'};
  border: none;
  padding: 15px 20px;
  font-size: 18px;
  cursor: pointer;
  transition: all 0.3s ease;
  
  &:hover {
    background-color: ${props => props.active ? '#4a8db7' : '#e0e0e0'};
  }
`;

const TabContent = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 20px;
`;

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  width: 90%;
  max-width: 500px;
`;

const ModalTitle = styled.h2`
  color: #325d79;
  margin-bottom: 20px;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  resize: vertical;
`;

const ModalButton = styled.button`
  background-color: #325d79;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-right: 10px;

  &:hover {
    background-color: #2a4d66;
  }
`;

const UserProfileSeller = () => {
  const [profileData, setProfileData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('contracts');
  const [showEditModal, setShowEditModal] = useState(false);
  const [showPhotoModal, setShowPhotoModal] = useState(false);
  const [editedData, setEditedData] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    fetchProfileData();
  }, []);

  const fetchProfileData = async () => {
    setLoading(true);
    try {
      const user = localStorage.getItem('user');
      const accessToken = localStorage.getItem('access_token');
      const config = {
        headers: { 'Authorization': `Bearer ${accessToken}` }
      };
      
      let imageUrl = null;
      try {
        const imageRes = await axios.get(`https://carbonsmith-handling-69e454e97e3b.herokuapp.com/profile/get_profile_image?user=${user}`, {
          ...config,
          responseType: 'blob'
        });
        imageUrl = URL.createObjectURL(imageRes.data);
      } catch (imageError) {
        console.warn('No profile image found or error fetching image:', imageError);
        // Set a default image URL or leave as null
        imageUrl = null; // or set to a default image URL
      }
  
      const infoRes = await axios.get(`https://carbonsmith-handling-69e454e97e3b.herokuapp.com/get_profile_info?user=${user}`, config);
  
      setProfileData(prevData => ({
        ...infoRes.data.data,
        profileImage: imageUrl || prevData?.profileImage || null
      }));
      setEditedData(infoRes.data.data);
    } catch (error) {
      console.error('Error fetching profile data:', error);
      setProfileData({}); // Set an empty object as default
    } finally {
      setLoading(false);
    }
  };


  const handleEditProfile = async (e) => {
    e.preventDefault();
    const user = localStorage.getItem("user");
    const accessToken = localStorage.getItem("access_token");
    
    const profileData = {
      username: user,
      firstName: editedData.firstName,
      lastName: editedData.lastName,
      location: editedData.location,
      bio: editedData.bio,
      organization: editedData.organization,
      orgDetails: editedData.orgDetails,
      phoneNumber: editedData.phoneNumber,
      title: editedData.title,
    };
  
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
  
    try {
      const response = await axios.post(
        "https://carbonsmith-handling-69e454e97e3b.herokuapp.com/edit_profile_info",
        profileData,
        config
      );
      
      if (response.status === 200) {
        // Preserve the existing profile image URL
        setProfileData(prevData => ({
          ...prevData,
          ...profileData,
          profileImage: prevData.profileImage // Keep the existing profile image URL
        }));
        setShowEditModal(false);
        alert("Profile updated successfully!");
      } else {
        alert("An error occurred while changing your information.");
      }
    } catch (error) {
      console.error("Error updating profile:", error);
      alert("An error occurred while updating your profile. Please try again.");
    }
  };


  const handlePhotoUpload = async () => {
    if (!selectedFile) return;
  
    const formData = new FormData();
    formData.append('file', selectedFile);
    formData.append('user', localStorage.getItem('user'));
  
    const accessToken = localStorage.getItem('access_token');
    const config = {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data'
      }
    };
  
    try {
      await axios.post('https://carbonsmith-handling-69e454e97e3b.herokuapp.com/profile/image_upload', formData, config);
      setShowPhotoModal(false);
      
      // After successful upload, fetch the new image immediately
      const user = localStorage.getItem('user');
      const imageRes = await axios.get(`https://carbonsmith-handling-69e454e97e3b.herokuapp.com/profile/get_profile_image?user=${user}`, {
        ...config,
        responseType: 'blob'
      });
      const newImageUrl = URL.createObjectURL(imageRes.data);
      
      // Update the profile data with the new image URL
      setProfileData(prevData => ({
        ...prevData,
        profileImage: newImageUrl
      }));
  
      alert("Profile photo updated successfully!");
    } catch (error) {
      console.error('Error uploading photo:', error);
      alert("An error occurred while updating your profile photo. Please try again.");
    }
  };

  if (loading) {
    return (
      <PageContainer>
        <MainContainer>
          <Spinner />
        </MainContainer>
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      <MainContainer>
        <ProfileContainer>
          <ProfileHeader>
          <ProfileImageContainer>
            <ProfileImage 
              src={profileData?.profileImage || defaultImage} 
              alt="Profile" 
              onError={(e) => {
                e.target.onerror = null; 
                e.target.src = defaultImage;
              }}
            />
            <EditIconContainer onClick={() => setShowPhotoModal(true)}>
              <FaEdit color="white" />
            </EditIconContainer>
          </ProfileImageContainer>
            <ProfileInfo>
            <ProfileName>{`${profileData?.firstName || 'First Name'} ${profileData?.lastName || 'Last Name'}`}</ProfileName>
            <ProfileRole>{profileData?.organization || 'Company'}</ProfileRole>
            <ProfileBio>{profileData?.bio || 'No bio provided. Click edit to add your bio!'}</ProfileBio>
              <EditButton onClick={() => setShowEditModal(true)}>
                <FaEdit /> Edit Profile
              </EditButton>
            </ProfileInfo>
          </ProfileHeader>

          <ContentSection>
            <SectionTitle>Information</SectionTitle>
            <InfoGrid>
              <InfoItem>
                <InfoIcon><FaMapMarkerAlt /></InfoIcon>
                <InfoText>{profileData.location || 'Location not specified'}</InfoText>
              </InfoItem>
              <InfoItem>
                <InfoIcon><FaBriefcase /></InfoIcon>
                <InfoText>{profileData.organization || 'Organization not provided'}</InfoText>
              </InfoItem>
              <InfoItem>
                <InfoIcon><FaUserTie /></InfoIcon>
                <InfoText>{profileData.title || 'Chief Carbon Officer'}</InfoText>
              </InfoItem>
              <InfoItem>
                <InfoIcon><FaChartLine /></InfoIcon>
                <InfoText>500 Credits Sold</InfoText>
              </InfoItem>
            </InfoGrid>
          </ContentSection>

          <ActivitySection>
            <SectionTitle>Activity</SectionTitle>
            <TabContainer>
              <Tab active={activeTab === 'contracts'} onClick={() => setActiveTab('contracts')}>
                <FaFileContract /> Contracts
              </Tab>
              <Tab active={activeTab === 'goals'} onClick={() => setActiveTab('goals')}>
                <FaChartLine /> Goals
              </Tab>
            </TabContainer>
            <TabContent>
              <AnimatePresence mode="wait">
                <motion.div
                  key={activeTab}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.3 }}
                >
                  {activeTab === 'contracts' ? <ContractPreviewsProfile /> : <GoalsPreviewsProfile />}
                </motion.div>
              </AnimatePresence>
            </TabContent>
          </ActivitySection>
        </ProfileContainer>

        {showEditModal && (
          <Modal>
            <ModalContent>
              <ModalTitle>Edit Profile</ModalTitle>
              <form onSubmit={handleEditProfile}>
                <Input 
                  placeholder="First Name" 
                  value={editedData.firstName || ''} 
                  onChange={(e) => setEditedData({...editedData, firstName: e.target.value})}
                />
                <Input 
                  placeholder="Last Name" 
                  value={editedData.lastName || ''} 
                  onChange={(e) => setEditedData({...editedData, lastName: e.target.value})}
                />
                <Input 
                  placeholder="Organization" 
                  value={editedData.organization || ''} 
                  onChange={(e) => setEditedData({...editedData, organization: e.target.value})}
                />
                <Input 
                  placeholder="Organization Details" 
                  value={editedData.orgDetails || ''} 
                  onChange={(e) => setEditedData({...editedData, orgDetails: e.target.value})}
                />
                <Input 
                  placeholder="Location" 
                  value={editedData.location || ''} 
                  onChange={(e) => setEditedData({...editedData, location: e.target.value})}
                />
                <Input 
                  placeholder="Title" 
                  value={editedData.title || ''} 
                  onChange={(e) => setEditedData({...editedData, title: e.target.value})}
                />
                <Input 
                  placeholder="Phone Number" 
                  value={editedData.phoneNumber || ''} 
                  onChange={(e) => setEditedData({...editedData, phoneNumber: e.target.value})}
                />
                <TextArea 
                  placeholder="Bio" 
                  value={editedData.bio || ''} 
                  onChange={(e) => setEditedData({...editedData, bio: e.target.value})}
                />
                <ModalButton type="submit">Save Changes</ModalButton>
                <ModalButton type="button" onClick={() => setShowEditModal(false)}>Cancel</ModalButton>
              </form>
            </ModalContent>
          </Modal>
        )}

        {showPhotoModal && (
          <Modal>
            <ModalContent>
              <ModalTitle>Upload New Photo</ModalTitle>
              <Input 
                type="file" 
                onChange={(e) => setSelectedFile(e.target.files[0])} 
              />
              <ModalButton onClick={handlePhotoUpload}>Upload</ModalButton>
              <ModalButton onClick={() => setShowPhotoModal(false)}>Cancel</ModalButton>
            </ModalContent>
          </Modal>
        )}
      </MainContainer>
    </PageContainer>
  );
};

export default UserProfileSeller;
