import React, { useState, useEffect, useRef } from "react";
import styled, { keyframes } from "styled-components";
import axios from "axios";
import { useAuth } from "../../../UniversalFunctions/AuthProvider";
import { Link, useNavigate } from "react-router-dom";
import LandingBodyTabsBuyer from "./LandingBodyTabsBuyer";
import { motion } from "framer-motion";
import onepointfive from "../../../../images/onepointfivedefault.jpeg";
import capture6 from "../../../../images/capture6default.jpeg";
import ecobalance from "../../../../images/dacdefault.jpeg";
import greenfuture from "../../../../images/dacdefault2.jpeg";
import pastoralbackground from "../../../../images/night_landscape_4k.jpeg";
import daytimebackground from "../../../../images/daytime_nature_background.jpeg";
import {
  format2Decimals,
  formatNumberDollars,
} from "../../../UniversalFunctions/Numeric/Format2Decimal";
import { OverviewContainer, OverviewHeader, OverviewMainContent, UpperDivMain, OverviewSection } from "../../../UI/Library/PageStructure";
import { Subtitle, Title, HelpLink, OverviewCard, OverviewCardTitleTop, OverviewCardValue, Card, CardContent, CardHeader, CardTitle, CardDescription, ContractItem, ContractDetails, ContractId, ContractStatus, HeaderContentDiv, ContractIdRow } from "../../../UI/Library/SellerComponents";
import ContractProgressBar from "../CoreProductFrontEndSeller/GraphSourcing/ContractProgressBar";
import { Bar } from "react-chartjs-2";
import dayjs from 'dayjs';
import ProgressBar from "../CoreProductFrontEndSeller/GraphSourcing/ProgressBar";
// import Globe from 'globe.gl';
// import MapChart from './GeoMappingFunctionality';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const LandingBodyContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  background-color: transparent;
  justify-content: space-between;
  // padding: .5%;
`;

const HorizontalBodyMain = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: transparent;
  // background: linear-gradient(145deg, #fff, #DBE2E9);
  // border-radius: 25px;
`;

const ProgressSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 3%;
`;

const ProgressSectionBackgroundDay = styled(ProgressSection)`
  background-image: url(${daytimebackground});
  background-size: cover;
  background-position: center;
  position: relative;
  padding-bottom: 10%;
  // border-radius: 20px;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(
      0,
      0,
      0,
      0.15
    ); // You can adjust the color and opacity to ensure text readability
    border-radius: 20px; // Match your existing border-radius if needed
  }

  > * {
    // Ensure that content inside respects the overlay
    position: relative;
    z-index: 1;
  }
`;

const ProgressSectionBackgroundNight = styled(ProgressSectionBackgroundDay)`
  background-image: url(${pastoralbackground});
`;

const NoContractsMessage = styled.div`
  text-align: center;
`;

const NoContractsTitle = styled.h3`
  font-size: 1.2em;
  color: #325d79ff;
  font-weight: bold;
`;

const NoContractSubText = styled.p`
  animation: ${fadeIn} 1.25s ease-out;
  color: black;
  font-weight: bold;
  font-size: 0.9em;
`;

const TitleDiv = styled.div`
  text-align: center;
  margin: 0% 10%;
  width: 80%;
`;

const MarketsSection = styled.div``;



const ContractDescDiv = styled.div`
  text-align: left;
`;

const MarketTitle = styled.h1`
  font-size: 2em; // Larger and more striking
  color: #325d79ff;
  max-width: 100%;
  margin-bottom: 1%;
  font-weight: bold; // Bold and impactful
  line-height: 1.1;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  text-align: center;
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1%;
  padding: 2%;
`;

const CardLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  margin: 3% 4%;

  &:hover {
    text-decoration: none;
    color: inherit;
  }
`;

const AddShadowContractTitle = styled.h3`
  font-size: 1.25em;
  margin-top: 20%;
  font-weight: bold;
  color: #325d79ff;
`;

const ContractPlus = styled.p`
  font-size: 2em;
  font-weight: bold;
  color: #325d79ff;
  //background: linear-gradient(to right, #325d79ff, #5f7d95ff);
`;

const CardSection = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;

  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

const FirstSection = styled.div`
  width: 50%;
`;

const SecondSection = styled.div`
  width: 50%;
  // display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`;

const LowerDivMain = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 2%;
  gap: 20px;

  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

const LeftSideDiv = styled.div`
  flex: 3;
  display: flex;
  flex-direction: column;
  width: 70%

  @media (max-width: 800px) {
    flex: 1;
  }
`;

const RightSideDiv = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const ChartContainer = styled.div`
  background-color: #fff;
  border-radius: 8px;
  padding: 40px 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  @media (max-width: 800px) {
    width: 100%;
  }
`;

const ChartHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ChartTitle = styled.h3`
  font-size: 18px;
  color: #345D72;
  margin-bottom: 10px;
  text-align: left;
`;

const DateRange = styled.div`
  font-size: 16px;
  color: #1D374A;
  background-color: #DFEFF0;
  padding: 5px 10px;
  border-radius: 5px;
`;

const TransactionsContainer = styled.div`
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  @media (min-width: 800px) {
    min-height: 60vh;
  }
`;

const TransactionsList = styled.ul`
  list-style-type: none;
  padding: 0;
  flex: 1;
  overflow-y: auto;
`;

const TransactionItem = styled.li`
  font-size: 16px;
  color: #0A3C57;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
`;

const TransactionDate = styled.p`
  font-size: 12px;
  color: #6C757D;
  margin-top: -10px;
  margin-bottom: 8%;
  text-align: left;
`;

const CompanyFormatText = styled.p`
  text-align: left;
  color: #345D72;
  font-weight: bold;
`;

const TransactionFormatText = styled.p`
  text-align: right;
  color: #373D46;
  font-weight: bold;
`;

const ActionButton = styled.button`
  background-color: #345D72;
  color: #fff;
  border: none;
  border-radius: 8px;
  padding: 5px 10px;
  cursor: pointer;
  white-space: nowrap;
  &:hover {
    background-color: ${props => (props.primary ? '#007CBA' : '#001F30')};
  }
`;

const NoProjectsMessage = styled.p`
  font-style: italic;
  color: #5f7d95;
  text-align: center;
  padding: 20px;
  font-size: 1.1em;
`;

const NoProjectsLink = styled.span`
  color: #1EB1B5;
  cursor: pointer;
  text-decoration: underline;
  
  &:hover {
    color: #16858a;
  }
`;

const formatDate = (dateString) => {
  return dayjs(dateString).format('MM/DD/YYYY');
};

const calculateColor = (change) => {
  const lightColor = [187, 224, 225]; // #BBE0E1
  const darkColor = [52, 172, 179]; // #34ACB3 

  // Calculate the ratio between -1 and 1 based on the change
  const ratio = Math.max(-1, Math.min(1, change / 100000)); // Adjust the denominator based on typical range of changes
  const color = lightColor.map((lc, i) => Math.round(lc + (darkColor[i] - lc) * ratio));

  return `rgb(${color.join(',')})`;
};

const revenues = [30000, 45000, 35000, 59000, 45000, 40000, 60000, 65000, 70000, 45000, 80000, 85000];
const changes = revenues.map((rev, i) => i === 0 ? 0 : rev - revenues[i - 1]);
const backgroundColors = changes.map(calculateColor);

const data = {
  labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  datasets: [
    {
      label: 'Revenue',
      backgroundColor: backgroundColors,
      borderColor: '#00A8E8',
      data: revenues,
    },
  ],
};

const options = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    y: {
      beginAtZero: true,
      ticks: {
        color: '#568893',
        font: {
          size: 14,
        },
        callback: function(value) {
          return value >= 1000 ? (value / 1000) + 'K' : value;
        },
      },
      grid: {
        display: true,
        drawBorder: false,
      },
    },
    x: {
      ticks: {
        color: '#568893',
        font: {
          size: 14,
        },
      },
      grid: {
        display: false,
        drawBorder: false,
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
  },
};


function LoggedInLandingBodyBuyer() {
  //need to be pulling from user database
  const [contracts, setContracts] = useState([]);
  const [markets, setMarkets] = useState({});
  const [profile, setProfile] = useState({});
  const [creditsPurchased, setCreditsPurchased] = useState(0);
  const [hasContracts, setHasContracts] = useState(false);
  const [creditsDelivered, setCreditsDelivered] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [goals, setGoals] = useState({});
  const [hasGoals, setHasGoals] = useState(false);
  const [budget, setBudget] = useState(0);
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });
  const [transactionList, setTransactionList] = useState([]);
  const requestParams = {};
  const navigate = useNavigate();
  const username = localStorage.getItem("user");
  const hour = new Date().getHours();
  const isDaytime = hour >= 6 && hour <= 18;

  useEffect(() => {
    // Fetch contracts data and set it using setContracts
    const fetchContracts = async () => {
      const username = localStorage.getItem("user");
      const accessToken = localStorage.getItem("access_token");
      requestParams["username"] = username;
      const config = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };
      try {
        const response = await axios.post(
          "https://carbonsmith-handling-69e454e97e3b.herokuapp.com/buyer_contracts_all",
          requestParams,
          config
        );
        const fetchedContracts = response.data.data;
        // console.log("data ", fetchedContracts);

        setContracts(fetchedContracts || []);
        if (Object.keys(fetchedContracts).length > 0) {
          setHasContracts(true);
        }
      } catch (error) {
        console.log("error :", error);
      }
    };

    const fetchGoals = async () => {
      const username = localStorage.getItem("user");
      const accessToken = localStorage.getItem("access_token");
      requestParams["username"] = username;
      const config = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };
      try {
        const response = await axios.post(
          "https://carbonsmith-handling-69e454e97e3b.herokuapp.com/buyer_goals",
          requestParams,
          config
        );
        console.log(response.data.goals);
        const fetchedGoals = response.data.goals;
        setGoals(fetchedGoals || []);
        if (Object.keys(fetchedGoals).length > 0) {
          setHasGoals(true);
        }
      } catch (error) {
        console.log("error: ", error);
      }
    }
    fetchContracts();
    fetchGoals();
  }, []);

  useEffect(() => {
    const assignVariables = () => {
      // console.log('hi')
      // console.log(contracts);
      const contractsArray = Object.values(contracts);
      const soldContracts = contractsArray.filter(contract => contract.contractSold === true);
      setTransactionList(soldContracts);
      if (Object.keys(contracts).length > 0) {
        const contractsArray = Object.values(contracts);
        const creditsPurchsedCalc = contractsArray.reduce((sum, contract) => {
          let credits = 0;
          credits = parseFloat(contract.tonsToCapture) || 0;
          return sum + credits;
        }, 0);

        const creditsDeliveredCalc = contractsArray.reduce((sum, contract) => {
          let credits = 0;
          credits = parseFloat(contract.carbonRemoved) || 0;
          return sum + credits;
        }, 0);

        const monthlyRevenue = Array(12).fill(0);
        const labels = Array(12).fill('').map((_, i) => dayjs().subtract(11 - i, 'month').format('MMM'));
        soldContracts.forEach(contract => {
          const monthIndex = 11 - dayjs().diff(dayjs(contract.timeSold), 'month');
          if (monthIndex >= 0 && monthIndex < 12) {
            if (typeof contract.revenueGenerated === 'string') {
              // console.log(contract.revenueGenerated);
              monthlyRevenue[monthIndex] += parseFloat(contract.revenueGenerated.replace(/[^0-9.-]+/g, ""));
            } else if (typeof contract.revenueGenerated === 'number') {
              monthlyRevenue[monthIndex] += contract.revenueGenerated;
            }
            // setHasTTMSales(true);
          }
        });

        const changes = monthlyRevenue.map((rev, i) => i === 0 ? 0 : rev - monthlyRevenue[i - 1]);
        const backgroundColors = changes.map(calculateColor);

        const data = {
          labels,
          datasets: [
            {
              label: 'Revenue',
              backgroundColor: backgroundColors,
              borderColor: '#00A8E8',
              data: monthlyRevenue,
            },
          ],
        };
        console.log(data);
        // console.log('charttttt', data);
        setChartData(data);

      setCreditsPurchased(creditsPurchsedCalc)
      setCreditsDelivered(creditsDeliveredCalc)
    }
    setIsLoading(false);
    }
    if (hasContracts) {
      assignVariables();
    } 

  }, [hasContracts]);

  useEffect(() => {
    const accessToken = localStorage.getItem("access_token");
    const fetchMarkets = async () => {
      const config = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };

      try {
        const res = await axios.get(
          "https://carbonsmith-handling-69e454e97e3b.herokuapp.com/browse_all_contracts",
          config
        );
        const allContracts = res.data.data;
        console.log("all contracts ", allContracts);
        setMarkets(allContracts);
      } catch (error) {
        console.log("An error has occurred: ", error);
      }
    };
    fetchMarkets();
  }, []);

  useEffect(() => {
    const accessToken = localStorage.getItem("access_token");
    const user = localStorage.getItem("user");
    const fetchUser = async () => {
      const config2 = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };
      try {
        const res = await axios.get(
          `https://carbonsmith-handling-69e454e97e3b.herokuapp.com/get_profile_info?user=${user}`,
          config2
        );
        console.log("profile info: ", res.data);
        const profileInfo = res.data.data;
        setProfile(profileInfo);
      } catch (error) {
        console.log("An error has occurred: ", error);
      }
    };

    fetchUser();
  }, []);

  return (
    <UpperDivMain>
      <OverviewContainer>
      <OverviewMainContent>
        <OverviewHeader>
          <div>
            <Title>Overview</Title>
            <Subtitle>Here's a quick look at your data.</Subtitle>
          </div>
          <HelpLink href="/support">Help?</HelpLink>
        </OverviewHeader>
        <OverviewSection>
          <OverviewCard>
            <OverviewCardTitleTop>Credits Purchased</OverviewCardTitleTop>
            {isNaN(creditsPurchased)? (<></>) : (<div style={{display: 'inline-block', flexDirection: 'row'}}><OverviewCardValue>{creditsPurchased}</OverviewCardValue> <p> credits</p></div>)}

          </OverviewCard>
          <OverviewCard>
            <OverviewCardTitleTop>Credits Delivered</OverviewCardTitleTop>
            {(isNaN(creditsDelivered) || isNaN(creditsPurchased)) ? (<></>) : (<><OverviewCardValue>{((creditsDelivered / creditsPurchased)*100).toFixed(2)}%</OverviewCardValue><p>{creditsDelivered}/{creditsPurchased}<br />credits</p></>)}
          </OverviewCard>
          <OverviewCard style={{ width: '50%'}}>
            <OverviewCardTitleTop>Budget Spent</OverviewCardTitleTop>
            {(budget && budget !== 0) ? (
              <ProgressBar percent={((budget.spent / budget.total)*100).toFixed(2)} />
            ) : (
              <ProgressBar percent={50} />
            )}
          </OverviewCard>

        </OverviewSection>
        <CardSection>
          <Card style={{ flex: 1, maxWidth: '90%', marginRight: '1%'}}>
            <CardHeader>
              <CardTitle style={{ color: '#345D72', lineHeight: '1.3'}}>Contracts</CardTitle>
              <CardDescription style={{color: '#373D46', fontWeight: '100'}}>Your largest contracts</CardDescription>
            </CardHeader>
            {!isLoading && (
              <>
              {Object.keys(contracts).length > 0 ? (
                <>
                <HeaderContentDiv style={{marginBottom: '1%'}}>
                  <div style={{width: '40%'}}></div>
                  <CardDescription style={{marginLeft: '20%', fontSize: '.8rem'}}>CO<sub>2</sub> Removed (tons)</CardDescription>
                  {/* <CardDescription style={{marginTop: '0', fontSize:'.8rem'}}>Remaining Credits</CardDescription> */}
                </HeaderContentDiv>
                <CardContent>
                  {Object.values(contracts).slice(0,2).map((contract) => (
                    <ContractItem style={{display: 'inline-block'}} key={contract.contractName}>
                      <ContractDetails onClick={() => {navigate(`/purchased-contract/${contract.contractName}`)}}>
                        <ContractIdRow>
                          <FirstSection>
                            <ContractId style={{fontSize: '1.5rem', fontWeight: 'bold', color: '#191E27'}}>{contract.contractName}</ContractId>                            
                          </FirstSection>
                          <SecondSection style={{justifyContent: 'space-between'}}>
                            <ProgressBar percent={(contract.carbonRemoved / contract.tonsToCapture) * 100} />
                          </SecondSection>
                        </ContractIdRow>
                      </ContractDetails>
                    </ContractItem>
                  ))}
                </CardContent>
                </>
              ): (
                <>
                <NoProjectsMessage>
                  You don't have any projects uploaded. Get started <NoProjectsLink onClick={() => navigate('/add_project')}>here</NoProjectsLink>
                </NoProjectsMessage>
                </>
              )}
              <ContractIdRow style={{justifyContent: 'space-between', marginTop: '5%'}}>
                <ContractId style={{cursor: 'pointer', textAlign: 'left'}} onClick={() => (navigate(`/Contract-History-Buyer`))}>+ See more</ContractId>
                <ContractId><ActionButton onClick={() => (navigate('/Contract-History-Buyer'))}>Manage Contracts</ActionButton></ContractId>
              </ContractIdRow>
              </>
            )}
          </Card>
          <Card style={{ flex: 1, maxWidth: '90%'}}>
            <CardHeader>
              <CardTitle style={{ color: '#345D72', lineHeight: '1.3'}}>Goals</CardTitle>
              <CardDescription style={{color: '#373D46', fontWeight: '100'}}>Track your targets</CardDescription>              
            </CardHeader>
            {!isLoading && (
              <>
              {Object.keys(goals).length > 0 ? (
                <>
                  <HeaderContentDiv style={{marginBottom: '1%'}}>
                    <div style={{width: '40%'}}></div>
                      {/* <CardDescription style={{fontSize: '.8rem'}}>Removed (tons)</CardDescription> */}
                      <CardDescription style={{marginTop: '0', fontSize:'.8rem'}}>Progress</CardDescription>
                  </HeaderContentDiv>
                  <CardContent>
                    {Object.values(goals).slice(0,2).map((goal) => (
                      <ContractItem style={{display: 'inline-block'}} key={goal.goalName}>
                        <ContractDetails onClick={() => {navigate(`/purchased-contract/${goal.goalName}`)}}>
                          <ContractIdRow>
                            <FirstSection>
                              <ContractId style={{fontSize: '1.5rem', fontWeight: 'bold', color: '#191E27'}}>{goal.goalName}</ContractId>                            
                            </FirstSection>
                            <SecondSection style={{justifyContent: 'space-between'}}>
                              <ProgressBar percent={1/100} />
                            </SecondSection>
                          </ContractIdRow>
                        </ContractDetails>
                      </ContractItem>
                    ))}
                  </CardContent>
                </>
              ): (
                <>
                <NoProjectsMessage>
                  You don't have any projects uploaded. Get started <NoProjectsLink onClick={() => navigate('/add_project')}>here</NoProjectsLink>
                </NoProjectsMessage>
                </>
              )}
              <ContractIdRow style={{justifyContent: 'space-between', marginTop: '5%'}}>
                <ContractId style={{cursor: 'pointer', textAlign: 'left'}} onClick={() => (navigate(`/Removal-Goals`))}>+ See more</ContractId>
                <ContractId><ActionButton onClick={() => (navigate('/Removal-Goals'))}>Manage Goals</ActionButton></ContractId>
              </ContractIdRow>
              </>
            )}
          </Card>

        </CardSection>
        <LowerDivMain>
          <LeftSideDiv>
            <ChartContainer>
              <ChartHeader>
                {hasContracts ? (<ChartTitle>Spend Overview</ChartTitle>) : (
                  <ChartTitle>Example Spend</ChartTitle>
                )}
              </ChartHeader>
              <div style={{flex: 1, width: '100%'}}>
                {hasContracts ? (
                  <Bar data={chartData} options={options} />
                ): (
                  <Bar data={data} options={options} />
                )}
              </div>
            </ChartContainer>
          </LeftSideDiv>
          <RightSideDiv>
            <TransactionsContainer>
              <OverviewCardTitleTop>Recent Transactions</OverviewCardTitleTop>
              <TransactionsList>
                {transactionList.length > 0 && 
              transactionList.map((transaction, i) => (
                <div key={i}>
                    <TransactionItem>
                        <span><CompanyFormatText>{transaction.purchaser}</CompanyFormatText></span>
                        <span><TransactionFormatText>${formatNumberDollars(transaction.revenueGenerated)}</TransactionFormatText></span>
                    </TransactionItem>
                    <TransactionDate>{formatDate(transaction.timeSold)}</TransactionDate>
                </div>
                ))
            }
            {transactionList.length === 0 && (
                <TransactionItem><CompanyFormatText>Sell contracts and begin tracking!</CompanyFormatText></TransactionItem>
            )}
              </TransactionsList>
            </TransactionsContainer>
          </RightSideDiv>
        </LowerDivMain>
        {/* daytime is one background, nighttime is another */}
        {/* {isDaytime ? (
          <ProgressSectionBackgroundDay>
            {profile.firstName ? (
              <ProgressTitle style={{ color: "white" }}>
                Welcome, {profile.firstName}!
              </ProgressTitle>
            ) : (
              <ProgressTitle>Welcome, {username}</ProgressTitle>
            )}
            {Object.keys(contracts).length === 0 ? (
              <ProgressTitleSubheader>
                Explore contracts below to begin your carbon credit journey
              </ProgressTitleSubheader>
            ) : (
              <ProgressTitleSubheader style={{ color: "white" }}>
                Check out your contracts or add more below
              </ProgressTitleSubheader>
            )}

            {contracts.length === 0 ? (
              <NoContractsMessage>
                <NoContractsTitle>No Contracts</NoContractsTitle>
                <NoContractSubText>
                  Get started with available markets below
                </NoContractSubText>
              </NoContractsMessage>
            ) : (
              <ContractsDisplay>
                {Object.values(contracts).map((contract) => (
                  <ContractItem
                    key={contract.contractName}
                    onClick={() =>
                      navigate(`/purchased-contract/${contract.contractName}`)
                    }
                  >
                    <ContractItemTextAll>
                      <ContractTitleNew>
                        {contract.contractName}
                      </ContractTitleNew>
                      <ContractDescDiv>
                        <ContractDesc>
                          <CardTextTitle>Status</CardTextTitle>:{" "}
                          {contract.status}
                        </ContractDesc>
                        <ContractDesc>
                          <CardTextTitle>ID: </CardTextTitle>
                          {contract.contract_hedera}
                        </ContractDesc>
                        <ContractDesc>
                          <CardTextTitle>Tons Removed: </CardTextTitle>
                          {contract.tonsToCapture || contract.Tons}
                        </ContractDesc>
                        <ContractDesc>
                          <CardTextTitle>Price: </CardTextTitle>$
                          {formatNumberDollars(contract.priceTonUSD)}
                        </ContractDesc>
                        <ContractDesc>
                          <CardTextTitle>Total Cost: </CardTextTitle>
                          {contract.cost}
                        </ContractDesc>
                      </ContractDescDiv>
                    </ContractItemTextAll>
                  </ContractItem>
                ))}

                {Object.keys(contracts).length > 0 &&
                  Object.keys(contracts).length < 10 && (
                    <ShadowContract onClick={() => navigate("/")}>
                      <ContractItemTextAll>
                        <AddShadowContractTitle>
                          Add Contract
                        </AddShadowContractTitle>
                        <ContractPlus>+</ContractPlus>
                      </ContractItemTextAll>
                    </ShadowContract>
                  )}
              </ContractsDisplay>
            )}
          </ProgressSectionBackgroundDay>
        ) : (
          <ProgressSectionBackgroundNight>
            {profile.firstName ? (
              <ProgressTitle style={{ color: "white" }}>
                Welcome, {profile.firstName}!
              </ProgressTitle>
            ) : (
              <ProgressTitle>Welcome, {username}</ProgressTitle>
            )}
            {Object.keys(contracts).length === 0 ? (
              <ProgressTitleSubheader>
                Explore contracts below to begin your carbon credit journey
              </ProgressTitleSubheader>
            ) : (
              <ProgressTitleSubheader style={{ color: "white" }}>
                Check out your contracts or add more below
              </ProgressTitleSubheader>
            )}

            {contracts.length === 0 ? (
              <NoContractsMessage>
                <NoContractsTitle>No Contracts</NoContractsTitle>
                <NoContractSubText>
                  Get started with available markets below
                </NoContractSubText>
              </NoContractsMessage>
            ) : (
              <ContractsDisplay>
                {Object.values(contracts).map((contract) => (
                  <ContractItem
                    key={contract.contractName}
                    onClick={() =>
                      navigate(`/purchased-contract/${contract.contractName}`)
                    }
                  >
                    <ContractItemTextAll>
                      <ContractTitleNew>
                        {contract.contractName}
                      </ContractTitleNew>
                      <ContractDescDiv>
                        <ContractDesc>
                          <CardTextTitle>Status</CardTextTitle>:{" "}
                          {contract.status}
                        </ContractDesc>
                        <ContractDesc>
                          <CardTextTitle>ID: </CardTextTitle>
                          {contract.contract_hedera}
                        </ContractDesc>
                        <ContractDesc>
                          <CardTextTitle>Tons Removed: </CardTextTitle>
                          {contract.tonsToCapture || contract.Tons}
                        </ContractDesc>
                        <ContractDesc>
                          <CardTextTitle>Price: </CardTextTitle>$
                          {formatNumberDollars(contract.priceTonUSD)}
                        </ContractDesc>
                        <ContractDesc>
                          <CardTextTitle>Total Cost: </CardTextTitle>
                          {contract.cost}
                        </ContractDesc>
                      </ContractDescDiv>
                    </ContractItemTextAll>
                  </ContractItem>
                ))}

                {Object.keys(contracts).length > 0 &&
                  Object.keys(contracts).length < 10 && (
                    <ShadowContract onClick={() => navigate("/")}>
                      <ContractItemTextAll>
                        <AddShadowContractTitle>
                          Add Contract
                        </AddShadowContractTitle>
                        <ContractPlus>+</ContractPlus>
                      </ContractItemTextAll>
                    </ShadowContract>
                  )}
              </ContractsDisplay>
            )}
          </ProgressSectionBackgroundNight>
        )}

        <MarketsSection>
          <TitleDiv>
            <MarketTitle>Available Markets</MarketTitle>
          </TitleDiv>
          <GridContainer>
            {Object.values(markets).map((market) => (
              <CardLink
                to={`/contract/${market.company}/${market.contract_name}`}
                key={market.contract_name}
              >
                <Card>
                  <CardTitle>{market.company}</CardTitle>
                  {market.company === "1PointFive" && (
                    <CardImage
                      style={{ backgroundImage: `url(${onepointfive})` }}
                    />
                  )}
                  {market.company === "Capture6" && (
                    <CardImage
                      style={{ backgroundImage: `url(${capture6})` }}
                    />
                  )}
                  {market.company === "EcoBalance" && (
                    <CardImage
                      style={{ backgroundImage: `url(${ecobalance})` }}
                    />
                  )}
                  {market.company === "GreenFuture" && (
                    <CardImage
                      style={{ backgroundImage: `url(${greenfuture})` }}
                    />
                  )}

                  <CardText>
                    <CardTextName style={{ fontWeight: "bold" }}>
                      {market.contract_name}
                    </CardTextName>
                  </CardText>
                  <CardText>
                    <CardTextTitle>Tons: </CardTextTitle>
                    {market.contract_details.Tons ||
                      market.contract_details.tonsToCapture}
                  </CardText>
                  <CardText>
                    <CardTextTitle>Price: </CardTextTitle>$
                    {formatNumberDollars(market.contract_details.PriceTonUSD)}{" "}
                    USD
                  </CardText> */}
                  {/* <CardText>Permanence: {market.contract_details.Permanence}%</CardText> */}
                  {/* <CardText>
                    <CardTextTitle>Storage: </CardTextTitle>
                    {market.contract_details.Storage}
                  </CardText>
                  <CardText>
                    <CardTextTitle>Year: </CardTextTitle>
                    {market.contract_details.Year}
                  </CardText>
                  <CardText>
                    <CardTextTitle>Location: </CardTextTitle>
                    {market.contract_details.Location}
                  </CardText>
                </Card>
              </CardLink>
            ))}
          </GridContainer>
        </MarketsSection> */}
        {/* <GlobalImpactMap /> */}
      </OverviewMainContent>
      </OverviewContainer>
    </UpperDivMain>
  );
}

export default LoggedInLandingBodyBuyer;
