import React from 'react';
import styled from 'styled-components';

const BarGraphContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const BarGraphItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const BarGraphLabel = styled.div`
  width: 150px;
  font-size: 14px;
  color: #333;
`;

const BarGraphBar = styled.div`
  position: relative;
  width: 100%;
  height: 30px;
  background-color: #e0e0e0;
  border-radius: 4px;
  overflow: hidden;
`;

const BarGraphProgress = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: #1890ff;
  width: ${props => props.progress}%;
  transition: width 0.5s ease-in-out;
`;

const BarGraphValue = styled.div`
  position: relative;
  top: 0;
  right: 10px;
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #fff;
`;

const BarGraphPercentage = styled.div`
  position: absolute;
  top: 0;
  right: 10px;
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #666;
`;

const ContractProgressBar = (contractInfo) => {
  console.log('contract info: ', contractInfo);
  const contract = {
    id: contractInfo.contractInfo.contract_hedera,
    name: contractInfo.contractInfo.contractName,
    revenue: contractInfo.contractInfo.Revenue,
    progress: ((contractInfo.contractInfo.carbonRemoved / contractInfo.contractInfo.tonsToCapture) * 100).toFixed(2)
  };
  console.log('contract: ', contract);

  return (
    <BarGraphContainer>
      <BarGraphItem key={contract.id}>
        <BarGraphLabel>{contract.name}</BarGraphLabel>
        <BarGraphBar>
          <BarGraphProgress progress={contract.progress} />
          <BarGraphValue>${contract.revenue}</BarGraphValue>
          <BarGraphPercentage>{contract.progress}%</BarGraphPercentage>
        </BarGraphBar>
      </BarGraphItem>
    </BarGraphContainer>
  );
};


export default ContractProgressBar;