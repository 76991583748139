import React, { useRef, useEffect, useState } from 'react';
import styled, { keyframes, css } from 'styled-components';
import { FaCheckCircle, FaCubes, FaCube, FaLayerGroup, FaCloud, FaCloudUploadAlt, FaCloudDownloadAlt } from 'react-icons/fa';
import { MdAccountTree, MdCloud, MdCloudDownload, MdCloudUpload, MdWidgets, MdViewModule, MdViewQuilt } from 'react-icons/md';
import { StandardButton, Title as ExternalTitle } from './ExternalUILibrary/ExternalComponents';
import portfolio_management_img from '../../../../images/portfolio_management_img.png';
import { useNavigate } from 'react-router-dom';
import saas_icon from '../../../../images/Icon-SaaS.png';
import blockchain_icon from '../../../../images/Icon-blockchain.png';


const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(10px);
  }
`;

const animatedStyle = css`
  opacity: ${props => (props.isVisible ? 1 : 0)};
  transform: ${props => (props.isVisible ? 'translateY(0)' : 'translateY(10px)')};
  animation: ${props => (props.isVisible ? fadeIn : fadeOut)} 0.6s ease-out;
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
`;

const SectionContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 20px;
  background-color: #0A3C57;
  color: white;
`;

const Title = styled(ExternalTitle)`
  ${animatedStyle}
  color: white;
  font-size: 32px;
  margin-bottom: 40px;
`;

const FeaturesWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  margin-bottom: 60px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const FeatureBox = styled.div`
  background-color: #0A3C57;
  border: 2px solid white;
  border-radius: 12px;
  padding: 40px 20px;
  width: 100%;
  // padding-bottom: 10%;
  ${animatedStyle}

  @media (max-width: 1000px) {
    margin-bottom: 10%;
  }
`;

const FeatureColumn = styled.div`
  width: 48%;
  
  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 40px;
  }
`;

const FeatureTitle = styled.h3`
  font-size: 24px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  ${animatedStyle}
`;

const FeatureIcon = styled.span`
  margin-right: 10px;
  font-size: 24px;
`;

const FeatureList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const FeatureItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 10%;
  font-size: 20px;
  ${animatedStyle}

  @media (max-width: 768px) {
    text-align: left;
  }
`;

const CheckIcon = styled(FaCheckCircle)`
  color: #1EB1B5;
  margin-right: 10px;
  font-size: 20px;
`;

const Dots = styled.div`
  display: flex;
  justify-content: center;
  margin: 40px 0;
`;

const Dot = styled.span`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${props => props.active ? '#1EB1B5' : 'white'};
  margin: 0 5px;
`;

const PortfolioSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin-top: 40px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const PortfolioContent = styled.div`
  width: 48%;
  
  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 40px;
  }
`;

const PortfolioTitle = styled.h3`
  font-size: 24px;
  margin-bottom: 6%;
  text-align: left;
  ${animatedStyle}
`;

const PortfolioDescription = styled.p`
  font-size: 18px;
  margin-bottom: 20px;
  text-align: left;
  width: 90%;
  ${animatedStyle}

`;

const PortfolioList = styled.ul`
  list-style: none;
  padding: 2% 4%;
  margin: 0;
`;

const PortfolioItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 5%;
  font-size: 18px;
  ${animatedStyle}

  @media (max-width: 768px) {
    text-align: left;
  }
`;

const PortfolioImageWrapper = styled.div`
  width: 48%;
  ${animatedStyle}
  
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const PortfolioImage = styled.img`
  width: 100%;
  border-radius: 8px;
`;

const RequestDemoButton = styled(StandardButton)`
  background-color: #1EB1B5;
  margin-top: 20px;
  &:hover {
    background-color: #179fa3;
  }
`;

const FeatureHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  ${animatedStyle}

  @media (max-width: 750px) {
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
`;

const OrangeCheckIcon = styled(CheckIcon)`
  color: #FFA500;
`;

const IconContainer = styled.div`
  margin-right: 10px;
  font-size: 60px;

  @media (max-width: 750px) {
    font-size: 60px;
  }
`;


const NewFeatures = () => {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      {
        threshold: 0.2,
      }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <SectionContainer ref={sectionRef}>
      <Title isVisible={isVisible}>Our Product Offerings</Title>

        <FeaturesWrapper>
        <FeatureColumn>
          <FeatureHeader isVisible={isVisible}>
            <IconContainer><img src={blockchain_icon} /></IconContainer>
            <FeatureTitle isVisible={isVisible}>Blockchain-Powered Marketplace</FeatureTitle>
          </FeatureHeader>
          <FeatureBox isVisible={isVisible}>
            <FeatureList>
              <FeatureItem isVisible={isVisible}><IconWrapper><CheckIcon /></IconWrapper>Competitive pricing for high-quality, durable credits</FeatureItem>
              <FeatureItem isVisible={isVisible}><IconWrapper><CheckIcon /></IconWrapper>Transparent ledger to prevent double-counting</FeatureItem>
              <FeatureItem isVisible={isVisible}><IconWrapper><CheckIcon /></IconWrapper>Financial support for innovative climate solutions</FeatureItem>
              <FeatureItem isVisible={isVisible} style={{marginBottom: '0'}}><IconWrapper><CheckIcon /></IconWrapper>Real-time smart contract fulfillment</FeatureItem>
            </FeatureList>
          </FeatureBox>
        </FeatureColumn>
        <FeatureColumn style={{marginBottom: '0'}}>
          <FeatureHeader isVisible={isVisible}>
            <IconContainer><img src={saas_icon} /></IconContainer>
            <FeatureTitle isVisible={isVisible}>Data-Centric Software as a Service</FeatureTitle>
          </FeatureHeader>
          <FeatureBox isVisible={isVisible}>
            <FeatureList>
              <FeatureItem isVisible={isVisible}><IconWrapper><OrangeCheckIcon /></IconWrapper>Advanced data management and analytics</FeatureItem>
              <FeatureItem isVisible={isVisible}><IconWrapper><OrangeCheckIcon /></IconWrapper>Industry insights for benchmarking</FeatureItem>
              <FeatureItem isVisible={isVisible}><IconWrapper><OrangeCheckIcon /></IconWrapper>Robust due diligence</FeatureItem>
              <FeatureItem isVisible={isVisible} style={{marginBottom: '0'}}><IconWrapper><OrangeCheckIcon /></IconWrapper>Streamlined Monitoring, Reporting, and Verification</FeatureItem>
            </FeatureList>
          </FeatureBox>
        </FeatureColumn>
        </FeaturesWrapper>
      <Dots>
        <Dot active />
        <Dot />
        <Dot />
      </Dots>
      <PortfolioSection>
        <PortfolioContent>
          <FeatureTitle isVisible={isVisible}>Portfolio Management</FeatureTitle>
          <PortfolioDescription isVisible={isVisible}>
            We put data to work. By helping you make data-driven decisions, we make sure the best projects are the ones receiving funding. Our analytics suite saves you money by mitigating risk while providing forward investment in desireable climate projects.
          </PortfolioDescription>
          <PortfolioList>
            <PortfolioItem isVisible={isVisible}><CheckIcon />Internal insights and recommendations</PortfolioItem>
            <PortfolioItem isVisible={isVisible}><CheckIcon />Broader market analysis</PortfolioItem>
            <PortfolioItem isVisible={isVisible}><CheckIcon />Predictive consulting</PortfolioItem>
          </PortfolioList>
          <RequestDemoButton isVisible={isVisible} onClick={() => (navigate('#contact-form'))}>
          <a href="#contact-form" style={{ textDecoration: 'none', color: 'white'}}>
          Request a Demo
          </a></RequestDemoButton>
        </PortfolioContent>
        <PortfolioImageWrapper isVisible={isVisible}>
          <PortfolioImage src={portfolio_management_img} alt="Portfolio Management" />
        </PortfolioImageWrapper>
      </PortfolioSection>
    </SectionContainer>
  );
};

export default NewFeatures;
