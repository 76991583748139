import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { useAuth } from "../UniversalFunctions/AuthProvider";
// import { useAuth, useAuthInitialization } from '../UniversalFunctions/AuthProvider';
import axios from "axios";
import LoggedInLandingBodyBuyer from "./PageComponents/CoreProductFrontEndBuyer/LoggedInLandingBodyBuyer";
// import SellerOverview from './PageComponents/CoreProductFrontEndSeller/SellerOverview';
import TestOverview from "./PageComponents/CoreProductFrontEndSeller/NewSellerOverviewPage";
import Spinner from "../UniversalFunctions/LoadingGraphic";
import NewLandingTop from "./PageComponents/ExternalSite/NewLandingTop";
import SolutionSection from "./PageComponents/ExternalSite/LandingOurSolution";
import NewFeatures from "./PageComponents/ExternalSite/NewFeatures";
import OurPartners from "./PageComponents/ExternalSite/OurPartners";
import NewContactForm from "./PageComponents/ExternalSite/NewContactForm";
import NewFooter from "./PageComponents/ExternalSite/NewFooter";
import LandingBodyTabsBuyer from "./PageComponents/CoreProductFrontEndBuyer/LandingBodyTabsBuyer";
import LandingBodyTabs from "./PageComponents/CoreProductFrontEndSeller/LandingBodyTabs";
import { OverviewPageContainer } from "../UI/Library/PageStructure";
// import CO2Equal from "./PageComponents/ExternalSite/CO2Equal";

const GuestPageContainer = styled(OverviewPageContainer)`
  flex-direction: column;
`;

function GuestLanding() {
  return (
    <GuestPageContainer>
      {/* <Header />
            <LandingFeatures /> */}
      <div id="landing">
        <NewLandingTop />
      </div>
      <SolutionSection />
      {/* <CO2Equal /> */}
      <NewFeatures />
      <OurPartners />
      <div id="contact-form">
        <NewContactForm />
      </div>
      {/* <MRVSection /> */}
      {/* <GetStartedSection /> */}
      {/* <ContactForm /> */}
      {/* <MapChart /> */}
      <NewFooter />
      {/* <Footer /> */}
    </GuestPageContainer>
  );
}

function LoggedInLandingBuy() {
  return (
    <OverviewPageContainer>
      <LandingBodyTabsBuyer />
      <LoggedInLandingBodyBuyer />
    </OverviewPageContainer>
  );
}

function LoggedInLandingSeller() {
  return (
    <OverviewPageContainer>
      <LandingBodyTabs />
      <TestOverview />
    </OverviewPageContainer>
  );
}

function Landing() {
  const [accountType, setAccountType] = useState({});
  const { authState } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  // console.log('auth state: ', authState);

  const fetchUser = async () => {
    if (authState.isAuthenticated) {
      //console.log("Landing successfully authed1");
      const username = localStorage.getItem("user");
      const accessToken = localStorage.getItem("access_token");
      const firebaseIdToken = localStorage.getItem("firebase_id_token");
      //console.log("landing get firebase id token", firebaseIdToken);
      //console.log("landing get access_token",accessToken);
      //console.log("landing get user", username);

      if (!username) {
        console.error("no user");
        try {
          localStorage.removeItem("firebase_id_token");
          localStorage.removeItem("access_token");
        } catch (error) {
          console.log(error);
        }
        return;
      }

      if (!accessToken || !firebaseIdToken) {
        console.error("Tokens are missing");
        return;
      }

      const config = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Firebase-Token": firebaseIdToken,
        },
      };

      try {
        //const res = await axios.post('http://127.0.0.1:5000/account_type', { username: username }, config);
        const res = await axios.post(
          "https://carbonsmith-handling-69e454e97e3b.herokuapp.com/account_type",
          { username: username },
          config
        );
        const userData = res.data;
        setAccountType(userData["account_type"]);
      } catch (error) {
        console.log("Error getting user data:", error);
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);
  // const { initialized } = useAuthInitialization();
  // const { authState, signOut } = useAuth();

  if (authState.isAuthenticated) {
    if (isLoading) {
      return (
        <div style={{ marginTop: "15%" }}>
          <Spinner />
        </div>
      );
    }
    if (accountType === "Seller") {
      return <LoggedInLandingSeller />;
    } else if (accountType === "Buyer") {
      return <LoggedInLandingBuy />;
    }
  }
  return <GuestLanding />;
}

export default Landing;
