import React, { createContext, useContext, useReducer, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
import { auth } from './firebase'

const AuthContext = createContext();

const getLocalStorageItem = (key) => {
  const value = localStorage.getItem(key);
  return value;
};


const isTokenExpired = (token) => {
  if (!token) {
    return true;
  }
  try {
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    // console.log('Decoded token:', decodedToken);
    // console.log('Current time:', currentTime);
    return decodedToken.exp < currentTime;
  } catch (error) {
    console.error('Error decoding token:', error);
    return true;
  }
};


// Update a lil more check-up info.
const initialState = () => {
  const user = getLocalStorageItem('user');
  const firebaseIdToken = getLocalStorageItem('firebase_id_token');
  const jwtToken = getLocalStorageItem('access_token');
  const isAuthenticated = !isTokenExpired(firebaseIdToken) && !isTokenExpired(jwtToken);

  // Just checks.
  /*
  console.log("Initial Stats, user:", user);
  console.log("Initial Stats, firebase_id_token:", firebaseIdToken);
  console.log("Initial Stats, jwt_token:", jwtToken);
  console.log("Initial Stats, isAuthenticated:", isAuthenticated);

  if (isTokenExpired(firebaseIdToken)) {
    console.log('firebase has not been authenticated');
  }
  if (!isTokenExpired(firebaseIdToken)) {
    console.log('firebase has been authenticated');
  }
  if (isTokenExpired(jwtToken)) {
    console.log('jwtToken has not been authenticated')
  }
  if (!isTokenExpired(jwtToken)) {
    console.log('jwtToken has been authenticated')
  }
  if (isTokenExpired(firebaseIdToken)) {
    console.log('firebase not authenticated');
  }
   */
  if (!isAuthenticated) {
    //console.log('either jwt or firebase has not been authenticated');
    clearAuthData();
  }
  return {
    user: isAuthenticated ? user: null,
    isAuthenticated,
  };
};

const authReducer = (state, action) => {
  switch (action.type) {
    case 'SIGN_IN':
      return {
        ...state,
        user: action.payload.user,
        isAuthenticated: action.payload.isAuthenticated,
      };
    case 'SIGN_OUT':
      clearAuthData();
      return {
        ...state,
        user: null,
        isAuthenticated: false,
      };
    default:
      return state;
  }
};


const clearAuthData = () => {
  // console.log("Clearing auth data from localStorage");
  localStorage.removeItem('user');
  localStorage.removeItem('firebase_id_token');
  localStorage.removeItem('access_token');
  localStorage.removeItem('access_csrf');
  localStorage.removeItem('refresh_csrf');
  localStorage.removeItem('hideIntroModal');
};

const checkTokenExpiration = () => {
  const token = localStorage.getItem('firebase_id_token');
  // console.log('token', token);
  if (!token) return true;

  const decodedToken = jwtDecode(token);
  const currentTime = Date.now() / 1000;
  // console.log('decodedToken', decodedToken);
  // console.log('current time: ', currentTime);
  if (decodedToken.exp < currentTime) {
    // console.log('token expired');
    clearAuthData();
    return true;
  }
  return false;
};

export const AuthProvider = ({ children }) => {
  const [authState, dispatch] = useReducer(authReducer, initialState());

  const setAuthState = (user, isAuthenticated) => {
    // localStorage.setItem('user', user);
    // localStorage.setItem('firebase_id_token', user.token);
    dispatch({
      type: 'SIGN_IN',
      payload: { user, isAuthenticated },
    });
  };

  const signOut = async () => {
    // First signout out of firebase auth.
    //console.log("Attempting to sign out...");
    try {
      await auth.signOut();
      //console.log("Firebase sign out completed.");
      dispatch({
        type: 'SIGN_OUT'
      });
      // Clear all auth data.
      clearAuthData();
      //console.log("Local storage cleared and state updated.");
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };



  // Refresh firebase_id_token every 58 mins.
  useEffect(() => {
    const refreshInterval = setInterval(async () => {
        if (auth.currentUser) {
          //console.log("Refresh firebase_id_token every 58 mins...")
          //console.log("Current firebase_id_token is:", localStorage.getItem("firebase_id_token"));
          try {
            const refreshedToken = await auth.currentUser.getIdToken(true);
            //console.log("The new refreshed firebase token is:", refreshedToken);
            localStorage.setItem("firebase_id_token", refreshedToken);
            //console.log("Successfully saved the refreshed firebase_id_token", localStorage.getItem("firebase_id_token"));
          } catch (error) {
            console.error('Error refreshing Firebase ID token:', error);
          }
        }
    }, 3480000);

    // The regular check every 100s for jwt_token and firebase_id_token.
    const expirationInterval = setInterval(() => {
        if (checkTokenExpiration()) {
            signOut();
        }
    }, 100000); 

    return () => {
        clearInterval(refreshInterval);
        clearInterval(expirationInterval);
    };
  }, []);


  return (
    <AuthContext.Provider value={{ authState, setAuthState, signOut }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};