import React from "react";
import styled from "styled-components";
import { StandardButton } from "./ExternalUILibrary/ExternalComponents";
import logofinal from '../../../../images/carbonsmith_logo_white.png';
import logodark from '../../../../images/carbonsmith_logo_black.png';
import { useNavigate } from "react-router-dom";


const HeaderContainer = styled.header`
  top: 0;
  left: 0;
  width: 100%;
  padding: 0px 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  height: 10vh;
  
  @media (max-width: 750px) {
    justify-content: flex-start;
    padding: 0;
    padding-left: 5%;
  }
  ${props => props.absolute && 'position: absolute; z-index: 1000;'}
`;

const Logo = styled.div`
  background-image: url(${logofinal});
  background-size: contain;
  width: 100px;
  height: 100px;
  background-repeat: no-repeat;
  background-position: center;
  align-content: left;

  @media (max-width: 800px) {
    min-width: object-fit;
    height: 70px;
    width: 100px;
    background-size: 300%;
    background-size: contain;
    margin-top: 5%;
    margin-right: 5%;
    margin-left: 2%;
  }

  @media (min-width: 800px) {
    width: 120px;
    height: 90px;
    background-size: contain;
  }

  @media (min-width: 1000px) {
    width: 150px;
    height: 100px;
    background-size: contain;
  }

  @media (min-width: 1100px) {
    width: 200px;
    height: 100px;
    background-size: contain;
  }

  @media (min-width: 1400px) {
    width: 250px;
    height: 100px;
    background-size: contain;
  }
`;

const LogoDark = styled.div`
  background-image: url(${logodark});
  background-size: contain;
  width: 100px;
  height: 100px;
  background-repeat: no-repeat;
  background-position: center;
  align-content: left;

  @media (max-width: 800px) {
    width: 100px;
    height: 70px;
    margin-top: 5%;
    background-size: contain;
    margin-left: 2%;
  }

  @media (min-width: 800px) {
    width: 120px;
    height: 90px;
    background-size: contain;
  }

  @media (min-width: 1000px) {
    width: 150px;
    height: 100px;
    background-size: contain;
  }

  @media (min-width: 1100px) {
    width: 200px;
    height: 100px;
    background-size: contain;
  }

  @media (min-width: 1400px) {
    width: 250px;
    height: 100px;
    background-size: contain;
  }
`;

const Nav = styled.nav`
  display: flex;
  gap: 20px;
  position: relative;
  z-index: 1001;

  @media (max-width: 750px) {
    gap: 10px;
  }
`;

const NavLink = styled.a`
  text-decoration: none;
  color: white;
  font-size: 1.15rem;
  &:hover {
    text-decoration: none;
  }

  @media (max-width: 750px) {
    font-size: .75rem;
    margin-top: 8%;
  }
`;

const GetStartedButton = styled.div`
  background-color: white;
  color: #0A3C57;
  border-radius: 20px;
  font-weight: 400;
  font-size: 1.15rem;
  padding: 1px 12px; /* Adjust padding to fit the text */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer; /* Add cursor to indicate it's a button */
  &:hover {
    background-color: #f0f0f0; /* Optional: Add hover effect */
  }

  @media (max-width: 750px) {
    padding: 1px 3px;
    font-size: .75rem;
    margin-top: 8%;
  }
`;


const NewHeader = ({absolute = false}) => {
  const navigate = useNavigate();
  return (
    <HeaderContainer absolute={absolute}>
        <Logo />
        <Nav>
        <NavLink href="/">Home</NavLink>
        <NavLink href="/our-team">About</NavLink>
        <NavLink href="/blog">Blog</NavLink>
        <NavLink href="/login">Login</NavLink>
        <GetStartedButton onClick={() => (navigate('/'))}>
          <a href="#contact-form" style={{ textDecoration: 'none', color: '#0A3C57'}}>
          Get Started
          </a>
        </GetStartedButton>

        </Nav>
    </HeaderContainer>
  );
};

const NewHeaderLight = () => {
  const navigate = useNavigate();
  return (
    <HeaderContainer style={{ backgroundColor: 'white', paddingLeft: '5%'}}>
        <LogoDark style={{ marginRight: '5%'}}/>
        <Nav>
        <NavLink href="/" style={{ backgroundColor: 'white', color: '#0A3C57'}}>Home</NavLink>
        <NavLink href="/our-team" style={{ backgroundColor: 'white', color: '#0A3C57'}}>About</NavLink>
        <NavLink href="/blog" style={{ backgroundColor: 'white', color: '#0A3C57'}}>Blog</NavLink>
        <NavLink href="/login" style={{ backgroundColor: 'white', color: '#0A3C57'}}>Login</NavLink>
        <GetStartedButton style={{ backgroundColor: '#0A3C57', color: 'white'}} onClick={() => (navigate('/'))}>
          <a href="#contact-form" style={{ textDecoration: 'none', color: 'white'}}>
          Get Started
          </a>
        </GetStartedButton>

        </Nav>
    </HeaderContainer>
  );
}

export { NewHeader, NewHeaderLight };