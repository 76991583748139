import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import { useAuth } from "../../../UniversalFunctions/AuthProvider";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import ContractData from "./ContractData";
import LandingBodyTabs from "./LandingBodyTabs";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";

//This should be the body of the site once logged in - we need to call the database and check if there are any
//existing contracts under the user.
//if there are contracts, they should be shown with a button to add additional contract logging
// - additionally show contracts fulfilled, carbon processed
// - create tab on left-hand side for shortcuts to all of these
//if there are no contracts - show what an example contract would look like - then show same button as in previous case
// - provide all same features in example -> do so by creating an example contract in DB under a fake acc

const LandingBodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* background: linear-gradient(145deg, #fff, #DBE2E9); */
  justify-content: space-between;
  padding: 20px;
  @media (max-width: 768px) {
    flex-direction: column;
    max-width: 100vw;
  }
`;

const ContractsList = styled.div`
  // width: 70%;
  width: 75%;
  background-color: #fff;
  // border-radius: 5px;
  border-radius: 8px;
  // box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  // margin: 10px;
`;

const ContractItem = styled.div`
  margin: 10px 0;
  border: 1px solid #ddd;
  // padding: 10px;
  padding: 15px;
  background-color: #f9f9f9;
  // border-radius: 5px;
  border-radius: 8px;
  transition: transform 0.3s ease;
  cursor: pointer;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #f0f0f0;
  }
`;

const ExampleContract = styled.div`
  width: 100%;
`;

const ExampleContractItem = styled.div`
  margin: 20px 0;
  border: 1px solid #ddd;
  padding: 15px;
  background-color: #f9f9f9;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
`;

const ContractButton = styled.button`
  background-color: #00796b;
  color: #fff;
  border: none;
  // padding: 0px 10px;
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
  margin-top: 0px;
  transition: background-color 0.3s, transform 0.3s;

  &:hover {
    background-color: #4b9d4b;
    transform: scale(1.05);
  }
`;

const ButtonText = styled.p`
  margin: 0;
  font-weight: bold;
`;

const CircularBarDiv = styled.div`
  width: 100px;
  height: 100px;
  margin: 35px;
  margin-left: 29%;

  @media (min-width: 500px) {
    width: 120px;
    height: 120px;
    margin-left: 36%;
  }

  @media (min-width: 1000px) {
    width: 200px;
    height: 200px;
    margin-left: 39%;
  }

  @media (min-width: 1250px) {
    width: 250px;
    height: 250px;
    margin-left: 40%;
  }
`;

const ContractTitle = styled.h3`
  color: #00796b;
  font-size: 1.2em;
  margin-bottom: 0.5em;
`;

const ContractDetails = styled.p`
  color: #666;
  margin: 0.25em 0;
  font-size: 1em;
`;

const ContractHeader = styled.h2`
  color: #00796b;
  font-weight: bold;
`;

const CircularProgressBarStyles = buildStyles({
  rotation: 0.25,
  strokeLinecap: "butt",
  textSize: "16px",
  pathTransitionDuration: 0.5,
  pathColor: `#5cb85c`,
  textColor: `#5cb85c`,
  trailColor: `#ddd`,
  backgroundColor: "#f5f5f5",
});

function LoggedInLandingBody() {
  //const { authState } = useAuth(); //useAuth hook to access authstate
  //const { user } = authState; // access the user property
  const [contracts, setContracts] = useState([]);
  // const contracts = []
  const navigate = useNavigate();
  const requestParams = {};

  const contractEx = ContractData();
  // console.log(contractEx);
  contractEx["percentage"] =
    parseFloat(contractEx["percentage"]).toFixed(2) * 100;

  function handleCreateExampleContract() {
    navigate("/ContractBuilder");
    //do something
  }

  ///USEEFFECT SHOULD CALL NEW API ENDPOINT THAT RETURNS ALL DATA FROM CONTRACTS HELD BY USER - SHOULD NOT BE THAT HARD
  ///WILL NEED TO UPDATE CONTRACT TO INCLUDE A STATUS PARAMETER - NEED TO CONSIDER HOW WE WANT TO INITIALIZE
  ////     - CONTRACT SHOULD BE EITHER "NOT STARTED", "IN PROGRESS" OR "COMPLETED"
  useEffect(() => {
    const fetchContracts = async () => {
      const username = localStorage.getItem("user");
      const accessToken = localStorage.getItem("access_token");
      requestParams["username"] = username;
      const config = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };

      try {
        // console.log('config ', config);
        // console.log('request params: ', requestParams);
        const response = await axios.post(
          "https://carbonsmith-handling-69e454e97e3b.herokuapp.com/seller_contracts",
          requestParams,
          config
        );
        const fetchedContracts = response.data.data;
        console.log("data", fetchedContracts);

        setContracts(fetchedContracts);
      } catch (error) {
        console.error("Error fetching contracts: ", error);
      }
    };
    fetchContracts();
  }, []);

  return (
      <ContractsList>
        {contracts && Object.keys(contracts).length > 0 ? (
          <React.Fragment>
            <ContractHeader>Your Contracts</ContractHeader>
            {/* need to cut off length > 5 */}
            {Object.values(contracts).map((contract) => (
              <ContractItem
                key={contract.contractName}
                onClick={() => navigate(`/contract/${contract.contractName}`)}
              >
                <ContractTitle>{contract.contractName}</ContractTitle>
                <ContractDetails>Status: {contract.Status}</ContractDetails>
                <ContractDetails>Revenue: {contract.Revenue}</ContractDetails>
                <ContractDetails>
                  Tons: {contract.tonsToCapture}
                </ContractDetails>
                <ContractDetails>
                  Progress: {contract.carbonRemoved} tons
                </ContractDetails>
                <ContractDetails>
                  Price Per Ton: {contract.priceTonUSD}
                </ContractDetails>
                <ContractDetails>
                  Contract ID: {contract.contract_hedera}
                </ContractDetails>
              </ContractItem>
            ))}
            <ContractButton onClick={() => handleCreateExampleContract()}>
              <ButtonText>Add Additional Contract</ButtonText>
            </ContractButton>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <ExampleContract>
              <h2>No Contracts Yet</h2>
              <p>Here's an example contract:</p>
              <ExampleContractItem>
                <p style={{ fontWeight: "bold" }}>Contract ID: 12345</p>
                <p style={{ fontWeight: "bold" }}>Tons to capture: 10</p>
                <p style={{ fontWeight: "bold" }}>Tons Captured: 6.66</p>
                <p style={{ fontWeight: "bold" }}>Status: In Progress</p>
                <CircularBarDiv>
                  <CircularProgressbar
                    value={contractEx["percentage"]}
                    text={`${contractEx["percentage"]}%`}
                    styles={CircularProgressBarStyles}
                  />
                </CircularBarDiv>
                {/* can refactor to import from univ func */}
                <ContractButton onClick={handleCreateExampleContract}>
                  <Link
                    style={{ textDecoration: "none", color: "white" }}
                    to="ContractBuilder"
                  >
                    <ButtonText>Build New Contract</ButtonText>
                  </Link>
                </ContractButton>
              </ExampleContractItem>
            </ExampleContract>
          </React.Fragment>
        )}
      </ContractsList>
  );
}

export default LoggedInLandingBody;
