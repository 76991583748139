import React, { useEffect, useState } from "react";
import axios from "axios";
import styled from "styled-components";
import LandingBodyTabs from "./LandingBodyTabs";
import { motion } from 'framer-motion';
import { MdLocationOn, MdOutlineCreditScore, MdOutlineExpandMore, MdOutlineSlideshow } from 'react-icons/md';
import { FaProjectDiagram } from 'react-icons/fa';
import { useNavigate } from "react-router-dom";
import { local } from "d3";
import FileUpload from "../../../UniversalFunctions/FileUpload";
import { addedAt, modifiedAt } from "../../../UniversalFunctions/timestamps";
import { Card } from "../../../UI/Library/SellerComponents";
import StripeConnectModal from "../../../UniversalFunctions/Stripe/StripeConnectHomeSeller";
import { Titleh3 } from "../ExternalSite/ExternalUILibrary/ExternalComponents";
import StripeService from "../../../UniversalFunctions/Stripe/StripeService";

const SectionalMotion = {
    hidden: {
      boxShadow: "0 0 0px rgba(0,0,0,0)",
      opacity: 0,
      scale: 0.95
    },
    visible: {
      boxShadow: "0 0 8px 2px rgba(50,93,121,0.5)", //simulated border appearance
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.8,
        ease: "easeOut",
        boxShadow: { duration: 1.5 } // slower transition for boxshadow
      }
    }
  }

const instructionalVariants = {
    hidden: { 
      opacity: 0, 
      scale: 0.95, 
      y: 30 
    },
    visible: {
      opacity: 1, 
      scale: 1, 
      y: 0,
      transition: { 
        duration: 0.8, 
        ease: "easeOut" 
      }
    }
  };

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  // height: 100%;
  width: 100%;
  // background: linear-gradient(145deg, #fff, #DBE2E9);
  @media (max-width: 768px) {
    padding-top: 10vh;
  }
`;

const MainContainer = styled.div`
  // flex: 1;
  // background-color: #f5f5f5;
  width: 100%;
  display: flex;
  flex-direction: row;
  // background-color: #f5f5f5;
  justify-content: space-between;
  padding: 2%;
`;

const BodyDiv = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  // align-items: center;
  width: calc(70% - 20px);
`;

const InstructionalSection = styled(motion.div)`
  flex: 1;
  padding: 2%;
  background: linear-gradient(145deg, #F0F9FF, #CBE0E3);
  color: #325D79;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 20px;
  margin: 2%;
  border: 1px solid transparent;
  transition: border 2s ease-in-out;

  &:hover {
    transform: scale(1.01);
    border: 1px solid #325D79; /* Visible border on hover */
  }
`;

const GuideStep = styled(motion.div)`
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: start;
`;

const StepTitle = styled.h3`
  color: #325d79;
  margin-bottom: 1%;
  font-size: 1.4rem;
`;

const StepDescription = styled.p`
  color: #325d79;
  text-align: left;
  line-height: 1.6;
  font-size: 1rem;
`;

const FormContainer = styled(motion.div)`
  padding: 20px;
  background: #fff;
  box-shadow: 0 2px 15px rgba(50, 50, 93, 0.1);
  border-radius: 12px;
  margin-top: 30px;
  transition: box-shadow 0.3s ease-in-out;
  border: 1px solid transparent;
  &:hover {
    box-shadow: 0 5px 30px rgba(50, 50, 93, 0.2);
  }
`;

const FormField = styled.div`
  position: relative;
  margin-bottom: 30px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 10px;
  color: #325D79;
  font-weight: bold;
  font-size: 16px;
`;

const Input = styled.input`
  width: 100%;
  padding: 12px;
  padding-left: 40px;
  border: 2px solid #CBE0E3;
  border-radius: 8px;
  font-size: 16px;
  transition: border-color 0.3s;
  &:focus {
    border-color: #325D79;
  }
`;

const Select = styled.select`
  width: 100%;
  padding: 12px;
  padding-left: 40px;
  appearance: none;
  background-repeat: no-repeat;
  background-position: right 10px center;
  border: 2px solid #CBE0E3;
  border-radius: 8px;
  font-size: 16px;
  transition: border-color 0.3s;
  &:focus {
    border-color: #325D79;
  }
`;

const Icon = styled.span`
  position: absolute;
  top: 38px;
  left: 12px;
  svg {
    fill: #325D79;
    height: 20px;
    width: 20px;
  }
`;

const UploadButton = styled.button`
    margin-top: 2%;
    background-color: #5f7d95ff;
    color: white;
    padding: 8px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
    font-size: .8em;

    &:hover {
        // background-color: #004c3f;
        transform: scale(1.02);
    }
`;

const SubLabel = styled.p`
  font-style: italic;
  color: #5f7d95;
  margin-bottom: 10px;
  font-size: 14px;
`;


const guideSteps = [
    { title: "Add Your Project", description: "Submit your project for review by our internal team. You must upload any existing documents related to your LCA for the project, or any external certification you have received for credits before submitting." },
    { title: "Outside Certification", description: "Enter documents detailing any outside certifications you have received for credit issuance. If you do not have documentation, the review and certification process may take longer." },
    { title: "Specify Timeframes and Ownership", description: 'Detail all owners and provisioners of the working site, additionally include whether your project has been built and ready to deliver credits, or whether it is awaiting funding for milestone based deals.' },
    { title: "Await Review", description: "Once your project has been reviewed and certified, you can begin initiating contracts for buyers. If you need assistance in the meantime, please visit the help tab." },
  ];


function AddProjectForm() {
  const [isCertified, setIsCertified] = useState("no");
  const [projectName, setProjectName] = useState('');
  const [buildStatus, setBuildStatus] = useState('Complete');
  const [projectLocation, setProjectLocation] = useState('');
  const [creditsPerYear, setCreditsPerYear] = useState('');
  const [creditsUsed, setCreditsUsed] = useState('');
  const [loading, setLoading] = useState(false);
  const [company, setCompany] = useState('');
  const [message, setMessage] = useState(null);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [formSubmitEnabled, setFormSubmitEnabled] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [showStripeModal, setShowStripeModal] = useState(false);
  const [stripeAccountComplete, setStripeAccountComplete] = useState(false);
  const navigate = useNavigate();
  const stripeService = new StripeService();

  const handleUploadSuccess = (success) => {
    setUploadSuccess(success);
    setFormSubmitEnabled(success && selectedFiles.length > 0);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const accessToken = localStorage.getItem('access_token');
    const firebaseIdToken = localStorage.getItem('firebase_id_token');
    const currentTime = new Date().toISOString().replace('T', ' ').replace('Z', '');
    const config = {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'X-Firebase-ID-Token': firebaseIdToken
      }
    };
    const remainingCredits = isCertified === 'yes' ? creditsPerYear - creditsUsed : null;
    const submissionObject = {
      'company': company,
      'project_name': projectName,
      'build_status': buildStatus,
      'project_location': projectLocation,
      'certified': isCertified,
      'credits_per_year': isCertified === 'yes' ? creditsPerYear : null,
      'credits_used': isCertified === 'yes' ? creditsUsed : null,
      'remaining_credits': remainingCredits,
      'time_initiated': currentTime,
      'addedAt': addedAt(),
      'modifiedAt': modifiedAt()
    };

    try {
      const response = await axios.post('https://carbonsmith-handling-69e454e97e3b.herokuapp.com/project_cert_submission', submissionObject, config);
      if (response.status === 200) {
        setMessage("Project added successfully.");
        const formData = new FormData();
        selectedFiles.forEach(file => {
          formData.append('files', file);
        });
        formData.append('company', company);
        formData.append('project_name', projectName);
        const config2 = {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'X-Firebase-ID-Token': firebaseIdToken
          }
        };
        try {
          const res2 = await axios.post('https://carbonsmith-handling-69e454e97e3b.herokuapp.com/project/file_submission', formData, config2);
          if (res2.status === 200) {
            alert('Successfully uploaded documents');
            //check if stripe account is complete before showing modal
            if (!stripeAccountComplete) {
              setShowStripeModal(true);
            } else {
              navigate('/Overview');
            }
          }
        } catch (error) {
          console.error('Error:', error.message || 'Unknown error');
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        if (error.response.data && error.response.data.error && error.response.data.error.includes('project name')) {
          alert('This project name already exists. Please try another name.');
        } else {
          alert('Error: ' + error.response.data.message);
        }
      } else {
        console.error('Error:', error.message || 'Unknown error');
        alert('Something went wrong! Please try again.');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleModalClose = () => {
    setShowStripeModal(false);
    navigate('/View_Projects');  // Navigate to Overview page when modal is closed
  };


  const checkStripeAccountStatus = async () => {
    try {
      const { stripe_connected, charges_enabled, payouts_enabled } = 
        await stripeService.checkAccountStatus(company);

      if (stripe_connected && charges_enabled && payouts_enabled) {
        setStripeAccountComplete(true);
      } else {
        setStripeAccountComplete(false);
      }
    } catch (error) {
      console.error('Error checking Stripe account status:', error);
      setStripeAccountComplete(false);
    }
  };



  useEffect(() => {
    setFormSubmitEnabled(uploadSuccess && selectedFiles.length > 0);
  }, [uploadSuccess, selectedFiles]);

  useEffect(() => {
    const getCompany = async () => {
      const user = localStorage.getItem('user');
      const accessToken = localStorage.getItem('access_token');
      const submissionOb = { username: user };
      const config = {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      };
      try {
        const res = await axios.post('https://carbonsmith-handling-69e454e97e3b.herokuapp.com/get_user_company', submissionOb, config);
        const fetchedCompany = res.data.Company;
        setCompany(fetchedCompany);
        if (fetchedCompany) {
          checkStripeAccountStatus(fetchedCompany);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getCompany();
  }, []);

  return (
    <Card
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <form onSubmit={handleSubmit}>
        <FormField>
          <Label htmlFor="projectName">Project Name</Label>
          <Icon><FaProjectDiagram /></Icon>
          <Input id="projectName" type="text" placeholder="Enter project name" value={projectName} onChange={e => setProjectName(e.target.value)} />
        </FormField>

        <FormField>
          <Label htmlFor="buildStatus">Project/Facility Status</Label>
          <Icon><MdOutlineSlideshow /></Icon>
          <Select id="buildStatus" value={buildStatus} onChange={e => setBuildStatus(e.target.value)}>
            <option>Complete</option>
            <option>In Progress</option>
            <option>Awaiting Funding</option>
          </Select>
        </FormField>

        <FormField>
          <Label htmlFor="projectLocation">Project Location</Label>
          <Icon><MdLocationOn /></Icon>
          <Input id="projectLocation" type="text" placeholder="Enter location" value={projectLocation} onChange={e => setProjectLocation(e.target.value)} />
        </FormField>

        <FormField>
          <Label htmlFor="isCertified">Already Certified for Credit Issuance</Label>
          <Icon><MdOutlineExpandMore /></Icon>
          <Select id="isCertified" value={isCertified} onChange={e => setIsCertified(e.target.value)}>
            <option value="no">No</option>
            <option value="yes">Yes</option>
          </Select>
        </FormField>

        {isCertified === "yes" && (
          <>
            <FormField>
              <Label htmlFor="creditsPerYear">Credits Issued (Yearly)</Label>
              <Icon><MdOutlineCreditScore /></Icon>
              <Input id="creditsPerYear" type="number" placeholder="Enter number of credits" value={creditsPerYear} onChange={e => setCreditsPerYear(e.target.value)} />
            </FormField>

            <FormField>
              <Label htmlFor="creditsUsed">Credits Retired/Sold (Current year)</Label>
              <Icon><MdOutlineCreditScore /></Icon>
              <Input id="creditsUsed" type="number" placeholder="Enter credits used" value={creditsUsed} onChange={e => setCreditsUsed(e.target.value)} />
            </FormField>
          </>
        )}
        {/* <Label>Add any necessary files</Label>
        <FileUpload onUploadSuccess={handleUploadSuccess} onFileSelect={setSelectedFiles} /> */}
        <FormField style={{marginBottom: '1%'}}>
          <Label>Upload Files</Label>
          <SubLabel>Project proposals, LCA, credit issuance certification and other relevant documentation</SubLabel>
          <FileUpload onUploadSuccess={handleUploadSuccess} onFileSelect={setSelectedFiles} />
        </FormField>
        <UploadButton type="submit" disabled={!formSubmitEnabled || loading}>
          {loading ? "Submitting..." : "Submit"}
        </UploadButton>
      </form>
      <StripeConnectModal
        isOpen={showStripeModal}
        onClose={handleModalClose}
        company={company}
        onAccountCreated={checkStripeAccountStatus}
      />
    </Card>
  );
}

function AddProjectPage() {
  return (
      <MainContainer>
        <BodyDiv>
          <InstructionalSection>
            {guideSteps.map((step, index) => (
              <GuideStep key={index}>
                <StepTitle>{step.title}</StepTitle>
                <StepDescription>{step.description}</StepDescription>
              </GuideStep>
            ))}
          </InstructionalSection>
          <AddProjectForm />
        </BodyDiv>
      </MainContainer>
  );
}

export default AddProjectPage;