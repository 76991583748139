// StripeService.js
import axios from 'axios';
import { CONFIG } from './stripeConfig';

class StripeService {
  constructor() {
    this.baseURL = CONFIG.API_URL;
  }

  async createAccount(company) {
    try {
        // console.log(company);
      const response = await axios.post(`${this.baseURL}/account`, { company });
    //   console.log(response);
      return response.data;
    } catch (error) {
      console.error('Error creating Stripe account:', error);
      throw error;
    }
  }

  async checkAccountStatus(company) {
    try {
      const response = await axios.post(`${this.baseURL}/is_stripe_account_complete`, { company });
    //   console.log(response);
      return response.data;
    } catch (error) {
      console.error('Error checking Stripe account status:', error);
      throw error;
    }
  }
}

export default StripeService;