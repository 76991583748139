import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import LandingBodyTabsBuyer from './LandingBodyTabsBuyer';
import { useNavigate, useParams } from 'react-router-dom';
import AvailableCreditsFooter from './UniversalFcnsBuyerFE/AvailableCreditsScroll';
import { format2Decimals, formatNumberDollars } from '../../../UniversalFunctions/Numeric/Format2Decimal';

const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background: linear-gradient(145deg, #fff, #DBE2E9);
    @media  (max-width: 768px) {
    max-width: 100vw;
    margin-top: 10vh;
    }
`;

const MainContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 20px;
    // background: #f5f5f5;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const BodyDiv = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;
    @media (max-width: 768px) {
    height: 100vh;
    }
`;

const UpperDivMain = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1.5%;
`;

const LeftUpperDivMain = styled.div`
  flex: 1;
  margin-right: 20px; // Adds some space between the left and right sections
  padding: 1.5%;
  background-color: #f8f8f8; // Light background for contrast
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid #325d7980;
`;

const MarketTitle = styled.h2`
  color: #325d79ff;
  margin-bottom: 4.5%;
  // background-color: #f5f5f5;
`;

const MarketDescLine = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 1%;
`;

const MarketDescTitle = styled.p`
    font-weight: bold;
    color: #5f7d95ff;
    margin-top: 0;
    margin-bottom: 0;
`;

const MarketDesc = styled.p`
color: #5f7d95ff;
margin-left: 2%;
margin-top: 0;
margin-bottom: 0;
`;

const RightUpperDivMain = styled.div`
  flex: 1;
  padding: 20px;
  background-color: #f8f8f8;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid #325d7980;
`;

const LowerDivMain = styled.div`
  margin-top: 20px;
  padding: 20px;
  background-color: #fff;
  overflow-y: auto; // Allows for scrolling
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* max-height: 400px; // Adjust as needed */
  border: 1px solid #325d7980;

`;

const ContractTitle = styled.h3`
    font-weight: bold;
    color: #325d79ff;
`;

const ProgressContainer = styled.div`
  width: 40%;
  background-color: #e0e0e0;
  border-radius: 5px;
  margin: 2% 0;
`;

const ProgressBar = styled.div`
  height: 10px;
  width: ${({ progress }) => progress}%;
  background-color: #4CAF50;
  border-radius: 5px;
  transition: width 0.5s ease-in-out;
`;

const StatusIndicator = styled.div`
  display: inline-block;
  background-color: #f0f0f0;
  color: green;
  padding: 2%;
  border-radius: 5px;
  font-size: 1em;
  font-weight: bold;
  margin-left: 2%; // Adjust spacing as needed
`;

const StatusIndicatorCompleted = styled.div`
  display: inline-block;
  background-color: #f0f0f0;
  color: red;
  padding: 2%;
  border-radius: 5px;
  font-size: 1em;
  font-weight: bold;
  margin-left: 2%; // Adjust spacing as needed
`;


//this page is meant to laod an individual market contract after a user clicks on it - and helps them purchase / checkout the contract
function MarketIndivPage() {
    const navigate = useNavigate();
    const { company, contract_name } = useParams();
    const accessToken = localStorage.getItem('access_token');
    const requestParams = {};
    const [market, setMarket] = useState({});
    const progress = (market.carbonRemoved / market.tonsToCapture) * 100;

    useEffect(() => {
        const fetchMarket = async() => {
            requestParams['company'] = company;
            requestParams['contractName'] = contract_name;
            // console.log('reqparams ', requestParams);
            const config = {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            }
            try {
                const res = await axios.post('https://carbonsmith-handling-69e454e97e3b.herokuapp.com/get_indiv_contract_open', requestParams, config);
                // console.log('data', res.data.data);
                const fetchedMarket = res.data.data;
                console.log(fetchedMarket);
                fetchedMarket['company'] = company;
                fetchedMarket['contractName'] = contract_name;
                setMarket(fetchedMarket);
                
            } catch (error) {
                console.log('An error occurred: ', error);
            }
        }
        fetchMarket();

    }, []);
    

    return (
            <MainContainer>
                <BodyDiv>
                    <UpperDivMain>
                        <LeftUpperDivMain>
                            <MarketTitle onClick={() => (navigate(`/supplier/${market.company}`))}>{market.company}</MarketTitle>
                            <ContractTitle>{market.contractName}</ContractTitle>
                            {market.tonsToCapture && (<MarketDescLine><MarketDescTitle>Tons:</MarketDescTitle><MarketDesc>{format2Decimals(market.tonsToCapture)}</MarketDesc></MarketDescLine>)}
                            {market.Tons && (<MarketDescLine><MarketDescTitle>Tons:</MarketDescTitle><MarketDesc>{format2Decimals(market.Tons)}</MarketDesc></MarketDescLine>)}
                            <MarketDescLine><MarketDescTitle>Price:</MarketDescTitle><MarketDesc>${formatNumberDollars(market.PriceTonUSD)}/ton</MarketDesc></MarketDescLine>
                            <MarketDescLine><MarketDescTitle>Storage:</MarketDescTitle><MarketDesc>{market.Storage}</MarketDesc></MarketDescLine>
                            <MarketDescLine><MarketDescTitle>Year:</MarketDescTitle><MarketDesc>{market.Year}</MarketDesc></MarketDescLine>
                            <MarketDescLine><MarketDescTitle>Location:</MarketDescTitle><MarketDesc>{market.Location}</MarketDesc></MarketDescLine>

                        </LeftUpperDivMain>
                        <RightUpperDivMain>

                        </RightUpperDivMain>
            
                    </UpperDivMain>
                    <LowerDivMain>
                        <AvailableCreditsFooter />

                    </LowerDivMain>
                </BodyDiv>

            </MainContainer>
    )
}

export default MarketIndivPage;