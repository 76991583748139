import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import CarbonCreditContractsGraph from './CoreProductFrontEndSeller/GraphSourcing/TestingGraphs';
import axios from 'axios';
import ContractsRadarChart from './CoreProductFrontEndSeller/GraphSourcing/ContractRadar';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background: linear-gradient(145deg, #fff, #DBE2E9);
`;


function TestingPage() {

  const [contracts, setContracts] = useState([]);
  const [averageMarketPrice, setAverageMarketPrice] = useState(0);
  const requestParams = {};

  useEffect(() => {
    const testingContracts = async () => {
      const user = localStorage.getItem('user');
      requestParams['username'] = user;
      const accessToken = localStorage.getItem('access_token');
      const config = {
          headers: {
              'Authorization': `Bearer ${accessToken}`
          },
      };  
      try {
        const res = await axios.post('https://carbonsmith-handling-69e454e97e3b.herokuapp.com/buyer_contracts_all', requestParams, config);
        const fetchedContracts = res.data.data;
        // console.log('fetched: ', fetchedContracts);
        // setContracts(fetchedContracts);

        if (fetchedContracts && Object.keys(fetchedContracts).length > 0) {
          setContracts(fetchedContracts);

          // Calculate average market price
          const prices = fetchedContracts.map(contract => parseFloat(contract.priceTonUSD));
          const averagePrice = prices.reduce((acc, price) => acc + price, 0) / prices.length;
          console.log('rice: ', averagePrice);
          setAverageMarketPrice(averagePrice);
        } else {
          console.log('No contracts found or fetchedContracts is not an array');
          setContracts([]);
          setAverageMarketPrice(0);
        }

        // Calculate average market price
        // const prices = Object.values(fetchedContracts).map(contract => parseFloat(contract.priceTonUSD));
        // const averagePrice = prices.reduce((acc, price) => acc + price, 0) / prices.length;
        // setAverageMarketPrice(averagePrice);

      } catch(error) {
        console.log('an error occurred: ', error);
      }
    
    }
  testingContracts();
  },[]);

  return (
    <>
    <div>
      <CarbonCreditContractsGraph contracts={contracts} />
    </div>
    {/* <div>
      <ContractsRadarChart contracts={contracts} averageMarketPrice={averageMarketPrice} />
    </div> */}
    </>

  );
}

export default TestingPage;
