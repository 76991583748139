import React, { useState, useEffect} from 'react';
import styled from 'styled-components';
import LandingBodyTabs from './LandingBodyTabs';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { min } from 'd3';
import { addedAt, modifiedAt } from '../../../UniversalFunctions/timestamps';
import { PageContainer, BodyDiv, UpperDivMain, MainContainer } from '../../../UI/Library/PageStructure';
import { Card, CardContent, CardDescription, CardHeader, CardTitle, ContractDetails, ContractStatus, ContractId, ContractItem, HeaderContainer, HeaderTitle, HeaderDescription } from '../../../UI/Library/SellerComponents';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { FaCalendar } from 'react-icons/fa';
import moment from 'moment-timezone';


const TargetsHeaderStatus = styled.div`
color: #325d79ff;
font-weight: bold;
margin-top: 40%;
`;


const AddTargetsDiv = styled.div`
    margin: 5% auto;

    &:hover {
        // background-color: #c5c6D0;
        transform: scale(1.02);
        transition: all 0.3s ease-in-out;        
    }
`;

const AddTargetsButton = styled.div`
  background-color: #325d79ff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 1.2em;
  cursor: pointer;
  margin-top: 4%;
  width: 100%;
  font-weight: bold;

  &:hover {
    background-color: #5f7d95ff;
    transform: scale(1.02);
}
`;

const ModalBackdrop = styled.div`
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: rgba(0, 0, 0, 0.6);
display: flex;
justify-content: center;
align-items: center;
z-index: 1000;
`;

const StyledButton = styled.button`
  padding: 2% 3%;
  border-radius: 5px;
  border: none;
  background-color: #325d79ff;
  color: white;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #5f7d95ff;
    transform: scale(1.03);
  }
`;


const TargetDesc = styled.p`
    text-align: left;
    font-size: .9em;
    font-weight: bold;
    margin: 2% 0;
    // font-family: dm sans;
`;

const ProgressContainer = styled.div`
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 5px;
  margin: 2% 0;
`;

const ProgressBar = styled.div`
  height: 10px;
  width: ${({ progress }) => progress}%;
  background-color: #4CAF50;
  border-radius: 5px;
  transition: width 0.5s ease-in-out;
`;

const StatusIndicator = styled.div`
  display: inline-block;
  background-color: #f0f0f0;
  color: green;
  padding: 2%;
  border-radius: 5px;
  font-size: 1em;
  font-weight: bold;
  margin-left: 2%; // Adjust spacing as needed
`;

const StatusIndicatorCompleted = styled.div`
  display: inline-block;
  background-color: #f0f0f0;
  color: red;
  padding: 2%;
  border-radius: 5px;
  font-size: 1em;
  font-weight: bold;
  margin-left: 2%; // Adjust spacing as needed
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 500px;
`;

const FormTitle = styled.h2`
  text-align: center;
  color: #325d79;
  margin-bottom: 24px;
  font-size: 1.5em;
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const FormSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const FormLabel = styled.label`
  font-weight: 600;
  color: #325d79;
  margin-bottom: 8px;
`;

const StyledInput = styled.input`
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1em;
  transition: border-color 0.3s;

  &:focus {
    outline: none;
    border-color: #325d79;
  }
`;

const StyledSelect = styled.select`
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1em;
  background-color: white;
  transition: border-color 0.3s;

  &:focus {
    outline: none;
    border-color: #325d79;
  }
`;

const DateInputGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const DateInput = styled.div`
  flex: 1;
  position: relative;
`;

const StyledDateInput = styled.input`
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1em;
  transition: border-color 0.3s;

  &:focus {
    outline: none;
    border-color: #325d79;
  }
`;

const DateLabel = styled.span`
  position: absolute;
  top: -20px;
  left: 0;
  font-size: 0.85em;
  color: #666;
`;

const DateSeparator = styled.span`
  color: #325d79;
  font-weight: 600;
`;

const SubmitButton = styled.button`
  width: 100%;
  padding: 12px;
  background-color: #325d79;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1.1em;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;

  &:hover {
    background-color: #1a4971;
    transform: translateY(-2px);
  }

  &:active {
    transform: translateY(0);
  }
`;

// The current date shown in the frontend for users is always their devices' local time.
const getCurrentDate = () => {
    return moment().tz(moment.tz.guess()).format('YYYY-MM-DD');
};


function Modal({ isOpen, onClose, children}) {


    if (!isOpen) return null;

    return (
        <ModalBackdrop onClick={onClose}>
            <ModalContent onClick={e => e.stopPropagation()}>
                {children}
            </ModalContent>
        </ModalBackdrop>
    );
}

function TargetItemComponent({ target, navigate}) {

    return (
        <Card style={{ margin: '1% 1%', marginLeft: '3%'}} onClick={() => navigate(`/target/${target.targetName}`)}>
            <CardHeader>
                <CardTitle>{target.targetName}</CardTitle>
            </CardHeader>
            <CardContent>
                <ContractItem>
                    <ContractDetails>
                        {Object.entries(target['targetType']).map(([key, value]) => (
                            key === 'CO2 Removed' ? (
                                <ContractId>{key}: {value} Tons</ContractId>
                            ) : (
                                <ContractId>{key}: {value}</ContractId>
                            )
                        ))}
                    {target.progress >= 0 && target.progress !== null && (
                        <div style={{ marginTop: '10%'}}>
                            <TargetDesc>{(target.progress).toFixed(2)}% Complete</TargetDesc>
                            <ProgressContainer>
                                <ProgressBar progress={target.progress} />
                            </ProgressContainer>
                        </div>
                    )}
                    </ContractDetails>
                    {target.Status === 'In Progress' && (
                        <ContractStatus style={{ backgroundColor: '#b2e0b2', color: 'white'}}>Active</ContractStatus>
                    )}
                    {target.Status === 'Uninitialized' && (
                        <ContractStatus>Uninitialized</ContractStatus>
                    )}
                </ContractItem>
            </CardContent>
        </Card>
    )
}

function RemovalTargets() {

    const [targets, setTargets] = useState({});
    const requestParams = {};
    const [isModalOpen, setModalOpen] = useState(false);
    const navigate = useNavigate();

    const [targetName, setTargetName] = useState('');
    //const [targetLength, setTargetLength] = useState('1 year'); //defaults - update when other options selected
    const [targetType, setTargetType] = useState('CO2 Removed');
    const [targetValue, setTargetValue] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [contractsAll, setContractsAll] = useState([]);
    const [CO2RemovedStatus, setCO2RemovedStatus] = useState(0);
    const [contractCompleteStatus, setContractCompleteStatus] = useState(0);
    const [emissionsReducedStatus, setEmissionsReducedStatus] = useState(0);
    const [emissionReducedPercentStatus, setEmissionsReducedPercentStatus] = useState(0);
    const [partnershipsCreatedStatus, setPartnershipsCreatedStatus] = useState(0);
    const [trigger1, setTrigger1] = useState(false);
    const [trigger2, setTrigger2] = useState(false);


    function handleTargetNameChange(event) {
        setTargetName(event.target.value);
    }
    
    // function handleTargetLengthChange(event) {
    //     setTargetLength(event.target.value);
    // }
    
    function handleTargetTypeChange(event) {
        setTargetType(event.target.value);
    }
    
    function handleTargetValueChange(event) {
        setTargetValue(event.target.value);
    }
    
    function handleStartDateChange(event) {
        setStartDate(event.target.value);
    }
    
    function handleEndDateChange(event) {
        setEndDate(event.target.value);
    }

    async function handleSubmit(event) {
        event.preventDefault(); //prevents the default form submission behavior
        const username = localStorage.getItem('user');
        const accessToken = localStorage.getItem('access_token');

        const config = {
            headers: {
              'Authorization': `Bearer ${accessToken}`
            }
          }
    
        const formData = {
            username,
            targetName,
            // targetLength,
            targetType,
            targetValue,
            // The timestamps to be stored in firebase database are always UTC. Since startDate and endDate in the frontend are shown as local time, so convert them.
            startDate: moment.tz(startDate, moment.tz.guess()).utc().format(),
            endDate: moment.tz(endDate, moment.tz.guess()).utc().format(),
            // ISO format = UTC format.
            addedAt: new Date().toISOString(),
            modifiedAt: new Date().toISOString()
        };
    
        try {
            // console.log('form data: ', formData);

            const res = await axios.post('https://carbonsmith-handling-69e454e97e3b.herokuapp.com/add_seller_targets', formData, config);

            //const res = await axios.post('http://127.0.0.1:5000/add_seller_targets', formData, config);
            // console.log(res);

            if (res.status === 200) {
                alert('Target successfully added!');
                setModalOpen(false); // Close the modal after successful submission
                window.location.reload(); // Refresh the page or consider a less disruptive way to update the UI
            }
        } catch (error) {
            if (error.response) {
                // Check for known error type from backend and display corresponding message
                if (error.response.status === 400 && error.response.data.error) {
                    alert(`Error: ${error.response.data.error}`);
                } else {
                    alert(`Error: ${error.response.statusText || "Unknown error occurred"}`);
                }
            } else {
                console.log('There was an error: ', error.message || 'Unknown error');
                alert('An unexpected error occurred, please try again.');
            }
        }
    }

    useEffect(() => {
        const fetchTargets = async() => {
            const username = localStorage.getItem('user');
            const accessToken = localStorage.getItem('access_token');
            const firebaseIdToken = localStorage.getItem('firebase_id_token');
    
            if (!accessToken || !firebaseIdToken) {
                console.error('Tokens are missing');
                return;
            }

            const requestParams = { username: username };
            const config = {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Firebase-Token': firebaseIdToken
                }
            }

            try {
                const res = await axios.post('https://carbonsmith-handling-69e454e97e3b.herokuapp.com/seller_targets', requestParams, config);
                const fetchedTargets = res.data.targets;
                // console.log('fetched targets: ', fetchedTargets);
                if (fetchedTargets.length !== 0) {
                    // console.log('didddd it')
                    setTargets(fetchedTargets);
                    setTrigger1(true);
                    // if (targets.length > 0) setTrigger1(true);
                }

            } catch (error) {
                console.log('Error ', error);
            }
        }

        fetchTargets();

    },[])

    useEffect(() => {

        if (targets.length !== 0 && trigger1) {
            const fetchContracts = async() => {
                const username = localStorage.getItem('user');
                const accessToken = localStorage.getItem('access_token');
                const firebaseIdToken = localStorage.getItem('firebase_id_token');
    
                if (!accessToken || !firebaseIdToken) {
                    console.error('Tokens are missing');
                    return;
                }
    
                const requestParams = { username: username };
                const config = {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Firebase-Token': firebaseIdToken
                    }
                }
    
                try {
                    const res = await axios.post('https://carbonsmith-handling-69e454e97e3b.herokuapp.com/buyer_contracts_all', requestParams,config);
                    const fetchedContracts = res.data.data;
                    setContractsAll(fetchedContracts);
                    setTrigger2(true);
                    // if (contractsAll.length > 0) setTrigger2(true);
                } catch (error) {
                    console.log('An error occurred: ', error);
                }
            }
            fetchContracts();
        }



    },[trigger1]);


    useEffect(() => {
        if (trigger2 && targets && Object.keys(targets).length !== 0) {
            setTargets(prevTargets => {
                const updatedTargets = { ...prevTargets };
    
                Object.entries(updatedTargets).forEach(([key, target]) => {
                    const targetType = Object.keys(target.targetType)[0];
                    const targetValue = target.targetType[targetType];
                    let totalValue = 0;
    
                    // Check if contractsAll exists and has items
                    if (contractsAll && Object.keys(contractsAll).length > 0) {
                        const contractsArray = Object.values(contractsAll);
    
                        switch (targetType) {
                            case 'CO2 Removed':
                                totalValue = contractsArray.reduce((acc, contract) => acc + (contract.carbonRemoved || 0), 0);
                                break;
                            case 'Contracts Completed':
                                totalValue = contractsArray.filter(contract => contract.Status === 'Completed').length;
                                break;
                            case 'Partnerships Created':
                                const uniqueContracts = new Set(contractsArray.map(contract => contract.company));
                                totalValue = uniqueContracts.size;
                                break;
                            default:
                                break;
                        }
                    }
    
                    const progress = targetValue > 0 ? Math.min(100, (totalValue / targetValue) * 100) : 0;
    
                    updatedTargets[key] = {
                        ...target,
                        progress,
                        Status: progress >= 100 ? 'Completed' : target.Status,
                    };
                });
    
                return updatedTargets;
            });
        }
    }, [trigger2]); 
    
    return (
            <MainContainer>
                <BodyDiv>
                    <HeaderContainer>
                        <HeaderTitle>
                        Company Targets
                        </HeaderTitle>

                        <HeaderDescription>
                        {Object.values(targets).some(target => target.Status === 'In Progress') && 
                        <div style={{ display: 'flex', flexDirection: 'row'}}>
                                <span style={{ height: '12px', width: '12px', backgroundColor: 'green', borderRadius: '50%', display: 'inline-block', marginRight: '7px', marginTop: '45%' }}></span>
                                <TargetsHeaderStatus>
                                    Active
                                </TargetsHeaderStatus>
                                </div>
                                }
                        </HeaderDescription>
                    </HeaderContainer>
                    {targets.length === 0 ? (
                        <div>
                            <h2>No targets set yet</h2>
                        </div>
                    ) : (
                        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                            {Object.entries(targets).map(([key, value]) => (
                                <TargetItemComponent target={value} navigate={navigate} />
                        ))}
                        </div>
                )}
                    <AddTargetsDiv>
                        <AddTargetsButton onClick={() => setModalOpen(true)}>Add Targets</AddTargetsButton>
                    </AddTargetsDiv>
                    <Modal isOpen={isModalOpen} onClose={() => setModalOpen(false)}>
                <ModalContent>
                  <FormTitle>Set a New Target</FormTitle>
                  <StyledForm onSubmit={handleSubmit}>
                    <FormSection>
                      <FormLabel>Target Details</FormLabel>
                      <StyledInput å
                        type="text" 
                        placeholder="Target Name" 
                        value={targetName} 
                        onChange={handleTargetNameChange} 
                      />
                      <StyledSelect value={targetType} onChange={handleTargetTypeChange}>
                        <option value="CO2 Removed">CO₂ Removed</option>
                        <option value="RECS Sold">Renewable Energy Credits Sold</option>
                        <option value="Total Emissions Reduced">Total Emissions Reduced</option>
                        <option value="Total Emissions Reduced (%)">% Emission Reduction</option>
                        <option value="Contracts Completed">Contracts Completed</option>
                        <option value="Partnerships Created">New Partnerships</option>
                      </StyledSelect>
                      <StyledInput 
                        type="number" 
                        placeholder="Target Value" 
                        value={targetValue} 
                        onChange={handleTargetValueChange} 
                      />
                    </FormSection>
                    
                    <FormSection>
                      <FormLabel>Target Duration</FormLabel>
                      <DateInputGroup>
                        <DateInput>
                          <StyledDateInput 
                            type="date" 
                            value={startDate} 
                            onChange={handleStartDateChange}
                            min={getCurrentDate()}
                          />
                          <DateLabel>Start Date</DateLabel>
                        </DateInput>
                        <DateSeparator>to</DateSeparator>
                        <DateInput>
                          <StyledDateInput 
                            type="date" 
                            value={endDate} 
                            onChange={handleEndDateChange}
                            min={startDate || getCurrentDate()}
                          />
                          <DateLabel>End Date</DateLabel>
                        </DateInput>
                      </DateInputGroup>
                    </FormSection>

                    <SubmitButton type="submit">Create Target</SubmitButton>
                  </StyledForm>
                </ModalContent>
                    </Modal>
                </BodyDiv>
            </MainContainer>
    );
}

export default RemovalTargets;