import React from "react";
import styled from "styled-components";

const StandardButton = styled.button`
  padding: 8px 20px;
  font-size: 16px;
  color: white;
  background-color: #315D79;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3;
  }
`;

const Titleh2 = styled.h2`
  font-size: 36px;
  color: #315D79;
  margin-bottom: 30px;
  text-align: center;
`;

const Title = styled.h2`
  font-size: 36px;
  color: #315D79;
  margin-bottom: 3%;
  text-align: center;
  margin-top: 4%;
`;

const Titleh3 = styled.h3`
  font-size: 24px;
  color: #315D79;
  margin-bottom: 30px;
  text-align: center;
`;

export { StandardButton, Title, Titleh2, Titleh3 }