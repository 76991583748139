// SellerOverviewLanding.jsx
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import { FaArrowUp, FaArrowDown } from 'react-icons/fa';
import { IoIosHelpCircleOutline } from "react-icons/io";
import dayjs from 'dayjs';
import { formatNumberDollars } from '../../../UniversalFunctions/Numeric/Format2Decimal';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent, CardHeader, CardTitle, CardDescription, ContractItem, ContractDetails, ContractName, ContractId, ContractStatus, HeaderContentDiv, ContractIdRow, OverviewCard, OverviewCardTitleTop,
  OverviewCardValue
 } from '../../../UI/Library/SellerComponents';
import { OverviewContainer, OverviewMainContent, OverviewHeader, OverviewSection } from '../../../UI/Library/PageStructure';
import ProgressBarDefault from './GraphSourcing/ProgressBar';
import axios from 'axios';
import contract_overview from "../../../../images/contract_overview_2.png";
import contract_progression from "../../../../images/contract_progression.png";
import project_page from "../../../../images/project_dashboard.png";
import project_form from "../../../../images/project_submission.png";
import project_selection from "../../../../images/project_selection.png";
import contract_submission from "../../../../images/contract_submission.png";
import ContractProgressBar from './GraphSourcing/ContractProgressBar';


const LowerDivMain = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 2%;
  gap: 20px;

  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

const LeftSideDiv = styled.div`
  flex: 3;
  display: flex;
  flex-direction: column;
  width: 70%

  @media (max-width: 800px) {
    flex: 1;
  }
`;

const RightSideDiv = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Title = styled.h1`
  font-size: 2rem;
  color: #0A3C57;
  text-align: left;
`;

const SubTitle = styled.p`
  font-size: 16px;
  color: #6C757D;
`;

const HelpButton = styled.button`
  background-color: #00A8E8;
  color: #fff;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  cursor: pointer;
  &:hover {
    background-color: #007CBA;
  }
`;

const ContractsAndProjects = styled.div`

`;

const ChangeIndicator = styled.div`
  margin-top: -1%;
  font-size: 14px;
  color: ${props => (props.positive ? '#739E9F' : '#FA9477')};
  display: flex;
  align-items: center;
  padding-bottom: 5%;
`;

const ChangeText = styled.p`
  font-size: 12px;
  margin-left: 5px;
  margin-top: 5%;
`;

const ArrowIcon = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  color: ${props => (props.positive ? '#1EB1B5' : '#F14B1B')};
`;

const ChartContainer = styled.div`
  background-color: #fff;
  border-radius: 8px;
  padding: 40px 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  @media (max-width: 800px) {
    width: 100%;
  }
`;

const ChartHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ChartTitle = styled.h3`
  font-size: 18px;
  color: #345D72;
  margin-bottom: 10px;
  text-align: left;
`;

const DateRange = styled.div`
  font-size: 16px;
  color: #1D374A;
  background-color: #DFEFF0;
  padding: 5px 10px;
  border-radius: 5px;
`;

const TransactionsContainer = styled.div`
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  @media (min-width: 800px) {
    min-height: 60vh;
  }
`;

const TransactionsList = styled.ul`
  list-style-type: none;
  padding: 0;
  flex: 1;
  overflow-y: auto;
`;

const TransactionItem = styled.li`
  font-size: 16px;
  color: #0A3C57;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
`;

const TransactionDate = styled.p`
  font-size: 12px;
  color: #6C757D;
  margin-top: -10px;
  margin-bottom: 8%;
  text-align: left;
`;

const ActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 40px;

  @media (max-width: 800px) {
    width: 100%;
  }
`;

const ActionButton = styled.button`
  background-color: #345D72;
  color: #fff;
  border: none;
  border-radius: 8px;
  padding: 5px 10px;
  cursor: pointer;
  white-space: nowrap;
  &:hover {
    background-color: ${props => (props.primary ? '#007CBA' : '#001F30')};
  }
`;

const CompanyFormatText = styled.p`
  text-align: left;
  color: #345D72;
  font-weight: bold;
`;

const TransactionFormatText = styled.p`
  text-align: right;
  color: #373D46;
  font-weight: bold;
`;

const SmallerCardValue = styled.p`
    font-size: .8rem;
    font-weight: 400;
    display: inline;
    color: #373D46;
`;

const ActiveRemovalDiv = styled.div`
  display: flex;
  flex-direction: row;
`;

const WelcomeText = styled.p`
  font-size: 1.2em;
  color: #5f7d95ff;
  margin-bottom: 1.4%;
  line-height: 1.25;
  text-align: left;
`;

const CardSection = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;

  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  height: 70%;
  background-color: white;
  padding: 4%;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  overflow-y: auto;
`;

const ModalRow = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;

const ModalItem = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 3%;
`;

const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
  max-width: 500px;
  width: 90%;
  z-index: 1001;
`;

const ModalIntroHeader = styled.h2`
  font-size: 1.8em;
  font-weight: bold;
  color: #325d79;
  text-align: center;
  margin-bottom: 20px;
  // align-content: center;
`;

const ModalIntroContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  height: 70vh;
  // max-width: 600px;
  height: auto;
  background-color: white;
  padding: 2% 2%;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  overflow-y: auto;
  z-index: 1001;
`;

const ModalIntroMessage = styled.p`
  font-size: 1.2em;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
  // width: 80%;
`;

const ModalIntroImageContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
`;

const ModalIntroImage = styled.img`
  // width: 100px;
  // height: 100px;
  border-radius: 10%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 40%;
`;

const ModalIntroControls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
`;

const ModalIntroCheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  font-size: 1em;
  color: #333;
`;

const ModalIntroCheckbox = styled.input`
  margin-right: 10px;
`;

const ModalIntroButton = styled.button`
  background-color: #325d79;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s;

  &:hover {
    background-color: #5f7d95;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const FirstSection = styled.div`
  width: 50%;
`;

const SecondSection = styled.div`
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`;

const SmallerText = styled.p`
  color: #1EB1B5;
  font-size: .7rem;
  align-content: center;
`;

const HelpLink = styled.a`
  color: #1a99d4;
  text-decoration: none;
  font-weight: bold;
`;

const NoContractsMessage = styled.p`
  font-style: italic;
  color: #5f7d95;
  text-align: center;
  padding: 20px;
  font-size: 1.1em;
`;

const NoContractsLink = styled.span`
  color: #1EB1B5;
  cursor: pointer;
  text-decoration: underline;
  
  &:hover {
    color: #16858a;
  }
`;

const NoProjectsMessage = styled.p`
  font-style: italic;
  color: #5f7d95;
  text-align: center;
  padding: 20px;
  font-size: 1.1em;
`;

const NoProjectsLink = styled.span`
  color: #1EB1B5;
  cursor: pointer;
  text-decoration: underline;
  
  &:hover {
    color: #16858a;
  }
`;

// Helper function to calculate the color based on the change in revenue
const calculateColor = (change) => {
  const lightColor = [187, 224, 225]; // #BBE0E1
  const darkColor = [52, 172, 179]; // #34ACB3 

  // Calculate the ratio between -1 and 1 based on the change
  const ratio = Math.max(-1, Math.min(1, change / 100000)); // Adjust the denominator based on typical range of changes
  const color = lightColor.map((lc, i) => Math.round(lc + (darkColor[i] - lc) * ratio));

  return `rgb(${color.join(',')})`;
};

const revenues = [30000, 45000, 35000, 59000, 45000, 40000, 60000, 65000, 70000, 45000, 80000, 85000];
const changes = revenues.map((rev, i) => i === 0 ? 0 : rev - revenues[i - 1]);
const backgroundColors = changes.map(calculateColor);

const data = {
  labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  datasets: [
    {
      label: 'Revenue',
      backgroundColor: backgroundColors,
      borderColor: '#00A8E8',
      data: revenues,
    },
  ],
};

const options = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    y: {
      beginAtZero: true,
      ticks: {
        color: '#568893',
        font: {
          size: 14,
        },
        callback: function(value) {
          return value >= 1000 ? (value / 1000) + 'K' : value;
        },
      },
      grid: {
        display: true,
        drawBorder: false,
      },
    },
    x: {
      ticks: {
        color: '#568893',
        font: {
          size: 14,
        },
      },
      grid: {
        display: false,
        drawBorder: false,
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
  },
};

function Modal({ isOpen, onClose, children }) {
  if (!isOpen) return null;

  return (
    <ModalBackdrop onClick={onClose}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        {children}
      </ModalContent>
    </ModalBackdrop>
  );
}

const IntroModal = ({ isOpen, onClose }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [dontShowAgain, setDontShowAgain] = useState(false);
  const user = localStorage.getItem('user');

  const slides = [
    {
      message:
        "Welcome to Carbonsmith! We're here to help you launch your projects and begin selling your credits. Let's get started.",
      images: [contract_overview, contract_progression],
    },
    {
      message:
        'Go to the "Projects" page to start adding new projects. Collect all required documentation and submit for project review and credit verification.',
      images: [project_page, project_form],
    },
    {
      message:
        'Once credits are verified for a project, proceed to the "Contract" page. Select the relevant project and create a new contract to start selling your credits.',
      images: [project_selection, contract_submission],
    },
  ];

  const handleNext = () => {
    if (currentSlide < slides.length - 1) {
      setCurrentSlide(currentSlide + 1);
    } else {
      handleClose();
    }
  };

  const handlePrevious = () => {
    if (currentSlide > 0) {
      setCurrentSlide(currentSlide - 1);
    }
  };

  const handleClose = () => {
    handleFinish();
  };
  // const handleModalClose = () => {
  //   handleFinish();
  //   onClose();
  // };

  const handleFinish = async () => {
    console.log(dontShowAgain);
    if (dontShowAgain) {
      localStorage.setItem("hideIntroModal", "true");
      // Function to update the database
      await updateDontShowAgainInDatabase();
    }
    onClose();
  };

  const toggleDontShowAgain = () => {
    setDontShowAgain(!dontShowAgain);
    // const subObj = {
    //   username: user,
    //   showIntro: preference
    // }

    // try {
    //   const res = axios.post(`http://127.0.0.1:5000/show_user_intro`,subObj);
    //   if (res.status === 200){
    //     console.log('successfully updated intro preference');
    //   }
    // } catch (error) {
    //   console.log(error)
    // }
    // console.log(dontShowAgain);
  };

  const updateDontShowAgainInDatabase = async () => {
    const username = localStorage.getItem("user");
    const accessToken = localStorage.getItem("access_token");
    console.log('hi')

    try {
      const res = await axios.post(
        "https://carbonsmith-handling-69e454e97e3b.herokuapp.com/show_user_intro",
        { username, dontShowIntroModal: true },

      );
      console.log(res);
    } catch (error) {
      console.error("Error updating preferences:", error);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalIntroContainer>
        <ModalIntroHeader>Welcome to Carbonsmith</ModalIntroHeader>
        <ModalIntroMessage>{slides[currentSlide].message}</ModalIntroMessage>
        <ModalIntroImageContainer>
          {slides[currentSlide].images.map((src, index) => (
            <ModalIntroImage key={index} src={src} alt="Example" />
          ))}
        </ModalIntroImageContainer>
        <ModalIntroControls>
          <ModalIntroButton
            onClick={handlePrevious}
            disabled={currentSlide === 0}
          >
            Previous
          </ModalIntroButton>
          {currentSlide === slides.length - 1 && (
            <ModalIntroCheckboxLabel>
              <ModalIntroCheckbox
                type="checkbox"
                checked={dontShowAgain}
                onChange={toggleDontShowAgain}
              />
              Don't show this again
            </ModalIntroCheckboxLabel>
          )}
          <ModalIntroButton onClick={currentSlide < slides.length - 1 ? handleNext : handleFinish}>
            {currentSlide < slides.length - 1 ? "Next" : "Finish"}
          </ModalIntroButton>

        </ModalIntroControls>
      </ModalIntroContainer>
    </Modal>
  );
};

const SellerOverviewLanding = (userData) => {
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [isNewUser, setIsNewUser] = useState(true);
    const [transactionList, setTransactionList] = useState([]);
    const [qtrRevenue, setQtrRevenue] = useState(0);
    const [priorqtrRevenue, setPriorqtrRevenue] = useState(0);
    const [creditsSold, setCreditsSold] = useState(0);
    const [priorqtrCreditsSold, setPriorqtrCreditsSold] = useState(0);
    const [creditsDelivered, setCreditsDelivered] = useState(0);
    const [priorqtrCreditsDelivered, setPriorqtrCreditsDelivered] = useState(0);
    const [variablesAssigned, setVariablesAssigned] = useState(false);
    const [avgPrice, setAvgPrice] = useState(0);
    const [priorQtrAvgPrice, setPriorQtrAvgPrice] = useState(0);
    const [percentRevChange, setPercentRevChange] = useState(0);
    const [percentCreditsSoldChange, setPercentCreditsSoldChange] = useState(0);
    const [percentCreditsDeliveredChange, setPercentCreditsDeliveredChange] = useState(0);
    const [percentAvgPriceChange, setPercentAvgPriceChange] = useState(0);
    const [fetchAverages, setFetchAverages] = useState(false);
    const [chartData, setChartData] = useState({ labels: [], datasets: [] });
    const [hasTTMSales, setHasTTMSales] = useState(false);
    const [hasContracts, setHasContracts] = useState(false);
    const [hasProjects, setHasProjects] = useState(false);
    const [contracts, setContracts] = useState([]);
    const [company, setCompany] = useState("");
    const [grabbedCompany, setGrabbedCompany] = useState(false);
    const [projects, setProjects] = useState([]);
    const [showIntroModal, setShowIntroModal] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const toggleModal = () => {
      setShowModal(!showModal);
    };
    
    useEffect(() => {
      // console.log(userData);
      // console.log(userData.userData)
      setCompany(userData.userData.profile.organization);
      setGrabbedCompany(true);
        if (userData && userData.userData && userData.userData.contracts) {
            setHasContracts(true);
            setContracts(Object.values(userData.userData.contracts));
            if (userData.userData.projects) {
              setHasProjects(true);
            }
            const assignVariables = () => {
                const contractsArray = Object.values(userData.userData.contracts);
                const soldContracts = contractsArray.filter(contract => contract.contractSold === true);

                // Determine the current quarter and the previous quarter
                const currentMonth = dayjs().month();
                const currentQuarter = Math.floor(currentMonth / 3);
                const priorQuarterStart = dayjs().subtract(3, 'month').startOf('month');
                const priorQuarterEnd = dayjs().subtract(1, 'month').endOf('month');

                // Filter contracts for the current quarter
                const currentQuarterContracts = soldContracts.filter(contract => {
                    const contractMonth = dayjs(contract.timeSold).month();
                    const contractQuarter = Math.floor(contractMonth / 3);
                    return contractQuarter === currentQuarter;
                });

                // Filter contracts for the previous quarter
                const priorQuarterContracts = soldContracts.filter(contract => {
                    const contractDate = dayjs(contract.timeSold);
                    return contractDate.isAfter(priorQuarterStart) && contractDate.isBefore(priorQuarterEnd);
                });

                // Calculate the total revenue for the current quarter
                const totalRevenue = currentQuarterContracts.reduce((sum, contract) => {
                    let revenue = 0;
                    if (typeof contract.revenueGenerated === 'string') {
                        revenue = parseFloat(contract.revenueGenerated.replace(/[^0-9.-]+/g, ""));
                    } else if (typeof contract.revenueGenerated === 'number') {
                        revenue = contract.revenueGenerated;
                    }
                    return sum + revenue;
                }, 0);

                // Calculate the total revenue for the prior quarter
                const totalPriorRevenue = priorQuarterContracts.reduce((sum, contract) => {
                    let revenue = 0;
                    if (typeof contract.revenueGenerated === 'string') {
                        revenue = parseFloat(contract.revenueGenerated.replace(/[^0-9.-]+/g, ""));
                    } else if (typeof contract.revenueGenerated === 'number') {
                        revenue = contract.revenueGenerated;
                    }
                    return sum + revenue;
                }, 0);

                // Calculate the total credits sold for the current quarter
                const creditsSoldCalc = currentQuarterContracts.reduce((sum, contract) => {
                    let credits = 0;
                    credits = parseFloat(contract.tonsToCapture) || 0;
                    return sum + credits;
                }, 0);

                // Calculate the total credits sold for the prior quarter
                const priorCreditsSoldCalc = priorQuarterContracts.reduce((sum, contract) => {
                    let credits = 0;
                    credits = parseFloat(contract.tonsToCapture) || 0;
                    return sum + credits;
                }, 0);

                // Calculate the total credits delivered for the current quarter
                const creditsDeliveredCalc = currentQuarterContracts.reduce((sum, contract) => {
                    let delivered = 0;
                    delivered = parseFloat(contract.carbonRemoved) || 0;
                    return sum + delivered;
                }, 0);

                // Calculate the total credits delivered for the prior quarter
                const priorCreditsDeliveredCalc = priorQuarterContracts.reduce((sum, contract) => {
                    let delivered = 0;
                    delivered = parseFloat(contract.carbonRemoved) || 0;
                    return sum + delivered;
                }, 0);

                              // Calculate the monthly revenue for the trailing twelve months
                const monthlyRevenue = Array(12).fill(0);
                const labels = Array(12).fill('').map((_, i) => dayjs().subtract(11 - i, 'month').format('MMM'));
                soldContracts.forEach(contract => {
                  const monthIndex = 11 - dayjs().diff(dayjs(contract.timeSold), 'month');
                  if (monthIndex >= 0 && monthIndex < 12) {
                    if (typeof contract.revenueGenerated === 'string') {
                      // console.log(contract.revenueGenerated);
                      monthlyRevenue[monthIndex] += parseFloat(contract.revenueGenerated.replace(/[^0-9.-]+/g, ""));
                    } else if (typeof contract.revenueGenerated === 'number') {
                      monthlyRevenue[monthIndex] += contract.revenueGenerated;
                    }
                    setHasTTMSales(true);
                  }
                });

                const changes = monthlyRevenue.map((rev, i) => i === 0 ? 0 : rev - monthlyRevenue[i - 1]);
                const backgroundColors = changes.map(calculateColor);

                const data = {
                  labels,
                  datasets: [
                    {
                      label: 'Revenue',
                      backgroundColor: backgroundColors,
                      borderColor: '#00A8E8',
                      data: monthlyRevenue,
                    },
                  ],
                };
                // console.log('charttttt', data);
                setChartData(data);


                // // Calculate the average price for the current quarter
                // const avgPriceCalc = totalRevenue / creditsSoldCalc || 0;
                setTransactionList(soldContracts);
                setQtrRevenue(totalRevenue);
                setPriorqtrRevenue(totalPriorRevenue);
                setCreditsSold(creditsSoldCalc);
                setPriorqtrCreditsSold(priorCreditsSoldCalc);
                setCreditsDelivered(creditsDeliveredCalc);
                setPriorqtrCreditsDelivered(priorCreditsDeliveredCalc);

                setPercentRevChange((((qtrRevenue-priorqtrRevenue)/(priorqtrRevenue))*100).toFixed(2));
                setPercentCreditsSoldChange((((creditsSold-priorqtrCreditsSold)/(priorqtrCreditsSold))*100).toFixed(2));
                setPercentCreditsDeliveredChange((((creditsDelivered-priorqtrCreditsDelivered)/(priorqtrCreditsDelivered))*100).toFixed(2));
                setVariablesAssigned(true);

                
            };
            assignVariables();
        } 
      }, [userData]);

    //separate useeffect for getting avg price after assignVariables finishes running
    useEffect(() => {
        const getAvgPrice = () => {
            setAvgPrice((qtrRevenue / creditsSold).toFixed(2));
            setPriorQtrAvgPrice((priorqtrRevenue / priorqtrCreditsSold).toFixed(2));
            setFetchAverages(true);

        }
        if (variablesAssigned) {
            getAvgPrice();
        }
    },[variablesAssigned])

    useEffect(() => {
        const getAvgPriceChange = () => {
            setPercentAvgPriceChange((((avgPrice-priorQtrAvgPrice)/(priorQtrAvgPrice))*100).toFixed(2));
        }
        if (avgPrice > 0) {
            getAvgPriceChange();
        }
    },[fetchAverages])

    const formatDate = (dateString) => {
        return dayjs(dateString).format('MM/DD/YYYY');
    };

    useEffect(() => {
      const fetchProjectData = async () => {
        const user = localStorage.getItem("user");
        const accessToken = localStorage.getItem("access_token");
        const firebaseIdToken = localStorage.getItem("firebase_id_token");
    
        if (!accessToken || !firebaseIdToken) {
          console.error("Tokens are missing");
          return;
        }
    
        const config = {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Firebase-Token": firebaseIdToken,
          },
        };
    
        const subObj = { username: user };
    
        try {
          setIsLoading(true);
          const res = await axios.post(
            "https://carbonsmith-handling-69e454e97e3b.herokuapp.com/get_company_projects",
            subObj,
            config
          );
          console.log("data: ", res.data.data);
          if (res.data.data === null) {
            const getInitialIntroState = async () => {
              const user = localStorage.getItem("user");
              const accessToken = localStorage.getItem("access_token");
              const subObj = { username: user };
    
              try {
                const res = await axios.post(
                  "https://carbonsmith-handling-69e454e97e3b.herokuapp.com/get_user_intro",
                  subObj
                );
                if (res.data.dontShowIntroModal === true){
                  setShowIntroModal(false);
                } else {
                  setShowIntroModal(true);
                }
              } catch (error) {
                console.log(error);
              }
            };
            getInitialIntroState();
          }
          console.log(res.data.data.Projects);
          const projectArray = res.data.data.Projects
            ? Object.values(res.data.data.Projects)
            : [];
          setProjects(projectArray);
          if (projectArray.length === 0) {
            setShowIntroModal(true);
          } else {
            setShowIntroModal(false);
            setIsNewUser(false);
          }
        } catch (error) {
          console.log(error);
        } finally {
          setIsLoading(false);
        }
      };
      if (grabbedCompany) {
        console.log(company);
        fetchProjectData();
      }
    }, [company]);

  return (
    <OverviewContainer>
      <OverviewMainContent>
        <OverviewHeader>
          <div>
            <Title>Overview</Title>
            <SubTitle>Here's a quick look at your data.</SubTitle>
          </div>
          <HelpLink href="/support">Help?</HelpLink>
          {/* <HelpButton onClick={() => (navigate('/support'))}>Help<IoIosHelpCircleOutline /></HelpButton> */}
        </OverviewHeader>
        <OverviewSection>
          <OverviewCard>
            <OverviewCardTitleTop>Total Revenue</OverviewCardTitleTop>
            <OverviewCardValue>${formatNumberDollars(qtrRevenue)}</OverviewCardValue>
            {percentRevChange > 0 && (
                <>
                <ChangeIndicator positive>
                    {isNaN(qtrRevenue) ? (<></>):(<ChangeText>+{percentRevChange}% from last quarter</ChangeText>)}
                </ChangeIndicator>
                <ArrowIcon positive><FaArrowUp /></ArrowIcon>
                </>
            )}
            {percentRevChange < 0 && (
                <>
                <ChangeIndicator negative>
                    {isNaN(qtrRevenue) ? (<></>):(<ChangeText>-{percentRevChange}% from last quarter</ChangeText>)}
                </ChangeIndicator>
                <ArrowIcon negative><FaArrowDown /></ArrowIcon>
                </>
            )}
            {percentRevChange === 0 && (
                <>
                {isNaN(qtrRevenue) ? (<></>):(<ChangeText>No change from last quarter</ChangeText>)}
                </>
            )}
          </OverviewCard>
          <OverviewCard>
            <OverviewCardTitleTop>Credits Sold</OverviewCardTitleTop>
            {isNaN(creditsSold) ? (<OverviewCardValue>0</OverviewCardValue>):(<OverviewCardValue>{creditsSold}</OverviewCardValue>)}
            {percentCreditsSoldChange > 0 && (
                <>
                <ChangeIndicator positive>
                    {isNaN(creditsSold) ? (<></>):(<ChangeText>+{percentCreditsSoldChange}% from last quarter</ChangeText>)}
                </ChangeIndicator>
                <ArrowIcon positive><FaArrowUp /></ArrowIcon>
                </>
            )}
            {percentCreditsSoldChange < 0 && (
                <>
                <ChangeIndicator negative>
                    {isNaN(creditsSold) ? (<></>):(<ChangeText>-{percentCreditsSoldChange}% from last quarter</ChangeText>)}
                </ChangeIndicator>
                <ArrowIcon negative><FaArrowDown /></ArrowIcon>
                </>
            )}
            {percentCreditsSoldChange === 0 && (
                <>
                {isNaN(creditsSold) ? (<></>):(<ChangeText>No change from last quarter</ChangeText>)}
                </>
            )}
          </OverviewCard>
          <OverviewCard>
            <OverviewCardTitleTop>Credits Delivered</OverviewCardTitleTop>
            {isNaN(creditsDelivered) ? (<OverviewCardValue>0</OverviewCardValue>):(<OverviewCardValue>{creditsDelivered}</OverviewCardValue>)}
            {percentCreditsDeliveredChange > 0 && (
                <>
                <ChangeIndicator positive>
                    {isNaN(creditsDelivered) ? (<></>):(<ChangeText>+{percentCreditsDeliveredChange}% from last quarter</ChangeText>)}
                </ChangeIndicator>
                <ArrowIcon positive><FaArrowUp /></ArrowIcon>
                </>
            )}
            {percentCreditsDeliveredChange < 0 && (
                <>
                <ChangeIndicator negative>
                    {isNaN(creditsDelivered) ? (<></>):(<ChangeText>-{percentCreditsDeliveredChange}% from last quarter</ChangeText>)}
                </ChangeIndicator>
                <ArrowIcon negative><FaArrowDown /></ArrowIcon>
                </>
            )}
            {percentCreditsDeliveredChange === 0 && (
                <>
                {isNaN(percentCreditsDeliveredChange) ? (<></>):(<ChangeText>No change from last quarter</ChangeText>)}
                </>
            )}
          </OverviewCard>
          <OverviewCard>
            <OverviewCardTitleTop>Average Price</OverviewCardTitleTop>
            {isNaN(avgPrice) ? (<OverviewCardValue>$0 <SmallerCardValue>/ credit</SmallerCardValue></OverviewCardValue>):(<OverviewCardValue>${avgPrice} <SmallerCardValue>/ credit</SmallerCardValue></OverviewCardValue>)}
            {percentAvgPriceChange > 0 && (
                <>
                <ChangeIndicator positive>
                    {isNaN(avgPrice) ? (<></>):(<ChangeText>+{percentAvgPriceChange}% from last quarter</ChangeText>)}
                </ChangeIndicator>
                <ArrowIcon positive><FaArrowUp /></ArrowIcon>
                </>
            )}
            {percentAvgPriceChange < 0 && (
                <>
                <ChangeIndicator negative>
                    {isNaN(avgPrice) ? (<></>):(<ChangeText>-{percentAvgPriceChange}% from last quarter</ChangeText>)}
                </ChangeIndicator>
                <ArrowIcon negative><FaArrowDown /></ArrowIcon>
                </>
            )}
            {percentAvgPriceChange === 0 && (
                <>
                {isNaN(avgPrice) ? (<></>):(<ChangeText>No change from last quarter</ChangeText>)}
                </>
            )}
          </OverviewCard>
        </OverviewSection>
        <CardSection>


        <Card style={{ flex: 1, maxWidth: '90%' }}>
          <CardHeader>
            <CardTitle style={{color: '#345D72', lineHeight: '1.3'}}>Projects</CardTitle>
            <CardDescription style={{color: '#373D46', fontWeight: '100'}}>Accepted Projects</CardDescription>
          </CardHeader>
          {!isLoading && (
            <>
              {projects.length > 0 ? (
                <>
                  <HeaderContentDiv style={{marginBottom: '1%'}}>
                    <div style={{width: '40%'}}></div>
                    <CardDescription style={{fontSize: '.8rem'}}>Removed (tons)</CardDescription>
                    <CardDescription style={{marginTop: '0', fontSize: '.8rem'}}>Remaining Credits</CardDescription>
                  </HeaderContentDiv>
                  <CardContent>
                    {projects.slice(0, 2).map((project) => (
                      <ContractItem style={{display: 'inline-block'}} key={project.project_name}>
                        <ContractDetails 
                          onClick={() => { navigate(`/View_Projects/${project.project_name}`) }} 
                        >
                          <ContractIdRow>
                            <FirstSection>
                              <ContractId style={{fontSize: '1.5rem', fontWeight: 'bold', color: '#191E27'}}>{project.project_name}</ContractId>
                            </FirstSection>
                            <SecondSection style={{justifyContent: 'space-between'}}>
                              {!project.credits_used && (<ContractId style={{fontSize: '1.5rem', fontWeight: 'bold', color: '#373D46', marginTop: '1%', marginLeft: '10%'}}>N/A</ContractId>)}
                              {project.credits_used >= 0 && (<ContractId style={{fontSize: '1.5rem', fontWeight: 'bold', color: '#373D46', marginTop: '1%', marginLeft: '10%'}}>{project.credits_used}</ContractId>)}
                              {!project.remaining_credits && (<ContractId style={{fontSize: '1.5rem', fontWeight: 'bold', color: '#1EB1B5', marginTop: '1%', marginRight: '20%'}}>N/A</ContractId>)}
                              {project.remaining_credits >= 0 && (<ContractId style={{fontSize: '1.5rem', fontWeight: 'bold', color: '#1EB1B5', marginTop: '1%', display: 'flex', flexDirection: 'row'}}><>{project.remaining_credits}&nbsp;</> <SmallerText>({((project.remaining_credits/(project.remaining_credits+project.credits_used))*100).toFixed(2)}%)</SmallerText></ContractId>)}
                            </SecondSection>
                          </ContractIdRow>
                          <ContractId style={{color: '#568893'}}>
                            <strong>Location:</strong>&nbsp;
                            {project.project_location}
                            &nbsp;
                          </ContractId>
                        </ContractDetails>
                      </ContractItem>
                    ))}
                  </CardContent>
                </>
              ) : (
                <NoProjectsMessage>
                  You don't have any projects uploaded. Get started <NoProjectsLink onClick={() => navigate('/add_project')}>here</NoProjectsLink>
                </NoProjectsMessage>
              )}
              <ContractIdRow style={{justifyContent: 'space-between'}}>
                <ContractId style={{marginTop: '2%', cursor: 'pointer', textAlign: 'left'}} onClick={() => (navigate(`/View_Projects`))}>+ See more</ContractId>
                <ContractId><ActionButton onClick={() => (navigate('/View_Projects'))}>Upload Projects</ActionButton></ContractId>
              </ContractIdRow>
            </>
          )}
        </Card>

      {(() => {
        const activeContracts = Object.values(contracts).filter(contract => contract.carbonRemoved > 0);
        const pendingContracts = Object.values(contracts).filter(contract => contract.tonsToCapture > 0 && contract.carbonRemoved === 0);
        
        const contractsToShow = activeContracts.length > 0 
          ? activeContracts.slice(0, 2) 
          : pendingContracts.slice(0, 2);

        return (
          <Card style={{flex: 1, maxWidth: '90%', marginLeft: '2%'}}>
            <CardHeader>
              <CardTitle style={{color: '#345D72', lineHeight: '1.3'}}>Contracts</CardTitle>
              <CardDescription style={{color: '#373D46', fontWeight: '100'}}>
                Contracts ready to start
              </CardDescription>
            </CardHeader>
            {contractsToShow.length > 0 ? (
              <>
                <HeaderContentDiv style={{marginBottom: '1%'}}>
                  <div style={{width: '50%'}}></div>
                  <CardDescription style={{fontSize: '.8rem'}}>Delivery Progress</CardDescription>
                </HeaderContentDiv>
                {contractsToShow.map((contract, index) => (
                  <ActiveRemovalDiv
                    key={index}
                    onClick={() => {
                      navigate(`/contract/${contract.contractName}`);
                    }}
                  >
                    <WelcomeText style={{ marginBottom: "3%" }}>
                      <span style={{ color: "#191E27" }}>
                        {contract.contractName}
                      </span>
                    </WelcomeText>
                    <ContractId></ContractId>
                    <ContractProgressBar contractInfo={contract} />
                  </ActiveRemovalDiv>
                ))}
              </>
            ) : (
              <NoContractsMessage>
                You don't have any contracts yet. Get started <NoContractsLink onClick={() => navigate('/ContractBuilder')}>here</NoContractsLink>
              </NoContractsMessage>
            )}
            <ContractIdRow style={{justifyContent: 'space-between'}}>
              <ContractId style={{marginTop: '2%', cursor: 'pointer', textAlign: 'left'}} onClick={() => (navigate(`/ContractHistory`))}>+ See more</ContractId>
              <ContractId><ActionButton onClick={() => (navigate('/ContractHistory'))}>Manage Contracts</ActionButton></ContractId>
            </ContractIdRow>
          </Card>
        );
      })()}

        </CardSection>
        <LowerDivMain>
          <LeftSideDiv>
            <ChartContainer>
              <ChartHeader>
                {(qtrRevenue !== 0 || priorqtrRevenue !== 0) && (<ChartTitle>Revenue Overview</ChartTitle>)}
                {qtrRevenue === 0 && priorqtrRevenue === 0 && (
                  <ChartTitle>Example Data</ChartTitle>
                )}
                <DateRange>{chartData["labels"][0]} 2023 - {chartData["labels"][chartData["labels"].length-1]} 2024</DateRange>
              </ChartHeader>
              <div style={{ flex: 1, width: '100%' }}>
                {!hasTTMSales && (
                  <Bar data={data} options={options} />
                )}
                {hasTTMSales && (
                  <Bar data={chartData} options={options} />
                )}
              </div>
            </ChartContainer>
          </LeftSideDiv>
          <RightSideDiv>
            <TransactionsContainer>
              <OverviewCardTitleTop>Recent Transactions</OverviewCardTitleTop>
              <TransactionsList>
                {transactionList.length > 0 && 
              transactionList.map((transaction, i) => (
                <div key={i}>
                    <TransactionItem>
                        <span><CompanyFormatText>{transaction.purchaser}</CompanyFormatText></span>
                        <span><TransactionFormatText>${formatNumberDollars(transaction.revenueGenerated)}</TransactionFormatText></span>
                    </TransactionItem>
                    <TransactionDate>{formatDate(transaction.timeSold)}</TransactionDate>
                </div>
                ))
            }
            {transactionList.length === 0 && (
                <TransactionItem><CompanyFormatText>Sell contracts and begin tracking!</CompanyFormatText></TransactionItem>
            )}
              </TransactionsList>
            </TransactionsContainer>
          </RightSideDiv>
        </LowerDivMain>

      </OverviewMainContent>
      {showIntroModal && (
        <IntroModal
          isOpen={showIntroModal}
          onClose={() => setShowIntroModal(false)}
        />
      )}
    </OverviewContainer>
  );
};

export default SellerOverviewLanding;



