import React, { useState } from 'react';
import { NewHeaderLight } from '../ExternalSite/NewHeader';
import NewFooter from '../ExternalSite/NewFooter';
import styled from 'styled-components';
import axios from 'axios';
import { useNavigate } from 'react-router';
import { useAuth } from '../../../UniversalFunctions/AuthProvider';
import createAccount from '../../../UniversalFunctions/Hedera/AccountCreate';
import { addedAt, modifiedAt } from '../../../UniversalFunctions/timestamps';
import { clamp } from 'framer-motion';
import { signInWithCustomToken } from 'firebase/auth';
import { auth } from '../../../UniversalFunctions/firebase';
import FlexContainer from '../../../UI/Library/Container/FlexContainer';
import theme from "../../../UI/Library/Theme";
import loginBackground from "../../../../images/michael-benz--IZ2sgQKIhM-unsplash.jpg";

const PageContainer = styled.div`
  background-color: #fff; /* Background color to match your site's design */
  text-align: center; /* Center the content */
  overflow-x: clip; /* Hide horizontal scrollbar */
`;

const MainContainer = styled.div`
  width: 100%;
  height: 100vh;
  background-image: url(${loginBackground});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center calc(50% - 5%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (min-width: 1024px) {
    flex-direction: row;
    justify-content: right;
  }
`;

const AuthForm = styled.form`
    min-height: 60%;
  width: 80%;
  background-color: white;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  padding: 5vh 0;
  @media (min-width: 1024px) {
    width: auto;
    min-width: 30%;
    margin-right: 10%;
  }
`;

const FormTitle = styled.h2`
  margin-bottom: 20px;
  color: #325d79ff;
`;

const FormLabel = styled.label`
  align-self: flex-start;
  display: block;
  font-weight: bold;
  color: #325d79ff;
  transition: all 0.5s ease-in-out;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 400;
  line-height: 17.58px;
  text-align: left;

  &:focus-within {
    color: #325d79ff;
  }
`;

const FormInput = styled.input`
  width: 100%;
  margin-bottom: 10px;
  background: #edeeee;
  border: none;
  padding: 0 10px;
  border-radius: 10px;
  height: 2.5rem;
  min-height: 2rem;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 300;
  line-height: 16.41px;
  text-align: left;
  color: #8d9b9c;

  ::placeholder {
    color: #8d9b9c;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 300;
    line-height: 16.41px;
  }

  &:focus {
    outline: none;
    border-color: #325d79ff;
  }

  &:focus + ${FormLabel} {
    color: #325d79ff;
  }
`;

const FormButton = styled.button`
  // background-color: #325d79ff; /* Button background color */
  background-color: ${theme.colors.primary[900]};
  color: #fff; /* Button text color */
  padding: 12px 24px;
  border-radius: 10px;
  font-size: 16px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s, transform 0.2s;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 300;
  line-height: 16.41px;
  text-align: center;

  &:hover {
    background-color: #325d7980;
    transform: scale(1.05);
  }
`;

const SwitchModeLink = styled.p`
  cursor: pointer;
  text-decoration: underline;
`;

const FormSelect = styled.select`
  padding: 10px;
  margin-bottom: 20px;
  border: 2px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  background-color: white;
  transition: border-color 0.3s ease;

  &:focus {
    border-color: #00796B;
    outline: none;
  }`

  const FormInputWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1vw;
    margin-bottom: 20px;
    align-items: baseline;

    @media (max-width: 768px) {
      /* flex-direction: column; */
    }
    `;

    const SuccessAlert = styled.div`
    background-color: #f3f9e8;
    color: #739e9f;
    padding: 10px;
    margin: 10px;
    border-radius: 10px;
  `;
  const ErrorAlert = styled.div`
    background-color: #ffebd4;
    color: #f76a1d;
    padding: 10px;
    margin: 10px;
    border-radius: 10px;
  `;
  


function SignInPage() {
  const [isSignup, setIsSignup] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [organization, setOrganization] = useState('');
  const [userType, setUserType] = useState('Buyer');
  const [userData, setUserData] = useState({});
  const [userInfo, setUserInfo] = useState({});
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [alert, setAlert] = useState({
    type: "",
    message: "",
  });
  const { setAuthState } = useAuth(); //get the function to update the auth state
  const navigate = useNavigate(); //Get the history object from React Router

  const handleSwitchMode = () => {
    setIsSignup((prevIsSignup) => !prevIsSignup);
    navigate('/login');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // In case the previous firebase auth.currentUser is not successfully logged out.
    if (auth.currentUser) {
        //console.log("SignIn log out current firebase user", auth.currentUser);
        await auth.signOut();
    }
    const userInfo = await createAccount();
    // Handle sign-up or sign-in here based on isSignup state
    try {
        if (isSignup) {
            // Handle sign-up logic
            //add endpoint on heroku
            userData["username"] = email;
            userData["password"] = password;
            userData['usertype'] = userType;
            userData['organization'] = organization;
            userData['accountID'] = userInfo['accountId'];
            userData['privateKey'] = userInfo['privateKey'];
            userData['addedAt'] = addedAt();
            userData['modifiedAt'] = modifiedAt();
            // console.log('email: ' + email);

            //console.log(userData);
            axios.post('https://carbonsmith-handling-69e454e97e3b.herokuapp.com/signup', userData)
            //axios.post('http://127.0.0.1:5000/signup', userData)
            .then(async res => {
                //console.log('response:', res)
                if (res.status === 200) {
                  setAlert({
                    type: "success",
                    message: "Sign up successful",
                  });
                    // Get firebase_custom_token returned from the backend login endpoint.
                    const firebaseCustomToken = res.data.firebase_custom_token;
                    //console.log("firebase_custom_token get from backend:", firebaseCustomToken);

                    // Key step: use signInWithCustomToken method with firebase_custom_token to connect with firebase in frontend, so that we can use firebase's built-in method to do the refreshing.
                    // After calling this function, we will get a new token, which will be used to refreshed, not the same one with the firebase_custom_token.
                    const firebaseCredential = await signInWithCustomToken(auth, firebaseCustomToken);
                    const newToken = await firebaseCredential.user.getIdToken(true);
                    //console.log("firebase_id_token after signInWithCustomToken to be kept refreshed in frontend:", newToken);

                    try {
                        // Set it here. Because the first token generated by signInWithCustomToken will only exist here for once and will not successfully trigger the refreshFirebaseIdToken method and be stored.
                        localStorage.setItem("firebase_id_token", newToken);
                        //console.log("SignIn get firebase_id_token:", localStorage.getItem("firebase_id_token"));

                        localStorage.setItem('user', userData['username']);
                        //console.log("SignIn get firebase_id_token:", localStorage.getItem("user"));

                        localStorage.setItem('access_token', res.data.jwt_token);
                        //console.log("SignIn get firebase_id_token:", localStorage.getItem("access_token"));

                    } catch (error) {
                        console.error('Error setting items in localStorage:', e);
                    }

                    // Update auth status and redirect.
                    setAuthState(email, true);
                    navigate('/');
                }
            })
            .catch((error) => {
              console.log(error);
              console.log(error.response.data.error)
              if (String(error.response.data.error).includes('password')) {
                // setAlert('Error: username and password are required');
                setAlert({
                  type: "error",
                  message: "Username and password are required",
                });
              }
              if (String(error.response.data.error).includes('already exists')) {
                // console.log(res.data.error);
                setAlert({
                  type: "error",
                  message: "Username already exists",
                });
              }
              else {
                console.error('Error: ' + error.reponse.data.error);
                setAlert({
                  type: "error",
                  message: "Something went wrong",
                });
              }
            })

        } else {
        // Handle sign-in logic by making a post request to authenticate the user
        const response = await fetch('{sign in endpoint}', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(userData),
        });

        if (response.status === 200) {
            //Sign-in successful, can redirect or set user session** should definitely do both
            setAuthState(email, true);
            navigate.push('/');
        } else {
            //Handle sign-in errors, e.g., invalid credentials
        }
        }
        } catch (error) {
            //handle network or server errors
            console.error('Error: ', error);
        }
    };

  return (
    <PageContainer>
        <NewHeaderLight />
        <MainContainer>
      <AuthForm onSubmit={handleSubmit}>
        <FlexContainer
          direction="column"
          customStyles={`
            padding: 1rem 2rem;
            width: 100%;
            height: 100%;
            `}
          justify="center"
          gap={"2vh"}>
          <div style={{ textAlign: "left", paddingBottom: "10px" }}>
                <h2
                  style={{
                    color: "#0A3C57",
                    fontFamily: "Roboto",
                    fontSize: clamp("1.8rem", "4vw", "2.5rem"),
                    fontWeight: "700",
                    lineHeight: "46.88px",
                    textAlign: "left",
                  }}
                >
                  Welcome
                </h2>
                <p>Get started on your sustainability journey.</p>
              </div>
              {alert.type === "error" && (
                <ErrorAlert>{alert.message}</ErrorAlert>
              )}
              {alert.type === "success" && (
                <SuccessAlert>{alert.message}</SuccessAlert>
              )}
        {/* <FormTitle>{isSignup ? 'Sign Up' : 'Sign In'}</FormTitle> */}
        {/* <FormInputWrapper> */}
        <FormLabel>Email</FormLabel>
        <FormInput
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder='john@carbonsmith.io'
          required
        />
        {/* </FormInputWrapper>
        <FormInputWrapper> */}
        <FormLabel>Organization</FormLabel>
          <FormInput
          type="organization"
          value={organization}
          onChange={(e) => setOrganization(e.target.value)}
          placeholder='Carbonsmith LLC'
          required
          />
        {/* </FormInputWrapper>
        <FormInputWrapper> */}
        <FormLabel>Password</FormLabel>
        <FormInput
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          placeholder='********'
        />
        {/* </FormInputWrapper>
        <span style={{ fontWeight: 'bold', fontSize: clamp('1.5rem', '3.5rem', '2rem'), paddingBottom: '2vw'}}>Carbon Credits</span>
        <FormInputWrapper> */}
        <FormLabel>Buying or selling credits?</FormLabel>
        <FormSelect value={userType} onChange={(e) => setUserType(e.target.value)}>
          <option value="Buyer">Buying</option>
          <option value="Seller">Selling</option>
          {/* <option value="Potential">Just looking</option> */}
        </FormSelect>
        {/* </FormInputWrapper> */}
        <FormButton type="submit" disabled={submitDisabled}>
          Create an Account
        </FormButton>
        </FlexContainer>
      </AuthForm>
      </MainContainer>
      <NewFooter />
    </PageContainer>
  );
}

export default SignInPage;
